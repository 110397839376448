import axios from "axios";
import { store } from "./../store/store";


export default {
    data() {
        return {
          AUTHENTICATION_BASE_URL: process.env.VUE_APP_AUTHENTICATION_ROOT_URL,
          config: null,
        };
      },
    methods: {
        getOTPfromAuthServer: async function (email) {
            const url  = this.AUTHENTICATION_BASE_URL + '/request-otp'
            let response = await axios
            .post(url, {email : email})
            .catch((err) => {
              return err.response;
            });
          return response;
        },

        signUp: async function (customer) {
          const url = this.AUTHENTICATION_BASE_URL + '/sign-up'
          const body = {
            "email":  customer.primaryEmail,
            "lastName": customer.lastName,
            "firstName": customer.firstName,
            "dateOfBirth": customer.dateOfBirth,
            "phoneNumber": (customer.phoneNumber).replace(/\s/g, ''), // Remove space in the phone number
            "roles": [
              "ROLE_CUSTOMER"
            ]
          }
          let response = await axios
            .post(url, body)
            .catch((err) => {
              return err.response;
            });
          return response;
        },

        signIn: async function (email, otp) {
            const url  = this.AUTHENTICATION_BASE_URL + '/sign-in';
            const body = {
              "email" : email, 
              "otp": otp
            };
            let response = await axios
            .post(url, body)
            .catch((err) => {
              return err.response;
            });
          return response;
        },

        verifyEmail: async function (email, otp) {
            const url  = this.AUTHENTICATION_BASE_URL + '/verify-email'
            let response = await axios
            .post(url, {email : email, otp: otp})
            .catch((err) => {
              return err.response;
            });
          return response;
        },

        getNewAuthToken: async function (refreshToken) {
          const url = process.env.VUE_APP_AUTHENTICATION_ROOT_URL + '/refresh'
          let response = await axios
            .post(url, { refresh_token: refreshToken })
            .catch((err) => {
              return err.response;
            });
          return response;
        },

        signOutAndRevokeTokens: async function (user_uuid) {
          const url = process.env.VUE_APP_AUTHENTICATION_ROOT_URL + '/revoke'
          let response = await axios
            .post(url, { user_uuid: user_uuid })
            .catch((err) => {
              return err.response;
            });
          return response;
        },

        calculateAccessTokenExpirationTime: function (tokenData) {
          const currentTimestamp = Date.now();
          // Default expiration time set for access token = 23 hours  = 23*60*60 = 82,800 sec
          // For testing purpose let's make expiration time to 1 min
          let accessTokenExpirationTime = currentTimestamp + ((tokenData?.expires_in || 3600) * 1000);
          return {
            ...tokenData,
            "accessTokenExpirationTime": accessTokenExpirationTime
          }
        },

        calculateRefreshTokenExpirationTime: function (tokenData) {
          const currentTimestamp = Date.now();
          // Default expiration time set for refresh token = 7 days =~ 6 days 23 hours  = 7*23*60*60 = 579600 sec
          let refreshTokenExpirationTime = currentTimestamp + ((tokenData.refresh_token_expires_in || 579600) * 1000);
          return {
            ...tokenData,
            "refreshTokenExpirationTime": refreshTokenExpirationTime
          }
        },

        handleTokenValidationAndRenewal: async function (renewToken = false) {
          const currentTimestamp = Date.now();
          const tokenFromLocalStorage = JSON.parse(window.localStorage.getItem('authData'));
          if(renewToken == true) {
            await store.dispatch("RENEW_TOKEN", tokenFromLocalStorage.refresh_token)
            return true;
          }
          else if (currentTimestamp < store.getters.getAccessTokenExpirationTime) {
            return true;
          } 
          if(!tokenFromLocalStorage) {
            return false
          } else if (currentTimestamp < tokenFromLocalStorage.accessTokenExpirationTime) {
            await store.dispatch("SAVE_AUTH_DATA_APP", tokenFromLocalStorage)
            return true;
          } else if (currentTimestamp < tokenFromLocalStorage.refreshTokenExpirationTime) {
            // Renew accesstoken from refresh token
            await store.dispatch("RENEW_TOKEN", tokenFromLocalStorage.refresh_token)
            return true;
          } else {
            return false;
          }
      },

        getAccessToken: function() {
          let accessToken = store.getters.getAccessToken;
          if(!accessToken && window.localStorage.getItem('authData')) {
            let authData = JSON.parse(window.localStorage.getItem('authData'));
            accessToken = authData.access_token;
          }
          return accessToken;
        },

        isLoggedIn: function() {
          if(store.getters.getAccessToken != null) {
            return true;
          } else if (window.localStorage.getItem('authData') != null) {
            return true;
          } else {
            return false
          }
        },

        getUserUuid: function() {
          // let tokenData = 
          return store.getters.getUserUuid
        }
    }
}