<template>
    <div v-show="!isLoading" class="img-pic-viewer">
        <div class="img-header">
            <div>
                <p>
                    {{ fileName }}
                </p>
            </div>
            <div class="zoom-section">
                <i class="fa fa-magnifying-glass-plus zoom-plus" 
                @click.prevent="zoomIn" 
                @mousedown.prevent="longPress(zoomIn)"
                @mouseup.prevent="releasePress"></i>
                <div class="zoom-input">
                    <input
                    type="text"
                    maxlength="3"
                    v-model="scaleInput"
                    v-on:input="handleInput($event)"
                    v-on:blur="changeScale"
                    v-on:keyup.enter="changeScale"
                    >
                    <div>%</div>
                </div>
                <i style="margin-left: 24px;" class="fa fa-magnifying-glass-minus zoom-minus" 
                @click="zoomOut"
                @mousedown="longPress(zoomOut)"
                @mouseup="releasePress"
                ></i>
            </div>
        </div>
        <div class="img-container">
            <img :src="imageUrl"
            id="pic"
            alt="Uploaded Image">
            <div></div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ImageViewer",
    props: ["imageUrl","isLoading","fileName"],
    data() {
        return {
            scale:1,
            scaleInput:1,
            maxScale:2,
            minScale:0.2,
            zoomStep:0.01,
            imgElem:null,
            imgViewerElem:null,
            originalImgWidth:0,
            originalImgHeight:0,
            adjustedScale:0,
            processCycle:null,
        }
    },
    methods: {
        longPress(callback) {
            this.processCycle=setInterval(callback,200);
        },
        releasePress() {
            clearInterval(this.processCycle);
        },
        handleInput($event) {
            this.scaleInput=$event.target.value.replace(/\D/g, "");
            this.scale=(this.scaleInput / 100);
        },
        zoomIn() {
            if(this.scale < this.maxScale) {
                this.scale += this.zoomStep;
                this.updateScale("add");
            }
        },
        zoomOut() {
            if(this.scale > this.minScale) {
                this.scale -= this.zoomStep;
                this.updateScale("sub");
            }
        },
        changeScale() {
            if(this.scale<=this.minScale) {
                this.scale=this.minScale;   
            } else if(this.scale>=this.maxScale) {
                this.scale=this.maxScale;
            } else {
                this.scale=(this.scaleInput/100);
            }
            this.scaleInput=Math.round(this.scale * 100);
            this.adjustedScale=this.scale;
            this.imgElem.style.width=Math.round(this.adjustedScale * this.originalImgWidth) + "px";
            this.imgElem.style.height=Math.round(this.adjustedScale * this.originalImgHeight) + "px";

            const viewerStyle = window.getComputedStyle(this.imgViewerElem);
            if(this.scale > 1 && viewerStyle.getPropertyValue("flex-direction") == "row") {
                if(this.imgElem.scrollWidth <= this.imgViewerElem.clientWidth ) {
                    this.imgViewerElem.style.justifyContent="center";
                } else {
                    this.imgViewerElem.style.justifyContent="flex-start";
                }
            }
            else if(this.scale>1) {
                this.imgViewerElem.style.justifyContent="flex-start";
            } else {
                this.imgViewerElem.style.justifyContent="center";
            }
        },
        updateScale(mode) {
            if(mode == "add") {
                this.adjustedScale=this.adjustedScale + this.zoomStep;
            } else {
                this.adjustedScale=this.adjustedScale - this.zoomStep;
            }
            this.imgElem.style.width=Math.round(this.adjustedScale * this.originalImgWidth) + "px";
            this.imgElem.style.height=Math.round(this.adjustedScale * this.originalImgHeight) + "px";
            this.scaleInput=Math.round(this.adjustedScale * 100);

            const viewerStyle = window.getComputedStyle(this.imgViewerElem);
            if(this.scale > 1 && viewerStyle.getPropertyValue("flex-direction") == "row") {
                if(this.imgElem.scrollWidth <= this.imgViewerElem.clientWidth ) {
                    this.imgViewerElem.style.justifyContent="center";
                } else {
                    this.imgViewerElem.style.justifyContent="flex-start";
                }
            }
            else if(this.scale>1) {
                this.imgViewerElem.style.justifyContent="flex-start";
            } else {
                this.imgViewerElem.style.justifyContent="center";
            }
        },
        adjustPreview() {
            this.originalImgHeight=this.imgElem.scrollHeight;
            this.originalImgWidth=this.imgElem.scrollWidth;
            if(this.imgViewerElem.clientWidth < this.imgElem.scrollWidth) {
                this.scale=(this.imgViewerElem.clientWidth/this.imgElem.scrollWidth);
                this.imgElem.style.height = Math.round((this.imgViewerElem.clientHeight * this.imgViewerElem.clientWidth) / this.imgElem.scrollWidth) + "px";
                this.imgElem.style.width = this.imgViewerElem.clientWidth + "px";
                this.imgViewerElem.style.flexDirection = "column";
            }

            if(this.imgViewerElem.clientHeight < this.imgElem.scrollHeight) {
                this.scale=(this.imgViewerElem.clientHeight/this.imgElem.scrollHeight);
                this.imgElem.style.width = Math.round((this.imgViewerElem.clientWidth * this.imgViewerElem.clientHeight) / this.imgElem.scrollHeight) + "px";
                this.imgElem.style.height = this.imgViewerElem.clientHeight + "px";
                this.imgViewerElem.style.flexDirection = "row";
            }

            this.scaleInput=Math.round(this.scale * 100);
            this.adjustedScale=this.scale;
            this.minScale=this.adjustedScale;
            this.imgViewerElem.style.visibility="visible";
        },
        saveFile() {
            const link = document.createElement("a");
            link.href=this.imageUrl;
            link.download=this.fileName;
            document.body.appendChild(link);
            //open download prompt
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(this.imageUrl);
        },
        printImage() {
          const printWindow = window.open('', '_blank');  // Open new window
          /* eslint-disable no-useless-escape */
          printWindow.document.write(`
            <html>
              <head>
                <title>Print Image</title>
                <style>
                  body { text-align: center; margin: 0; padding: 0; }
                  img { max-width: 100%; height: auto; }
                </style>
              </head>
              <body>
                <img src="${this.imageUrl}" alt="Image to Print">
                <script>
                  window.onload = function() {
                    window.print();  // Trigger print dialog
                    window.onafterprint = function() { window.close(); };
                  }
                <\/script>
              </body>
            </html>
          `);
          printWindow.document.close();  // Close document write stream
        }



    },
    mounted() {
        function waitForElement(id, callback) {
            const interval = setInterval(() => {
            const elem = document.getElementById(id);
                if (elem) {
                    clearInterval(interval);
                    callback(elem);
                }
            }, 100);
        }
        function waitForDom(elem,callback) {
            const interval = setInterval(() => {
                if(elem.clientWidth!=0) {
                    clearInterval(interval);
                    callback();
                }
            }, 100);   
        }

        //check the elements and DOM properties are loaded
        waitForElement('pic', (elem) => {
            this.imgElem=elem;
            this.imgViewerElem=document.getElementsByClassName("img-container")[0];
            waitForDom(elem,()=> {
                this.adjustPreview();
            });
        });
    }
}
</script>

<style scoped>
.fa {
    cursor: pointer;
}
.img-pic-viewer{
    padding: 0%;
    margin: 1%;
    height: calc(100vh - 80px);
}
.img-header {
    display: flex;
    /*width: 450px;*/
    position: sticky;
    width: 100%;
    height: 56px;
    background-color: rgb(50,54,57);
    color: #f8f8f8;
    caret-color: transparent;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap:2rem;
}
.img-container {
    display: flex;
    visibility: hidden;
    width: inherit;
    height: calc(100% - 40px);
    box-sizing: border-box;
    padding: 0%;
    position: relative;
    background-color: rgb(82,86,89);
    overflow: scroll !important;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}
.img-container img {
    margin: 0%;
    object-fit:fill;
    /*transform-origin: center;*/
}
.img-header div:nth-child(1) {
    color: #f8f8f8;
    width: 40%;
    float: left;
    text-align: center;
    margin-left:2%;
}
.img-header div:nth-child(1) p {
    padding: 0%;
    margin: 0%;
    text-wrap: nowrap;
    text-align: left;
    width: 20vw;
    text-overflow: ellipsis;
    overflow: hidden;
}
.img-header div:nth-child(2) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap:10px;
    padding: 0%;
    margin: 0%;
    width: 15%;
    justify-content: flex-start;
}
.img-header div:nth-child(2) p {
    line-height:1em;
    min-width: 60px;
    background-color: rgba(28, 27, 27, 0.862);
    text-align: center;
    padding: 0%;
    margin: 0%;
}
.img-header div:nth-child(3) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0%;
    margin: 0%;
    justify-content: flex-start;
    width: 30%;
    gap:2rem;
}
.zoom-section .zoom-input {
    display:flex;
    flex-wrap: nowrap;
    flex-direction: row;
    width:40px;
    padding: 0%;
    margin: 0%;
}
.zoom-section .zoom-input div {
    background-color: rgb(18, 17, 17);
    margin: 0 0 0 -12px;
    padding: 0%;
    width: 15px;
}
.zoom-section input[type="text"] {
    width:30px;
    text-align: center;
    height: 25px;
    background-color: rgb(18, 17, 17);
    caret-color: #f8f8f8;
    padding: 0%;
    margin: 0%;
    font-size: 14px;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border: 1px solid rgb(18, 17, 17);
}
.zoom-section input[type="text"]:focus {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.fa {
    margin-top:5px ;
}

.zoom-plus:active, .zoom-minus:active {
    color: #babab4;
}
</style>