<template>
  <div>
    <div class="limiter">
      <div class="container-login100">
        <div class="wrap-login100 p-t-25 p-b-20" v-if="currentSection === 'sign-up'">
          <span class="login100-form-title p-b-20">Join now!</span>
          <form id="sign-in-form" class="login100-form validate-form" v-on:submit.prevent="signUpUser" method="post">
            <!-- Moon Loader -->
            <moon-loader v-bind:loading="isLoading" :color="loaderColor" :size="loaderSize"
              v-bind:loaderMessage="loaderMessage"></moon-loader>

            <!-- First Name-->
            <div class="wrap-input100 validate-input my-1" v-bind:class="validations.customer.firstName === 1 ? '' : 'alert-validate'
          " data-validate="Enter valid name">
              <input id="first-name" class="input100" v-bind:class="customer.firstName !== null ? 'has-val' : ''"
                type="text" name="firstName" v-model="customer.firstName" v-on:change="validateFirstName($event)"
                required />
              <span class="focus-input100" data-placeholder="First Name"></span>
            </div>

            <!-- Last Name-->
            <div class="wrap-input100 validate-input my-1" v-bind:class="validations.customer.lastName === 1 ? '' : 'alert-validate'
          " data-validate="Enter valid name">
              <input id="last-name" class="input100" v-bind:class="customer.lastName !== null ? 'has-val' : ''"
                type="text" name="lastName" v-model="customer.lastName" v-on:change="validateLastName($event)"
                required />
              <span class="focus-input100" data-placeholder="Last Name"></span>
            </div>

            <!-- Email Address -->
            <div class="wrap-input100 validate-input my-1" v-bind:class="validations.customer.primaryEmail === 1
          ? ''
          : 'alert-validate'
          " data-validate="Enter valid email">
              <input id="login-email" class="input100" v-bind:class="customer.primaryEmail !== null ? 'has-val' : ''"
                type="email" name="email" v-model="customer.primaryEmail" v-on:change="validateEmail($event)"
                required />
              <span class="focus-input100" data-placeholder="Email"></span>
            </div>

            <!-- Date of Birth Section -->
            <div class="wrap-input100 validate-input my-1" v-bind:class="validations.customer.dateOfBirth === 1 ? '' : 'alert-validate'
          " data-validate="Enter valid date">
              <input id="date-of-birth" class="input100" v-bind:class="customer.dateOfBirth !== null ? 'has-val' : ''"
                type="text" maxlength="10" name="dateOfBirth" v-mask="'##/##/####'" v-model="dateOfBirthModel"
                v-on:change="validateDateOfBirth()" required />
              <span class="focus-input100" data-placeholder="Date of Birth (MM/DD/YYYY)"></span>
            </div>

            <!-- Phone Number Section -->
            <div class="wrap-input100 validate-input my-1" v-bind:class="validations.customer.phoneNumber === 1 ? '' : 'alert-validate'
          " data-validate="Enter valid phone number">
              <input id="phone-number" class="input100" v-bind:class="customer.phoneNumber !== null ? 'has-val' : ''"
                type="text" maxlength="14" name="phoneNumber" v-mask="'(###) ###-####'" v-model="phoneNumberModel"
                v-on:change="validatePhoneNumber()" required />
              <span class="focus-input100" data-placeholder="Phone Number (###) ###-####"></span>
            </div>

            <span class="same-as-above">
                <label></label>
                <input type="checkbox" id="same-as-above" v-model="sameAsAbove">
                <label for="same-as-above">Same as above</label>
            </span>

            <!-- Whats App Number Section -->
            <div class="wrap-input100 validate-input my-1" v-bind:class="validations.customer.whatsAppNumber === 1 ? '' : 'alert-validate'
          " data-validate="Enter valid Whatsapp number">
              <input id="whatsapp-number" class="input100" v-bind:class="customer.whatsAppNumber !== null ? 'has-val' : ''"
                type="text" maxlength="14" name="whatsappNumber" v-mask="'(###) ###-####'" 
                v-model="whatsAppNumberModel"
                v-on:change="validateWhatsAppNumber()"
                v-bind:disabled="sameAsAbove ? 'disabled': null"
                required />
              <span class="focus-input100" data-placeholder="Whats app Number (###) ###-####"></span>
            </div>

            <!-- Error message -->
            <p v-if="errorMessage" class="error-text">{{ errorMessage }}</p>

            <!-- Sign Up -->
            <div class="container-login100-form-btn">
              <button class="login100-form-btn">
                Sign up
              </button>
            </div>

            <div class="bottom-link">
              <p>
                <a
            class="link"
            v-on:click="$router.push({ name: 'sign-in' })"
            >Already have an account?</a>
              </p>
            </div>
          </form>
        </div>
        <!-- OTP Section -->
        <div v-if="currentSection === 'otp'">

          <span class="login101-form-title p-b-6">Please Enter the Verification Code</span>


            <div class="otp-input-label">
              <p>
                An OTP has been sent to your email address {{ customer.primaryEmail }}
              </p>
            </div>
            <div class="otp-input-row">
              <input
                class="otp-input"
                :class="otp[index] == '' ? '' : 'active__input'"
                v-for="(digit, index) in otp"
                :key="index"
                type="text"
                maxlength="1"
                v-model="otp[index]"
                @input="handleInput(index,$event)"
                @keydown="handleOtherKeys(index,$event)"
                ref="otpInput"
                required
              />  
              <span class="focus-input100" data-placeholder="Email"></span> 
            </div>
            <!-- Submit OTP and Go Back button -->
            <div class="container-login100-form-btn">
              <button class="login100-form-btn" @click="submitOTP" :disabled="!isValidOTP">Submit OTP</button>
            </div>

            <div v-if="showErrorBanner" class="wrap-error">
              <transition name="shake">
                <p class="error">{{ errorMessage }}</p>
              </transition>
            </div>

            <div v-if="currentSection === 'otp'" 
            class="bottom-link">
              <p class="resend-active"
              v-if="enableResendOtp" @click="requestOTP">
                Didn't receive OTP yet? Click here to resend OTP
              </p>
              <p v-else-if="isOtpResent">
                OTP has been sent..
              </p>
              <p v-else>
                You can resend OTP in {{ otpCounter }} secs
              </p>
            </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import MoonLoader from "@/components/elements/MoonLoader.vue";
import validationsMixin from "@/mixins/validationsMixin.js";
import authenticationMixin from "@/mixins/authenticationMixin.js";

export default {
  // Component Name
  name: "SignUp",
  // mixins used in this component
  mixins: [validationsMixin, authenticationMixin],
  // components used
  components: {
    "moon-loader": MoonLoader,
  },
  // data used in component
  data() {
    return {
      // props for moon-loader
      loaderSize: "100px",
      loaderColor: "#2ebfac",
      currentSection: 'sign-up',
      //currentSection: 'otp',
      enableResendOtp: false,
      otpCounter:30,
      isOtpResent: false,
      isValidOTP: false,
      otpString:"",
      otp: Array(6).fill(""), // Initialize 6 empty fields
      errorMessage: '',
      isEmailVerified: false,
      loaderMessage: "Signing up",
      showErrorBanner: false,
      phoneRegex: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
      isLoading: false,
      minDateOfBirth: null,
      maxDateOfBirth: null,
      // should contain 1 for valid fields
      validations: {
        customer: {
          lastName: 1,
          firstName: 1,
          primaryEmail: 1,
          dateOfBirth: 1,
          lastFourDigitsOfSsn: 1,
          phoneNumber: 1,
          whatsAppNumber: 1,
        },
        otp: 1,
      },
      sameAsAbove: true,
      customer: {
        lastName: null,
        firstName: null,
        primaryEmail: null,
        dateOfBirth: null,
        lastFourDigitsOfSsn: null,
        phoneNumber: null,
        shouldBeValidated: true,
        whatsAppNumber: null,
      },
      errors: {
        customer: {
          lastName: null,
          firstName: null,
          primaryEmail: null,
          dateOfBirth: null,
          phoneNumber: null,
          whatsAppNumber: null,
          lastFourDigitsOfSsn: null,
        },
        otp: [],
      },
    };
  },

  computed: {
    dateOfBirthModel: {
      get() {
        if (undefined == this.customer.dateOfBirth) {
          return null;
        }
        const dateArray = this.customer.dateOfBirth.split("-");
        var formattedDate = "";
        if (dateArray[1]) {
          // mm
          formattedDate += dateArray[1];
        }
        if (dateArray[2]) {
          // dd
          formattedDate += "/" + dateArray[2];
        }
        if (dateArray[0]) {
          // yyyy
          formattedDate += "/" + dateArray[0];
        }
        return formattedDate;
      },
      set(value) {
        const dateArray = value.split("/");
        var formattedDate = "";
        if (dateArray[2]) {
          // yyyy
          formattedDate += dateArray[2];
        }
        if (dateArray[0]) {
          // mm
          formattedDate += "-" + dateArray[0];
        }
        if (dateArray[1]) {
          // dd
          formattedDate += "-" + dateArray[1];
        }
        this.customer.dateOfBirth = formattedDate;
      },
    },
    phoneNumberModel: {
      get() {
        // Retrieve the phone number from the customer object or return null if undefined
        return this.customer.phoneNumber ? this.customer.phoneNumber : null;
      },
      set(value) {
        // Set the phone number in the customer object
        this.customer.phoneNumber = value;
        // set the whatsAppNumber also, if "sameAsAbove" is checked
        if (this.sameAsAbove) this.customer.whatsAppNumber = value;
      },
    },
    // getter and setter for whatsAppNumber value
    whatsAppNumberModel: {
      get() {
        // returns the value of customer.whatsAppNumber
        return this.customer.whatsAppNumber;
      },
      set(value) {
        // set the whatsAppNumber value
        this.customer.whatsAppNumber = value;
        // set the whatsAppNumber also, if "sameAsAbove" is checked
        if (this.sameAsAbove) this.customer.phoneNumber = value;
      }
    },
    /*
    isValidOTP() {
      // Check if the OTP is a 6-digit number
      return /^\d{6}$/.test(+this.otp.join(''));
    }
    */
  },

  watch: {
    otp(arr) {
      let otpStr="";
      for (let i=0;i<arr.length;i++) {
        otpStr= otpStr + arr[i].toString();
      }
      if(/^\d{6}$/.test(otpStr)) {
        this.otpString=otpStr;
        this.isValidOTP=true;
      } else {
        this.otpString="";
        this.isValidOTP=false;
      }
    }
  },

  methods: {
    init: function () {
      // set default values
      this.setDateOfBirthRange();
    },

    showError(message) {
      this.errorMessage = message;
      this.showErrorBanner = true;
      this.otp=Array(6).fill("");
    },

    closeErrorBanner() {
      this.showErrorBanner = false;
    },

    handleInput(index,$event) {
      //accept only digits
      this.otp[index]=$event.target.value.replace(/\D/g, "");
      if (this.otp[index].length === 1 && index < this.otp.length - 1) {
        //go to next box
        this.$refs.otpInput[index + 1].focus();
      }
    },
    handleOtherKeys(index,$event) {
      if ($event.key === 'ArrowLeft') {
        // Prevent default action for the left arrow key
        $event.preventDefault();
        return;
      }
      if ($event.key === 'Backspace') {
        //trace back to preceding box
        if (this.otp[index] === '' && index > 0) {
          this.$refs.otpInput[index - 1].focus();
        }
      }
    },

    signUpUser: async function () {
      this.isLoading = true
      this.errorMessage = ''
      let response = await this.signUp(this.customer);
      // 201, 409 -> OTP page
      // else show error
      if (
        response.status == 201 &&
        response.data != null
      ) {
        // User Created in DB, check if the user email is already verified
        window.localStorage.setItem('userUuid', response.data?.userUuid);
        window.localStorage.setItem('userEmail', this.customer.primaryEmail);
        await this.requestOTP(this.customer.primaryEmail,response.status)
        this.isLoading = false
      } else if (
        response.status == 409
      ) {
        // User already exits in database, go to sign in page
        // window.localStorage.setItem('userUuid', response.data?.userUuid);
        window.localStorage.setItem('userEmail', this.customer.primaryEmail);
        //this.currentSection = 'otp'
        /*this.errorMessage = response.data;*/
        //await this.requestOTP(this.customer.primaryEmail)
        await this.requestOTP(this.customer.primaryEmail,response.status);
        this.isLoading = false;
      } else {
        // Default case
        this.errorMessage = 'An error occurred. Please try again later.';
        this.currentSection = 'sign-up'
        this.isLoading = false;
      }
    },

    handle4XXresponse(response) {
      if(response.status == 400) {
        this.showError("Bad request");
      } else if(response.status  == 401) {
        if(this.currentSection == 'otp') {
          this.showError("Wrong OTP entered");
        } else {
          this.showError("Error occured at gateway validation. Please try again.");
        }
      } else if (response.status == 404) {
        this.showError(response.data.error);
      } else if(response.status  == 429) {
        this.isAccountLocked=true;
        const retryAfter = response.headers['retry-after'] || 1000
        this.showError(`Account locked due to too many failed sign-up attempts, please try after ${Math.ceil(retryAfter/60)} minutes`);
      } else {
        // Handle addition errors if any
        this.showError(response.data)
      }
    },
    acknowledgeResentOtp: async function () {
      this.isOtpResent=true;
      await new Promise(resolve => setTimeout(resolve, 3000));
      this.isOtpResent=false;
    },
    startTimertoResendOtp: async function() {
      for(var i=30;i>=0;i--) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.otpCounter = i;
      }
      this.enableResendOtp = true;
    },
    requestOTP: async function (email,signupStatus) {
      // Proceed to OTP generation only if email is valid
      this.otpCounter = 30;
      if(this.enableResendOtp) {
        this.enableResendOtp=false;
        await this.acknowledgeResentOtp();
      }
      this.closeErrorBanner();
      let response = await this.getOTPfromAuthServer(email);
      if( response.status == 404 && signupStatus == 409) {
        this.showError("The requested phoneNumber is already in registered");
        return;
      }
      if(response.status != 500) {
        this.isEmailVerified = response.data.isEmailVerified;
        this.currentSection = 'otp';
      }
      this.startTimertoResendOtp();
    },

    goBack: function () {
      this.currentSection = 'sign-up'
      this.enableResendOtp = false;
    },

    submitOTP: async function () {
      this.closeErrorBanner();
      let response = null;
      if(this.isEmailVerified)  {
        //go to sign in if email verified
        response = await this.signIn(this.customer.primaryEmail, this.otpString);
      } else {
        response = await this.verifyEmail(this.customer.primaryEmail, this.otpString);
      }
      // for 401, show bad credentials error
      if(response.status >= 400 && response.status < 500) {  
        this.handle4XXresponse(response)  
      } else if (
        response.status !== 200 ||
        response.data === undefined
      ) {
        this.$router.push({ name: "error" });
      } else {
        let updatedTokenData = this.calculateAccessTokenExpirationTime(response.data);
        updatedTokenData = this.calculateRefreshTokenExpirationTime(updatedTokenData);
        this.$store
        .dispatch("SAVE_AUTH_DATA", updatedTokenData)
        .then(() => {
          let lastRoute = this.$store.getters.getLastRoute;
          if(lastRoute == null) {
            this.$router.push({ name: "home" });
          } else {
            this.$router.push({ name: lastRoute });
          }
        }).catch(() => {
          // push to error page
          this.$router.push({ name: "error" });
        })
      }
    },

    validateLastName: function (event) {
      this.errors.customer.lastName = this.checkLastName(event);
      this.validations.customer.lastName =
        this.errors.customer.lastName.length === 0 ? 1 : 0;
    },

    validateFirstName: function (event) {
      this.errors.customer.firstName = this.checkLastName(event);
      this.validations.customer.firstName =
        this.errors.customer.firstName.length === 0 ? 1 : 0;
    },

    validateEmail: function (event) {
      // validate email from validationsMixin
      let inputValue = event.target.value;
      if (!inputValue) return;

      this.errors.customer.primaryEmail = this.checkEmail(event);

      // set validation-icon
      this.validations.email =
        this.errors.customer.primaryEmail.length === 0 ? 1 : 0;
    },

    validateDateOfBirth: function () {
      this.errors.customer.dateOfBirth = this.checkDateRangeFromValue(
        this.customer.dateOfBirth,
        this.getFormattedDate(this.minDateOfBirth),
        this.getFormattedDate(this.maxDateOfBirth)
      );
      this.validations.customer.dateOfBirth =
        this.errors.customer.dateOfBirth.length === 0 ? 1 : 0;
    },

    validatePhoneNumber: function () {
      // Get the phone number from the model
      const phoneNumber = this.phoneNumberModel;

      // Define a regular expression pattern for US phone numbers
      const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

      // Check if the phone number matches the pattern
      if (phoneRegex.test(phoneNumber)) {
        // If valid, clear any validation error message
        this.validations.customer.phoneNumber = 1;
      } else {
        // If invalid, set validation error message
        this.validations.customer.phoneNumber = 0;
      }
    },

    validateWhatsAppNumber: function () {
      // Get the phone number from the model
      const phoneNumber = this.whatsAppNumberModel;

      // Define a regular expression pattern for US phone numbers
      const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

      // Check if the phone number matches the pattern
      if (phoneRegex.test(phoneNumber)) {
        // If valid, clear any validation error message
        this.validations.customer.whatsAppNumber = 1;
      } else {
        // If invalid, set validation error message
        this.validations.customer.whatsAppNumber = 0;
      }
    },

    setDateOfBirthRange: function () {
      var minDoB = new Date();
      minDoB.setFullYear(minDoB.getFullYear() - 100);
      this.minDateOfBirth = minDoB;
      var maxDoB = new Date();
      maxDoB.setFullYear(maxDoB.getFullYear() - 18);
      this.maxDateOfBirth = maxDoB;
    },

    getFormattedDate: function (date) {
      // extract day, month and year from date
      const dd = String(date?.getDate())?.padStart(2, "0");
      const mm = String(date?.getMonth() + 1)?.padStart(2, "0"); //January is 0!
      const yyyy = date?.getFullYear();
      // concat string to yyyy-mm-dd format
      const formattedDate = yyyy + "-" + mm + "-" + dd;
      return formattedDate;
    },

    validateLastFourDigitsOfSsn: function (event) {
      this.errors.customer.lastFourDigitsOfSsn = this.checkLastFourDigitsOfSsn(
        event
      );
      this.validations.customer.lastFourDigitsOfSsn =
        this.errors.customer.lastFourDigitsOfSsn.length === 0 ? 1 : 0;
    },

  },
  mounted() {
    this.init();
  },
  created() {
    setTimeout(() => {
      this.isSessionOut = false;
    }, 5000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* custom CSS*/

.instruction {
  color: red;
  font-size: 14px;
}

.otp-container {
  display: flex;
}

.otp-box {
  width: 60px;
  height: 60px;
  text-align: center;
  margin-right: 5px;
  /* Add spacing between input boxes */
  border: 1px solid #ccc;
  border-radius: 5px;
}

.instruction .instruction-message {
  color: red;
  font-weight: bold;
}

ul.instruction-list>li::before {
  content: "";
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: red;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
}

ul.instruction-inner-list li::before {
  content: "\1806";
  /* Add content: \1806 is the CSS Code/unicode for a bullet */
  color: red;
  /* Change the color */
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  position: absolute;
  left: 0;
}

.no-border-bottom {
  border-bottom: none;
}

.wrap-error {
  min-height: 25px;
  text-align: center;
}

.error {
  text-decoration: none;
  /*color: #bf4d2e;*/
  color: red;
  margin: 0;
  padding: 0;
}

/* Enter/Leave & List Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

/* shake transition for error*/
.shake-enter-active {
  /*transition: opacity 0.5s;*/
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

.shake-leave-active {
  /*transition: opacity 0.5s;*/
  animation: shake 0s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.shake-enter,
.shake-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

/* main */
/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  /*font-family: Poppins-Regular, sans-serif;*/
}

/*---------------------------------------------*/
a,
.link {
  /*font-family: Poppins-Regular;*/
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.link {
  cursor: pointer;
}

a:focus,
.link:focus {
  outline: none !important;
  box-shadow: none;
  -webkit-box-shadow: none;
}

a:hover,
.link:hover {
  text-decoration: none;
  color: #1ccab2 !important;
  /*color: #333333;*/
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  /*font-family: Poppins-Regular;*/
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
  box-shadow: none;
  -webkit-box-shadow: none;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}

input:-moz-placeholder {
  color: #adadad;
}

input::-moz-placeholder {
  color: #adadad;
}

input:-ms-input-placeholder {
  color: #adadad;
}

textarea::-webkit-input-placeholder {
  color: #adadad;
}

textarea:-moz-placeholder {
  color: #adadad;
}

textarea::-moz-placeholder {
  color: #adadad;
}

textarea:-ms-input-placeholder {
  color: #adadad;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
  [ Utility ]*/
.txt1 {
  /*font-family: Poppins-Regular;*/
  font-size: 15px;
  color: #999999;
  line-height: 1.5;
}

.txt2 {
  /*font-family: Poppins-Regular;*/
  font-size: 15px;
  color: #2ebfac;
  line-height: 1.5;
}

.error-text {
  font-size: 15px;
  font-weight: bold;
  color: red;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
}

/*//////////////////////////////////////////////////////////////////
  [ login ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 60vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f8f8f8;
}

.wrap-login100 {
  width: 420px;
  background: #f8f8f8;
}

/*------------------------------------------------------------------
  [ Form ]*/

.login100-form {
  width: 100%;
}

.login100-form-title {
  display: block;
  font-weight: bold;
  /*font-family: Poppins-Bold;*/
  font-size: 52px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  caret-color: transparent;
}

.login101-form-title {
  display: block;
  font-weight: bold;
  /*font-family: Poppins-Bold;*/
  font-size: 35px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}

.login100-form-avatar {
  display: block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.login100-form-avatar img {
  width: 100%;
}

/*------------------------------------------------------------------
  [ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #d9d9d9;
}

.my-1 {
  margin: 1.5rem 0rem;
}

.wrap-input100-noborder {
  width: 100%;
  position: relative;
}

.input100 {
  /*font-family: Poppins-SemiBold;*/
  font-size: 18px;
  color: #555555;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 52px;
  background: transparent;
  padding: 0 5px;
}

/*---------------------------------------------*/
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #2ebfac;
}

.focus-input100::after {
  /*font-family: Poppins-Medium;*/
  font-size: 18px;
  color: #999999;
  line-height: 1.2;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 15px;
  left: 0px;
  padding-left: 5px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus+.focus-input100::after {
  top: -20px;
  font-size: 15px;
}

.input100:focus+.focus-input100::before {
  width: 100%;
}

.has-val.input100+.focus-input100::after {
  top: -20px;
  font-size: 15px;
}

.has-val.input100+.focus-input100::before {
  width: 100%;
}

/*------------------------------------------------------------------
  [ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
}

.align-middle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  /*font-family: Poppins-Medium;*/
  font-size: 16px;
  color: #fff !important;
  line-height: 1.2;
  /*text-transform: uppercase;*/

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 30%;
  width: fit-content;
  height: 40px;
  background-color: #2ebfac;
  border-radius: 25px;

  border: none !important;

  box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -moz-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -webkit-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -o-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -ms-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background-color: #333333;
  box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
}

/*------------------------------------------------------------------
  [ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  /*background-color: #fff;*/
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0px;
  pointer-events: none;

  /*font-family: Poppins-Regular;*/
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  font-size: 16px;
  color: #c80000;

  display: block;
  position: absolute;
  /*background-color: #fff;*/
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

/*//////////////////////////////////////////////////////////////////
  [ Login more ]*/
.login-more li {
  position: relative;
  padding-left: 16px;
}

/* util */

.p-t-25 {
  padding-top: 25px;
}

.p-t-85 {
  padding-top: 85px;
}

.p-t-190 {
  padding-top: 190px;
}

.p-b-70 {
  padding-bottom: 70px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-l-15 {
  padding-left: 15px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-35 {
  margin-bottom: 35px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-l-20 {
  margin-left: 20px;
}

.bottom-link {
  text-align: center;
  caret-color: transparent;
  margin-top: 20px;
}
.bottom-link p {
  font-size: 15px;
  font-weight: 600;
  opacity: 0.8;
}
.bottom-link .resend-active {
  cursor: pointer;
}
.bottom-link .resend-active:hover {
  opacity: 1;
}
.bottom-link .resend-active:active {
  color: #2ebfac;
}
.otp-input {
  text-align: center;
  width: 38px;
  border: 2px solid #babab4;
  background-color: #f8f8f8;
  padding: 0%;
  border-radius: 2px;
  margin: 0 6px 0 6px;
}

.otp-input:focus {
  border: 2px solid #2ebfac !important;
  box-shadow: 0 0 4px #2ebfac;
}
.active__input {
  border: 2px solid #2ebfac;
}

.otp-input-label {
  display: flex;
  padding: 24px 0 8px 0;
  margin: 0%;
  justify-content: center;
  caret-color: transparent;
}
.otp-input-label > p {
  font-size: medium !important;
  text-align: center;
}
.otp-input-row {
  display: flex;
  padding: 0 0 30px 0;
  flex-direction: row;
  justify-content: center;
}
</style>