<template>
    <div v-show="isOpenToUpdate" class="files__drive" :class="{ mask__panel : isMaskEnabled }">
        <div v-if="isMaskEnabled" class="mask__layer"></div>
        <div class="drive__info">
            <div class="drive__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-database-fill-lock" viewBox="0 0 16 16">
                    <path d="M8 1c-1.573 0-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4s.875 1.755 1.904 2.223C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777C13.125 5.755 14 5.007 14 4s-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1"/>
                    <path d="M3.904 9.223C2.875 8.755 2 8.007 2 7v-.839c.457.432 1.004.751 1.49.972C4.722 7.693 6.318 8 8 8s3.278-.307 4.51-.867c.486-.22 1.033-.54 1.49-.972V7c0 .424-.155.802-.411 1.133a4.5 4.5 0 0 0-1.364-.125 3 3 0 0 0-2.197.731 4.5 4.5 0 0 0-1.254 1.237A12 12 0 0 1 8 10c-1.573 0-3.022-.289-4.096-.777M8 14c-1.682 0-3.278-.307-4.51-.867-.486-.22-1.033-.54-1.49-.972V13c0 1.007.875 1.755 1.904 2.223C4.978 15.711 6.427 16 8 16q.134 0 .266-.003A2 2 0 0 1 8 15zm0-1.5q0 .15.01.3A2 2 0 0 0 8 13c-1.573 0-3.022-.289-4.096-.777C2.875 11.755 2 11.007 2 10v-.839c.457.432 1.004.751 1.49.972C4.722 10.693 6.318 11 8 11q.13 0 .257-.002A4.5 4.5 0 0 0 8 12.5"/>
                    <path d="M9 13a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1"/>
                </svg>
            </div>
            <div class="drive__title">
                <h2>Uploaded&nbsp;Files</h2>
            </div>
        </div>
        <folder-item v-if="nodes != []" :nodes="nodes" :isContextEnabled="isContextEnabled"></folder-item>
    </div>
</template> 

<script>
import {bus} from '../../../main';
import FolderItem from './FolderItem.vue';
import { generateTreeView,updateFolderStatusInTree,
     getNodeProperty,generateFolderRegister,
     setTwoAttributeOfAllFiles} from "../../../fileManagerUtils.js";

export default {
    name: "FolderviewPanel",
    components: {
        "folder-item" : FolderItem,
    },
    props: ["existingFiles","uploadedFilesList","isFilesUploaded",
    "isSelectFilesEnabled","isInitialLoadingDone",
    "closeFileSelection","isLoading"],
    data() {
        return {
            isMaskEnabled:false,
            isContextEnabled:true,
            lastClickedFileId:null,
            lastSelectedFile: null,
            lastContextEl: null,
            selectedFileObj: null,
            fileUrl: null,
            deleteFailed: false,
            lastselectedNode: {
                nodeType: null,
                htmlElement: null,
            },
            driveElement: null,
            selectedFolder: null,
            currentFolderId: null,
            currentFoldersFileIds: [],
            disableSelectButton: false,
            disableMoveButton:true,
            selectionButtonValue:"Select Files",
            selectedFolderIds: [],
            nodes:[],
            folderRegister: {},
            updatesCount:0,
            isOpenToUpdate:false,
            currentWorkingFilesList:[],
            defaultFoldersPaths: [
                "Assets/Statements",
                "Income/Paychecks/W2",
                "Immigration/Drivers_License",
                "Title_Documents",
                "Purchase_contract_Amendments",
                "Insurance",
                "Appraisal",
                "Survey",
                "Others",
                "Credit_Report",
                "Underwriting_Conditions"
            ],
            defaultFoldersList: []
        }
    },
    
     watch: {  
        selectedFolderIds(value) {
            if(value.length>0) {
                this.selectionButtonValue=value.length + " files selected";
                this.disableSelectButton=true;
                this.disableMoveButton=false;
            } else {
                this.disableSelectButton=false;
            }
        }, 
        isFilesUploaded(status) {
           
           //update uploaded files into the working list and tree
           if(status && this.uploadedFilesList.length > 0) {
                this.$parent.isLoading=true;
                this.reloadFilesFromDb();
           }
       },
       closeFileSelection() {
            this.isContextEnabled=true;
            if(this.lastClickedFileId != null) {
                setTwoAttributeOfAllFiles(this.nodes,this.lastClickedFileId,'isSelectionEnabled',false,
                    'isFileSelected',false);
            }
       },
       isLoading(status) {
            this.isMaskEnabled=status;
       },
       isInitialLoadingDone(status) {
           if(status) {
            this.init();
           }
       }
    },

    methods: {

        init: async function() {
            //await new Promise(resolve => setTimeout(resolve, 3000));

            //generate folder and files Tree

            //loading default folders
            this.defaultFoldersPaths.forEach(pathValue => {
                var pathObj = {};
                pathObj.filename = "no-file",
                pathObj.isFile = false,
                pathObj.path = pathValue,
                this.defaultFoldersList.push(pathObj);
            });

            //loading initial files into working list
            this.existingFiles.forEach(existingfileObj => {
                var fileObj = {};
                fileObj.filename = existingfileObj.name;
                fileObj.isFile = true;
                fileObj.lastUpdatedOn = existingfileObj.lastUpdatedOn;
                fileObj.fileFormat = existingfileObj.fileFormat;
                fileObj.url = existingfileObj.link.href;
                if (existingfileObj.path !=null) {
                    fileObj.path = existingfileObj.path;
                } else {
                    fileObj.path = ":root";
                }
                fileObj.fileId = existingfileObj.fileId;
                this.currentWorkingFilesList.push(fileObj);
            });
            // adding default folders also in the working list
            this.currentWorkingFilesList.push(...this.defaultFoldersList);
            this.nodes = generateTreeView(this.currentWorkingFilesList);
            // store address ids of folders
            generateFolderRegister(this.nodes, this.folderRegister);
            this.isOpenToUpdate=true;
            this.$parent.isLoading=false;
        },

        reloadFilesFromDb: async function() {
            await this.reloadFileStore();
            this.$parent.isUploadOperation=false;
            this.$parent.isLoading=false;
            this.$parent.isFilesUploaded=false;
        },

        //reload tree and the working list with the latest database
        reloadFileStore: async function() {
            //buffer time to wait for database to update
            await new Promise(resolve => setTimeout(resolve, 2000));
            await this.$parent.getExistingCustomerFiles();
            //update current working List
            this.currentWorkingFilesList=[];
            this.existingFiles.forEach(existingfileObj => {
                var fileObj = {};
                fileObj.filename = existingfileObj.name;
                fileObj.isFile = true;
                fileObj.lastUpdatedOn = existingfileObj.lastUpdatedOn;
                fileObj.fileFormat = existingfileObj.fileFormat;
                fileObj.url = existingfileObj.link.href;
                if (existingfileObj.path !=null) {
                    fileObj.path = existingfileObj.path;
                } else {
                    fileObj.path = ":root";
                }
                    fileObj.fileId = existingfileObj.fileId;
                    this.currentWorkingFilesList.push(fileObj);
            });
            // adding default folders also in the working list
            this.currentWorkingFilesList.push(...this.defaultFoldersList);
            //reload node Tree
            this.nodes = generateTreeView(this.currentWorkingFilesList);
            //update open folders in the tree
            updateFolderStatusInTree(this.nodes,this.folderRegister);
        },

        removeFolderIdsFromCheckList(folderId) {
            if(this.selectedFolderIds.length > 0) {
                let index = this.selectedFolderIds.indexOf(folderId);
                this.selectedFolderIds.splice(index,1);
            }
        },
    },
        
    mounted() {

        // bus to listen events on folders
        bus.$on('node_selected',(nodeType,targetNode)=> {
            if(!this.$parent.isHandHeldDevice) {
                if(nodeType == "Folder") {
                    this.$parent.isActiveTab = false;
                    this.$parent.tabSelected = "Folder";
                } else {
                    this.$parent.isActiveTab = true;
                    this.$parent.tabSelected = "File";
                }
            } else this.$parent.isActiveTab = false;
            if(this.lastselectedNode.htmlElement != null) {
                this.lastselectedNode.htmlElement.closest('.folder__details').classList.remove('folder__selected');
                if(this.lastselectedNode.nodeType == "Folder") {
                    this.lastselectedNode.htmlElement.closest('.folder__details').children[0].children[1].children[1].children[1].style.color="#babab4";
                }
            }
            if(this.driveElement != null) {
                this.driveElement.classList.remove('drive__selected');
            }
            if(nodeType == "Folder") {
                targetNode.closest('.folder__details').children[0].children[1].children[1].children[1].style.color="#2ebfac";
            }
            targetNode.closest('.folder__details').classList.add('folder__selected');
            this.lastselectedNode.htmlElement=targetNode;
            this.lastselectedNode.nodeType=nodeType;

            this.currentFolderId=targetNode.closest('.folder__details').parentElement.getAttribute('data-node-id');
            //check if node is file
            if(!targetNode.closest('.folder__details').children[0].children[1].classList.contains('folder__info')) {
                //selected node is file
                let fileId = getNodeProperty(this.currentFolderId,this.nodes,'fileId');
                let selectedFileObj = this.currentWorkingFilesList.find(fileObj => fileObj.fileId === fileId);
                this.selectedFileObj = selectedFileObj;
                this.fileUrl = selectedFileObj.url;
                this.$emit('file-selected',selectedFileObj);
            }
            this.$parent.currentPath = getNodeProperty(this.currentFolderId,this.nodes,'path');
        });

        bus.$on('update_folder_status',(node)=> {
            this.folderRegister[node.path].isActive=node.isActive;
        });

        //listen to mask
        bus.$on('turn-off-mask',()=> {
            this.isMaskEnabled=false;
            this.$parent.isProgressEnabled=false;
            this.$parent.isLoading=false;
        });
    },

    beforeDestroy() {
        //removing all events
        bus.$off('node_selected');
        bus.$off('update_folder_status');
        bus.$off('turn-off-mask');
    }

}
</script>

<style>
.files__drive {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    /* border: 1px solid green; */
    padding: 0%;
}
.files__drive > div {
    display:flex;
    flex-direction: row;
}
.drive__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top:15px;
    margin-bottom: 10px;
    max-height: 30px;
    /* border: 1px solid black; */
}
.drive__icon {
    margin-bottom: 15px;
    margin-right: 10px;
}

h3 {
    margin: 0%;
    line-height: 150%;
    color:#282626;
}
.mask__panel {
  position: relative;
  overflow-y: hidden;
}
.mask__layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #babab4;
  opacity: 0.1;
  z-index: 10050;
}
</style>