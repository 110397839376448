<template>
    <div class="file__wrapper">
      <i
        v-bind:class="{
          'fa fa-file-image-o': 
            fileInfo.fileFormat === 'image/jpeg' ||
            fileInfo.fileFormat === 'image/png',
          'fa fa-file-pdf-o':
            fileInfo.fileFormat === 'application/pdf',
          'fa fa-file-o':
            fileInfo.fileFormat !== 'application/pdf' &&
            fileInfo.fileFormat !== 'image/png',
        }"
      >
      </i>
      <div class="file__name">
        <p>{{ fileInfo.name }}</p>
        <div class="chip"></div>
        <div class="file__toolTip">
         <p>{{ fileInfo.name }}</p>
         <p>{{ calendarDate(fileInfo.lastUpdatedOn) }},&nbsp;fileformat:{{ fileInfo.fileFormat.split('/')[1].toUpperCase() }}</p>
        </div>
      </div>
      <div v-if="showDate" class="file__date">
        <p>
          {{ convertDate(fileInfo.lastUpdatedOn) }}
        </p>
      </div>
    </div>
</template>

<script>
import fileUploadMixin from '../../../mixins/fileUploadMixin';
import { saveAs } from "file-saver";


export default {
    name: "FileItem",
    props: ["fileInfo"],
    mixins: [fileUploadMixin],
    data() {
      return {
        showDate: false,
      }
    },
    mounted() {
      //get screen size
      this.showDate = (window.innerWidth <= 440) ? false: true;
    },
    methods: {

      convertDate(date) {
        let dateObj = new Date(date);
        let dateValue = (dateObj.getMonth() + 1) + '/' + (dateObj.getDate()) + '/' + (dateObj.getFullYear());

        //time
        let timeValue = dateObj.toLocaleString([], {
          hour: '2-digit',
          minute: '2-digit'
        });

        return dateValue + " " + timeValue;
      },

      calendarDate(date) {
        let dateObj = new Date(date);
        let formattedDate = dateObj.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        }).replace(/ /g, ' '); // Replace spaces with single space

        let formattedTime = dateObj.toLocaleString([], {
          hour: '2-digit',
          minute: '2-digit',
        });

        return formattedDate + " " + formattedTime;
      },

      async downloadFile(fileInfo) {
      this.loaderMessage = "Downloading";
      this.isLoading = true;
      let response = await this.downloadCustomerFile(fileInfo.url);
      this.isLoading = false;
      if (response.status == 404) {
        alert("404, File Not Found");
        return;
      }
      if (response.status == 500) {
        alert("Internal Server Error, please try again later");
        return;
      }
      if (response.status !== 200) {
        alert("Unexpected error, Please try again later");
        return;
      }
      if (response.status == 200 && response.data.size == 0) {
        alert("Something went wrong, Please try again later");
        return;
      }
      if (response.data) {
        var file_blob = new Blob([response.data], { type: fileInfo.fileFormat });
        saveAs(file_blob, fileInfo.name);
      }
    },
    }
}
</script>

<style scoped>
.file__wrapper > i {
  font-size: 20px;
}
.file__wrapper {
  display: -webkit-box;
  width: 100%;
  color: #464646;
  font-weight: 400;
  font-size: large;
  padding-top: 5px;
}
.fa-file-pdf-o {
  color: #b70a00;
  margin-right: 8px;
  font-size: 17px;
}
.fa-file-o {
  margin-right: 8px;
  font-size: 17px;
}
.fa-file-image-o {
  color: #015f68;
  opacity: 0.8;
  margin-right: 8px;
  font-size: 17px;
}
.file__name {
  width: 60%;
  position: relative;
  cursor:default;
}

.file__name > p {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 440px) {
  .file__name > p {
    width: 200px;
  }
}

.download-button {
  display: inline;
  opacity: 0.4;
  font-size: medium;
  margin-left: 10px;
  position: relative;
}
.download-button:hover{
  cursor: pointer;
  opacity: 0.9;
}

.download-button .download-file-info {
  height: 20px;
  border-radius: 2px;
  width: fit-content;
  display: inline-block;
  margin-top: -20px;
  margin-left: -30px;
  padding: 0 10%;
  visibility: hidden;
  background-color: #464646;
  text-align: center;
  color: white;
  font-size: x-small;
  z-index:10110;
}

.download-button:hover .download-file-info {
  visibility: visible;
}

.download-button:active .download-file-info {
  visibility: hidden;
}

.file__date {
  width:20%;
  min-width: 100px;
  margin-right: 10px;
}

.file__date p {
  font-size:12px;
  color:#666666;
  margin: 0%;
  display: inline;
  text-wrap: nowrap;
}

.file__name .file__toolTip {
    position: absolute;
    margin-left: 40px;
    margin-top: -30px;
    background-color: #666666;
    color:whitesmoke;
    height:fit-content;
    font-size: x-small;
    width:fit-content;
    padding: 0%;
    z-index: 10300;
    border-radius: 2px;
    visibility: hidden;
    transition-property: visibility;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    transition-delay: 0s;
}

.file__name .chip {
  background-color: #666666;
  height: 8px;
  width: 8px;
  rotate: 45deg;
  position: absolute;
  margin-left: 55px;
  margin-top: -34px;
  z-index: 10299;
  visibility: hidden;
  transition-property: visibility;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  transition-delay: 0s;
}
.file__name .file__toolTip p {
    padding: 2px 10px 0 10px;
    margin: 0%;
    text-wrap: nowrap;
}
.file__name .file__toolTip p:last-child {
    padding: 2px 10px 5px 10px;
    margin: 0%;
    text-wrap: nowrap;
}
.file__name:hover .file__toolTip {
    visibility: visible;
    transition-duration: 2s;
    transition-timing-function: ease-out;
    transition-delay: 2s;
}
.file__name:hover .chip {
    visibility: visible;
    transition-duration: 2s;
    transition-timing-function: ease-out;
    transition-delay: 2s;
}


</style>