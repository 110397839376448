var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file__wrapper"},[_c('i',{class:{
      'fa fa-file-image-o': 
        _vm.fileInfo.fileFormat === 'image/jpeg' ||
        _vm.fileInfo.fileFormat === 'image/png',
      'fa fa-file-pdf-o':
        _vm.fileInfo.fileFormat === 'application/pdf',
      'fa fa-file-o':
        _vm.fileInfo.fileFormat !== 'application/pdf' &&
        _vm.fileInfo.fileFormat !== 'image/png',
    }}),_c('div',{staticClass:"file__name"},[_c('p',[_vm._v(_vm._s(_vm.fileInfo.name))]),_c('div',{staticClass:"chip"}),_c('div',{staticClass:"file__toolTip"},[_c('p',[_vm._v(_vm._s(_vm.fileInfo.name))]),_c('p',[_vm._v(_vm._s(_vm.calendarDate(_vm.fileInfo.lastUpdatedOn))+", fileformat:"+_vm._s(_vm.fileInfo.fileFormat.split('/')[1].toUpperCase()))])])]),(_vm.showDate)?_c('div',{staticClass:"file__date"},[_c('p',[_vm._v(" "+_vm._s(_vm.convertDate(_vm.fileInfo.lastUpdatedOn))+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }