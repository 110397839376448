<template>
    <div class="folder__box">
        <div class="folder__child" :data-node-id="node.id" v-for="node in nodes" :key="node.id">
            <div class="folder__details"
            @click="selectFolder($event,node)"
            @dblclick.stop="handleDoubleClick(node)"
            :class="{ highlight_selection : node.isSelectionEnabled }">
                <div class="folder__icon">
                    <div @click.stop="selectArrow(node)" class="folder__arrow"
                     :class="node.nodes.length > 0 && node.type == 'Folder' ? 'show__arrow' : 'hide__arrow'">
                        <i class="fa" :class="node.isActive ? 'fa-angle-down' : 'fa-angle-right'"></i>
                    </div>
                    <div v-if="node.type == 'File' && node.isSelectionEnabled == true" class="file__info">
                    </div>
                    <div v-if="node.type==='Folder'" class="folder__info">
                        <div>
                            <svg v-if="!node.isActive || node.nodes.length == 0" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 512 512">
                                <path d="M64 480H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H288c-10.1 0-19.6-4.7-25.6-12.8L243.2 57.6C231.1 41.5 212.1 32 192 32H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64z"/>
                            </svg>

                            <svg v-else xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="0 0 576 512">
                                <path d="M88.7 223.8L0 375.8 0 96C0 60.7 28.7 32 64 32l117.5 0c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7L416 96c35.3 0 64 28.7 64 64l0 32-336 0c-22.8 0-43.8 12.1-55.3 31.8zm27.6 16.1C122.1 230 132.6 224 144 224l400 0c11.5 0 22 6.1 27.7 16.1s5.7 22.2-.1 32.1l-112 192C453.9 474 443.4 480 432 480L32 480c-11.5 0-22-6.1-27.7-16.1s-5.7-22.2 .1-32.1l112-192z"/>
                            </svg>
                        </div>
                            <div class="folder__name">
                                <p>{{setProperName(node.name)}}</p>
                                <p>
                                {{ node.fileCount }}&nbsp;{{ node.fileCount == 1 ? "File" : "Files" }}{{ subFolderDetails(node) }}
                                </p>
                            </div>
                    </div>
                    
                    <div v-if="node.type == 'File'" class="file__info">
                        <FileItem :fileInfo="node"></FileItem>
                    </div>
                </div>
            </div>
            <div :class="{ hide__child : !node.isActive }" open>
                <FolderItem v-if="node.nodes" :nodes="node.nodes" :isContextEnabled="isContextEnabled"/>
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from '../../../main';
import FileItem from "./FileItem.vue";
export default {
    name: "FolderItem",
    props: [ 'nodes', 'isContextEnabled' ],
    components: {FileItem},
    data() {
        return {
            isContextProcessing:false,
            isShowInfoEnabled:false,
        }
    },
    methods: {
        handleDoubleClick(node) {
            if(node.nodes.length > 0 && node.type == "Folder") {
                node.isActive=!node.isActive;
                //toggle folder
                bus.$emit('update_folder_status',node);
            }
        },
        subFolderDetails(node) {
            let folderCount = node.nodes.filter(child => { return child.type == "Folder" }).length;
            if(folderCount == 1) {
                return ", " + folderCount + " " + "Folder";
            } else if(folderCount > 1) {
                return ", " + folderCount + " " + "Folders";
            } else {
                return "";  
            }
        },
        setProperName(name) {
            let word = name;
            if(word.includes('_')) {
                word = word.replace(/_/g,' ');
            }
            return word;
        },
        selectFolder($event,node) {
          //  if(node.type == "Folder") {
                bus.$emit('node_selected',node.type,$event.target);
          //  }
        },
        selectArrow(node) {
            node.isActive=!node.isActive;
            //toggle folder
            bus.$emit('update_folder_status',node);
        }
    }
}

</script>

<style scoped>
.folder__box {
    margin: 0%;
    padding: 0%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.select__box__empty {
    color:#282626;
    margin-right: 8px;
}
.select__box__filled {
    color:#282626;
    margin-right: 8px;
}
.hide__box {
    display: none;
}
.folder__child {
    display:flex;
    /* visibility: collapse; */
    flex-direction: column;
    width: 500px;
    justify-content: left;
    align-items: left;
    flex-wrap:nowrap;
    /*border-left:1px dotted black;*/
    padding-left:20px;
    margin-left: 0px;
    border-left:1px dotted #bab4b4;
    caret-color: transparent;
}
@media screen and (max-width: 440px) {
  .folder__child {
    width: 400px;
  }
}
.hide__child {
    display: none;
}
.folder__details {
    background-color: transparent;
    /*max-height: 40px;*/
    padding: auto;
    max-height: 150px;
    min-height: 50px;
    border: 1px solid transparent;
}
.folder__details:hover {
    border: 1px solid #bab4b4;
}
.select__file {
    border: 1px solid #2ebfac;
}
.open__context {
    border: 1px solid #2ebfac;
}
.folder__selected {
    background-color:#bab4b4;
}
.folder__icon {
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    color:rgb(21, 190, 187);
    /*margin-left: 18px;*/
}
.folder__name {
    margin-left:8px;
    color:#282626 !important;
    font-size: larger;
    font-weight: 500;
}
.folder__name p {
    margin: 0%;
}
.folder__name :nth-child(2) {
    color: #babab4;
    font-size:12px;
    margin: 3px 0 0 0;
    font-weight: 300;
}
.show__arrow  {
    margin-right:10px;
}
.show__arrow:hover {
    cursor: pointer;
}
.folder__arrow {
    font-size: 18px;
    margin-left: -1px;
}
.hide__arrow {
    margin-right:10px;
    color: transparent !important;
}
.folder__open {
    transform:rotate(90deg);
}
.fa .fa-angle-right {
    font-size:20px;
    opacity:1;
}
.folder__info {
    display:flex;
    flex-direction:row;
    line-height: 1; 
    margin: 0 0 0 5px;
    padding:0%;
    color: #2ebfac;
}
.file__info {
    display:flex;
    position: relative;
    max-height: 40px;
    width: 100%;
    flex-direction:row;
    margin: 0 0 0 5px;
    padding:0%;
}
.file__selector > div {
    cursor: pointer;
}
h3 {
    margin: 0%;
    line-height: 150%;
    color:#282626;
}
.showInfo__menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* visibility: hidden; */
    position: absolute;
    color: whitesmoke;
    font-size: smaller;
    background-color: #464242;
    margin-top: 5%;
    margin-left: 30%;
    border: 1px solid #6e6a6a;
    border-radius:2px;
    z-index: 10001;
    width:fit-content;
}
.context__menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    visibility: hidden;
    position: absolute;
    color: #282626;
    background-color: whitesmoke;
    margin-top: 5%;
    margin-left: 30%;
    border: 1px solid #6e6a6a;
    z-index: 10001;
    width: 150px;
}
.context__menu div {    
    padding: 2% 0 2% 10%;
    border-bottom:1px dotted #6e6a6a;
    font-weight: 400;
    opacity: 0.8;
    cursor: pointer;
}
.context__menu div:hover {
    font-weight: 900;
    opacity: 1;
}
.context__menu div:nth-of-type(3) {
    cursor: not-allowed !important;
    font-weight: 400 !important;;
    opacity: 0.8 !important;
}
.highlight_selection {
    background-color: #ebe7e7;
}
.active__text {
    color:#2ebfac;
}
</style>