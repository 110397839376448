<template>
  <div>
    <header id="header">
        <!-- Logo -->
        <span class="logo">
          <router-link :to="{ name: 'home' }">
            <!--span class="image-logo">
              <img src="@/assets/images/logo-website.jpeg" alt="website logo" />
            </span-->
            American Smart Lending
          </router-link>
        </span>
      <!-- Nav -->
      <nav id="nav">
        <ul>
          <li>
            <router-link :to="{ name: 'urla' }">Apply for a loan</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'upload' }">Upload Files</router-link>
          </li>
          <li
            class="link depth-0"
            ref="services"
            @mouseover="expandDropdown('services','servicesList',100)"
            @mouseleave="collapseDropdown('services')"
          >
            Services
            <span class="fa fa-angle-down" aria-hidden="true"></span>
          </li>
          <li>
            <router-link :to="{ name: 'contact' }">Contact</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'success-stories' }">Success Stories</router-link>
          </li>
          <li> </li>
        </ul>
      </nav>
      <div v-if="!isSignedIn && !isHandHeldDevice" id="userLogin">
          <div
          ref="login"
          v-on:click="$router.push({ name: 'sign-in' });"
          >
            <i class="fa fa-arrow-right-to-bracket"></i>
            &nbsp;&nbsp;SignIn&nbsp;
          </div>
          <div
            v-on:click="$router.push({ name: 'sign-up' })"
            >Register
          </div>
      </div>
      <div 
          v-if="isSignedIn && !isHandHeldDevice"
          class="signin__block" v-on:click="signOut()">
            Sign out&nbsp;&nbsp;&nbsp;
            <i class="fa fa-arrow-right-from-bracket"></i>
      </div>
      <!-- click event to toggle NavPanel in mobile view-->
      <a v-on:click="isVisible = true" class="navPanelToggle" href="#navPanel"
        >Menu</a
      >
    </header>
    <div id="navPanel" v-bind:class="{ visible: isVisible }">
      <!--create nav list for mobile view-->
      <div v-if="isSignedIn" class="userProfile">
        <div>
          <div><i class="fa fa-circle-user"></i></div>
          <div>{{ username }}</div>
        </div>
      </div>

      <div v-else class="userLogin">
          <a 
            style="width: 30%;line-height: normal;border-radius: 8px 0 0 8px;padding-left: 0%;"
            class="button link depth-0 nav-link"
            v-if="!isSignedIn"
            v-on:click="
              isVisible = false;
              $router.push({ name: 'sign-in' });
            "
            >
            Sign In
          </a>
          <a 
            style="width: 30%;line-height: normal;border-radius: 0 8px 8px 0;padding-left: 0%;"
            class="button link depth-0 nav-link"
            v-if="!isSignedIn"
            v-on:click="
              isVisible = false;
              $router.push({ name: 'sign-up' });
            "
            >
            Register
          </a>
      </div>

      <a v-on:click="isVisible = false" class="close" href="#navPanel"></a>
      <nav id="nav">

        <a
          class="link depth-0 nav-link"
          v-on:click="
            isVisible = false;
            $router.push({ name: 'home' });
          "
          >Home</a
        >
        <a class="link depth-0 nav-link">Services</a>
        <a
          class="link depth-1 nav-link"
          v-on:click="
            isVisible = false;
            $router.push({ name: 'amortization-calculator' });
          "
        >
          <span class="indent-1"></span>
          Amortization Calculator
        </a>
        <a
          class="link depth-1 nav-link"
          v-on:click="
            isVisible = false;
            $router.push({ name: 'upload' });
          "
        >
          <span class="indent-1"></span>
          Upload Files
        </a>
        <a
          class="link depth-1 nav-link"
          v-on:click="
            isVisible = false;
            $router.push({ name: 'new-or-continue' });
          "
        >
          <span class="indent-1"></span>
          Apply for Mortgage/Refinance
        </a>
        <a
          class="link depth-1 nav-link"
          v-on:click="
            isVisible = false;
            $router.push({ name: 'faq' });
          "
          >
          <span class="indent-1"></span>
          FAQ
        </a>
        <a
          class="link depth-0 nav-link"
          v-on:click="
            isVisible = false;
            $router.push({ name: 'contact' });
          "
          >Contact</a
        >
        <a
          class="link depth-0 nav-link"
          v-on:click="
            isVisible = false;
            $router.push({ name: 'success-stories' });
          "
          >Success Stories</a
        >
        <div class="navPanel__signout">
            <a
            class="button link depth-0" v-if="isSignedIn" v-on:click="signOut()">Sign out</a>
        </div>
      </nav>
    </div>
    <transition name="fade" tag="ul">
      <ul
        @mouseover="expandDropdown('services','servicesList',100)"
        @mouseleave="collapseDropdown('services')"
        v-show="showDropDownFor.services"
        ref="servicesList"
        class="dropotron level-0 right droplist services"
      >
        <li style="white-space: nowrap">
          <a
            class="link"
            v-on:click="$router.push({ name: 'amortization-calculator' })"
            style="display: block"
            >Amortization Calculator</a
          >
        </li>
        <li style="white-space: nowrap">
          <router-link
            style="display: block"
            class="link"
            v-on:click="
            isVisible = false;"
            :to="{ name: 'upload' }"
          >
          Upload Files
          </router-link>
        </li>
        <li style="white-space: nowrap">
          <router-link
            style="display: block"
            class="link"
            v-on:click="
            isVisible = false;"
            :to="{ name: 'new-or-continue'}"
            >Apply for Mortgage/Refinance
          </router-link>
        </li>
        <li style="white-space: nowrap">
          <a
            class="link"
            v-on:click="$router.push({ name: 'contact' })"
            style="display: block"
            >Licensing information</a
          >
        </li>
        <li style="white-space: nowrap">
          <a
            class="link"
            v-on:click="$router.push({ name: 'disclosure' })"
            style="display: block"
            >Important Disclosure</a
          >
        </li>
        <li>
          <a
            class="link"
            v-on:click="$router.push({ name: 'faq' })"
            style="display: block"
            >FAQ</a
          >
        </li>
      </ul>
    </transition>

    <transition name="fade" tag="ul">
      <ul
        @mouseover="expandDropdown('users','usersList',50)"
        @mouseleave="collapseDropdown('users')"
        v-show="showDropDownFor.users"
        ref="usersList"
        class="dropotron level-0 right droplist users"
        id="signout__button"
      >
        <li style="white-space: nowrap;text-align: center;">
          <a
            v-if="isSignedIn" v-on:click="signOut()"
            style="display: inline-block;line-height: normal;"
            >Sign out&nbsp;
            <i class="fa fa-arrow-right-from-bracket"></i>
          </a>
        </li>
      </ul>
    </transition>

    <transition name="fade" tag="ul">
      <ul
        @mouseover="expandDropdown('login','loginList',50)"
        @mouseleave="collapseDropdown('login')"
        v-show="showDropDownFor.login"
        ref="loginList"
        class="dropotron level-0 right droplist login"
        id="signout__button"
      >
        <li style="white-space: nowrap;">
          <a
            class="link depth-0"
            v-on:click="$router.push({ name: 'sign-up' })"
            style="display: inline-block;line-height: normal;"
            >New Customer? Sign Up
          </a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>


import authenticationMixin from "@/mixins/authenticationMixin.js";
import { store } from "./../../store/store";

export default {
  name: "AppHeader",
  data() {
    return {
      isVisible: false,
      username: "user",
      isHandHeldDevice: false,
      showDropDownFor: {
        users: false,
        services: false,
        login: false,
      },
      images: [require("@/assets/images/logo-website.jpeg")],
    };
  },
  mixins: [authenticationMixin],
  watch: {
    isSignedIn(status) {
      if(status && this.$store.getters.signedIn) {
        this.username = store.getters.loggedInUser;
      } else {
          this.username = "user";
      }
    }
  },
  mounted() {
    //get loggedIn user
    if(this.$store.getters.signedIn) {
      this.username = this.$store.state.loggedInUser;
    } else {
      this.username = "user";
    }
    //get window dimensions
    this.isHandHeldDevice=(window.innerWidth / window.innerHeight < 1 ? true: false);
    if(window.innerWidth >= 1000 && (window.innerWidth / window.innerHeight < 1)) this.isHandHeldDevice = false;
  },
  methods: {
    expandDropdown: function(menuType,menuList,offset) {
      this.$refs[menuList].style.left =
        this.$refs[menuType].offsetLeft - offset + "px";
      this.showDropDownFor[menuType] = true;
    },
    collapseDropdown: function(menuType) {
      this.showDropDownFor[menuType] = false;
    },
    signOut: async function() {
      // hide menu for mobile view
      this.isVisible = false;

      // set flag for moon-loader
      this.isLoading = true;
      if(window.localStorage.getItem('authData')){
        let auth_data = JSON.parse(window.localStorage.getItem('authData'))
        let user_uuid = auth_data.user_uuid
        await this.signOutAndRevokeTokens(user_uuid);
      }
      // reset last route to home
      await this.$store.dispatch("SAVE_LAST_ROUTE", "home");
      // dipatch action 'AUTH_REQUEST'
      this.$store
        .dispatch("SIGN_OUT")
        .then(() => {
          // set flag for moon-loader
          this.isLoading = false;
          // push to signin page
          if (this.$route.name === 'home') {
            window.location.reload();
          } else {
            this.$router.push({ name: "home" });
          }
        })
        .catch(() => {
          // set flag for moon-loader
          this.isLoading = false;          
          if (this.$route.name === 'home') {
            window.location.reload();
          } else {
            this.$router.push({ name: "home" });
          }
        });
    },
  },
  computed: {
    isSignedIn: function() {
      return authenticationMixin.methods.isLoggedIn()
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}
#header > nav > ul > li {
  padding: 0 1em;
  margin: 0 0.5em;
}

#header > nav > ul > li a {
  padding: 0;
  margin: 0;
}

/* website logo image */
.image-logo {
  width: 40px;
  height: 40px;
  display: inline-block;
}

.image-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav-link {
  cursor: pointer;
}

.link {
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#signout__button > li > a {
  font-weight: bold; 
  opacity: 0.9;
}
#signout__button > li > a:hover{
  background: transparent;
  color: #2ebfac !important;
  cursor: pointer;
}

.signin__block {
  width: fit-content;
  text-wrap: nowrap;
  min-width: 110px;
  display: flex;
  padding: 4px 20px;
  margin: 0%;
  justify-content: center;
  align-items: center;
  font-size: inherit;
  caret-color: transparent;
  flex-wrap: nowrap;
  border: 1px solid #f8f8f8;
  border-radius: 5px;
  cursor: pointer;
}

.signin__block:active {
  text-shadow: #f8f8f8 1px 0 1px;
}

@media screen and (max-width: 1440px) {
  .signin__block {
    width: 10%;
  }
}

.signin__block > div {
  width: fit-content;
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 170px;
  cursor: pointer;
}
.loggedInUser {
  display: inline-block;
  max-width: 150px;
  font-weight: bold;
  padding: 0%;margin: 0%;
  white-space: nowrap;overflow: hidden;
  text-overflow: ellipsis;
}

#nav {
  padding-top: 0%; 
}

#userLogin {
  margin: 0%;
  padding: 0%;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  min-width: 150px;
  width: 15%;
  border: 1px solid #f8f8f8;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#userLogin  div {
  /*border-radius: 10px;*/
  float: left;
  font-size: inherit;
  font-weight: inherit;
  padding: 4px 0px;
  width: 50%;
  margin: 0%;
  text-align: center;
  cursor: pointer;
  caret-color: transparent;
}

#userLogin div:nth-child(2) {
  color: #2ebfac;
  background-color: #f8f8f8;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

#userLogin div:nth-child(1):active {
  text-shadow: #f8f8f8 1px 0 1px;
}

#userLogin div:nth-child(2):active {
  text-shadow: #2ebfac 1px 0 1px;
}

#navPanel .userProfile {
  height: 80px;
  border-bottom: 1px dashed rgba(255,255,255,0.15);
  padding-top:2em ;
}

#navPanel .userProfile > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size:x-large;
  padding-left: 2em;
  gap:10px;
}

#navPanel .userLogin {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 3em 0 2em 0;
  caret-color: transparent;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.navPanel__signout {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-top: 15px;
  caret-color: transparent;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.navPanel__signout a {
  width: 70%;
  line-height: normal;
  border-radius: 8px;
  padding-left: 0%;
}

.droplist {
  -moz-user-select: none;
  position: fixed;
  z-index: 1000;
  top: 76.8667px;
  opacity: 1;
}

.droplist .services {
  height: 16.5em;
}

.droplist .users {
  height: 2.5em;
  text-align: center;
  padding-top:0% ;
  padding-bottom: 0%;
  caret-color: transparent;
}

.droplist .login {
  height: 2.75em;
  text-align: center;
  padding-top:0% ;
  padding-bottom: 0%;
  caret-color: transparent;
}

</style>
