<template>
  <!-- main -->
  <section class="main">
    <!-- Content -->
    <section class="wizard">
      <!-- Moon Loader -->
      <moon-loader
        v-bind:loading="isLoading"
        :color="loaderColor"
        :size="loaderSize"
        v-bind:loaderMessage="loaderMessage"
      ></moon-loader>

      <form-wizard ref="urlaWizard" :steps="steps">
        <section-1
          v-bind:sourceSection1="urla.section1BorrowerInformation"
          slot="section1"
        ></section-1>
        <!-- <section-2
          v-bind:sourceSection2="urla.section2AssetsAndLiabilities"
          slot="section2"
        ></section-2> -->
        <section-3
          v-bind:sourceSection3="urla.section3RealEstate"
          slot="section3"
        ></section-3>
        <section-4
          v-bind:sourceSection4="urla.section4LoanAndPropertyInformation"
          slot="section4"
        ></section-4>
        <section-5
          v-bind:sourceSection5="urla.section5Declarations"
          slot="section5"
        ></section-5>
        <!--section-6 slot="section6"></section-6-->
        <section-7
          v-bind:sourceSection7="urla.section7DemographicInformation"
          slot="section7"
        ></section-7>
        <section-8
          v-bind:sourceSection8="urla.section8LoanOriginatorInformation"
          slot="section8"
        ></section-8>
        <disclaimer
          v-bind:sourceSection8="urla.section8LoanOriginatorInformation"
          slot="disclaimer"
        ></disclaimer>
      </form-wizard>
    </section>
  </section>
</template>

<script>
import { bus } from "@/main";
import VueGoodWizard from "@/components/elements/Wizard.vue";
import validationsMixin from "@/mixins/validationsMixin.js";
import inputMasksMixin from "@/mixins/inputMasksMixin.js";
import urlaMixin from "@/mixins/urlaMixin.js";
import MoonLoader from "@/components/elements/MoonLoader.vue";
import Section1 from "./Sections/Section1.vue";
// import Section2 from "./Sections/Section2.vue";
import Section3 from "./Sections/Section3.vue";
import Section4 from "./Sections/Section4.vue";
import Section5 from "./Sections/Section5.vue";
import Section7 from "./Sections/Section7.vue";
// import Section6 from "./Sections/Section6.vue";
import Section8 from "./Sections/Section8.vue";
import Disclaimer from "./Sections/Disclaimer.vue";

export default {
  name: "Urla",
  // props can be set from parent component
  props: ["customerId", "loanId", "sourceUrla"],
  // mixins used in this component
  mixins: [validationsMixin, urlaMixin, inputMasksMixin],
  // components used
  components: {
    "form-wizard": VueGoodWizard,
    "moon-loader": MoonLoader,
    "section-1": Section1,
    // "section-2": Section2,
    "section-3": Section3,
    "section-4": Section4,
    "section-5": Section5,
    "section-7": Section7,
    //"section-6": Section6,
    "section-8": Section8,
    "disclaimer": Disclaimer
  },
  data() {
    return {
      loaderSize: "100px",
      loaderColor: "#2ebfac",
      loaderMessage: "Saving",
      isLoading: false,
      urla: null,
      steps: [
        {
          label: "Step 1",
          slot: "section1",
        },
        // {
        //   label: "Step 2",
        //   slot: "section2",
        // },
        {
          label: "Step 2",
          // exchanged section 3 & 4
          slot: "section4",
        },
        {
          label: "Step 3",
          // exchanged section 3 & 4
          slot: "section3",
        },
        {
          label: "Step 4",
          slot: "section5",
        },
        {
          label: "Step 5",
          slot: "section7",
        },
        {
          label: "Step 6",
          slot: "section8",
        },
        {
          label: "Step 7",
          slot: "disclaimer",
          options: {
            nextDisabled: true, // control whether next is disabled or not
          },
        },
      ],
    };
  },

  methods: {
    pageReloadAlert: function(event) {
      // confirm to reload
      var reloadPage = window.confirm("");

      // reload if user selects to reload
      if (reloadPage) this.$router.push({ name: "urla" });
      else event.preventDefault();

      // Chrome requires returnValue to be set
      event.returnValue = "";
    },

    goToCurrentSection: function(message) {
      var currentStep;
      // route to urla submitted page if loanApplicationProgress >= 800
      if (this.urla.loanApplicationProgress >= 800) {
        this.$router.push({
          name: "urla-submitted",
          params: {
            message,
            loanPurpose: this.urla.section4LoanAndPropertyInformation
              .part4aLoanInformation.loanPurpose,
          },
        });

        // return from method
        return;
      }

      if (this.urla.loanApplicationProgress === 300) currentStep = 2;
      else if (this.urla.loanApplicationProgress === 400) currentStep = 3;
      else if (this.urla.loanApplicationProgress === 500) currentStep = 4;
      else if (this.urla.loanApplicationProgress === 700) currentStep = 5;
      else currentStep = this.urla.loanApplicationProgress / 100;

      // navigate to current step
      this.$refs.urlaWizard.goTo(currentStep);
    },

    async postUrlaSection(section) {
      const ERROR_MESSAGE = "Unexpected Error";
      // set moon-loader
      this.isLoading = true;

      // parse data from section
      this.urla[section.sectionName] = section;

      // set loanApplicationProgress
      this.urla.loanApplicationProgress = section.loanApplicationProgress;

      // set updatedByApp as FRONTEND
      this.urla.updatedByApp = "FRONTEND";

      // post URLA using REST API
      let response = await this.callUrlasAPIAndUpdateUrla(
        this.customerId,
        this.loanId,
        this.urla
      ).catch(() => {
        this.isLoading = false;
        this.$router.push({ name: "error", params: { data: ERROR_MESSAGE } });
      });
      this.isLoading = false;
      if (response.status !== 200 || response.data === undefined) {
        this.$router.push({
          name: "error",
          params: { message: ERROR_MESSAGE },
        });
        return;
      }
      this.urla = response.data;
      // go to next page
      this.goToCurrentSection(response.data);
    },

    setUpEventListeners: function() {
      // 1. Event listener to submit current section
      bus.$on("postUrla", (section) => {
        // clone section
        const sectionCopy = JSON.parse(JSON.stringify(section));

        // post urla section
        this.postUrlaSection(sectionCopy);
      });

      // 2. Event listener to go back to previous section
      bus.$on("goBack", (currentPage) => {
        // navigate to Previous page
        this.$refs.urlaWizard.goBack(currentPage);
      });

      // 3. Event listener for reload
      // window.addEventListener("beforeunload", (event) =>
      //   this.pageReloadAlert(event)
      // );
      
      // 4. Event listener to go to next section
      bus.$on("nextPage", (section) => {
        // navigate to next step
        const sectionCopy = JSON.parse(JSON.stringify(section));
        this.urla.section8LoanOriginatorInformation = sectionCopy;
        this.$refs.urlaWizard.goNext(section.currentPage);
      });
    },
  },

  mounted: function() {
    // go to current section
    this.goToCurrentSection();

    // set up event listeners for bus
    this.setUpEventListeners();
  },

  created: function() {
    // store a copy of urla received as prop
    this.urla = JSON.parse(JSON.stringify(this.sourceUrla));
  },

  beforeDestroy: function() {
    // remove event listeners
    // 1. Event listener to submit current section
    bus.$off("postUrla");
    // 2. Event listener to go back to previous section
    bus.$off("goBack");
    // 3. Event listener for reload
    // window.removeEventListener("beforeunload", (event) =>
    //   this.pageReloadAlert(event)
    // );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/* styles for wizard */
.wizard {
  padding-top: 0 !important;
}

.center-align-text {
  text-align: center;
}

/* styles for section description */
.urla-section-description {
  margin: auto;
  margin-bottom: 1rem;
  font-size: 18px;
}

/* styles for form inside wizard section */
.urla-section form {
  margin: auto 2rem;
}

/* style for Required Asterisk */
.urla-section .instruction {
  margin: auto 2rem;
}

/* border style for left section on mobile devices */
@media screen and (max-width: 800px) {
  .urla-left-section {
    border-right: none !important;
  }
}

@media screen and (max-width: 480px) {
  .urla-left-section {
    border-right: none !important;
  }

  .urla-middle-section {
    border-right: none !important;
    border-left: none !important;
  }

  .urla-section form {
    margin: 0;
  }

  /* style for Required Asterisk */
  .urla-section .instruction {
    margin: 0;
  }
}

@media screen and (max-width: 360px) {
  .urla-left-section {
    border-right: none !important;
  }

  .urla-middle-section {
    border-right: none !important;
    border-left: none !important;
  }

  .urla-section form {
    margin: 0;
  }

  /* style for Required Asterisk */
  .urla-section .instruction {
    margin: 0;
  }
}

.urla-section .required,
.urla-section .instruction,
.urla-section .error {
  text-decoration: none;
  /*color: #bf4d2e;*/
  color: #ff5049;
  padding: 0;
}

/* font style for h5 heading */
.urla-section h5 {
  font-size: 16px;
  font-weight: bold;
}

/* font styles for form elements */
.urla-section form p,
.urla-section form legend,
.urla-section form label {
  font: inherit;
  font-size: 16px;
  font-style: normal;
  text-transform: none;
  margin: 0;
  padding-bottom: 1.25%;
  color: #464646;
}

/* border style for left section of form */
.urla-left-section {
  border-right: dashed #2ebfac 1px;
  margin-bottom: 0;
}

.urla-right-section {
  margin-bottom: 0;
}

.urla-middle-section {
  border-right: dashed #2ebfac 1px;
  border-left: dashed #2ebfac 1px;
}

/* font styles for form elements */
.urla-section form label {
  font-weight: bold;
}

input[type="radio"] + label::before {
  height: 1.25em;
  width: 1.25em;
  line-height: 1.2em;
}

/* style for horizontal break */
.urla-break {
  margin: 0.75rem auto;
}

/* styles for extra small labels and inputs */
.x-small-label {
  width: 75px !important;
  margin-right: 2%;
  box-sizing: border-box;
}

.x-small-input {
  width: 60px !important;
  /* margin-right: 2%;*/
  box-sizing: border-box;
}

/* styles for small labels and inputs */
.small-label {
  width: 125px !important;
  margin-right: 2%;
  box-sizing: border-box;
}

.small-input {
  width: 125px !important;
  box-sizing: border-box;
}

/* styles for medium labels and inputs */
.medium-label {
  width: 200px !important;
  margin-right: 2%;
  box-sizing: border-box;
}

.medium-input {
  width: 200px !important;
  box-sizing: border-box;
}

/* styles for large labels and inputs */
.large-label {
  width: 400px !important;
  margin-right: 2%;
  box-sizing: border-box;
}

.large-input {
  width: 400px !important;
  box-sizing: border-box;
}

/* style for input and labels */
.urla-section input,
.urla-section label,
.urla-section select,
.urla-section .empty-label {
  display: inline-block;
  width: 250px;
  box-sizing: border-box;
}

/* style for long input and labels (appx. 1/3 of width)*/
.urla-section input.long,
.urla-section label.long,
.urla-section select.long,
.urla-section .empty-label.long {
  width: 300px !important;
}

/* style for input and labels (appx. 1/2 of width)*/
.urla-section input.extra-long,
.urla-section label.extra-long,
.urla-section select.extra-long,
.urla-section .empty-label.extra-long {
  width: 500px !important;
}

/* hide arrows for input[type=number] */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

/* hidden label */
.hidden-label {
  display: none !important;
}

/* bold font */
.font-weight-bold {
  font-weight: bold !important;
}

/* normal font */
.font-weight-normal {
  font-weight: normal !important;
}

/* italic font */
.font-style-italic {
  font-style: italic !important;
}

/* width for list elements in radio */
.urla-section input[type="radio"] {
  width: auto;
}

.urla-radio-label {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  /* line-height: 2.75rem !important; */
}

/* style for inline radio buttons*/
.radio-inline li,
.checkbox-inline li {
  display: inline;
}

.checkbox-inline ul {
  padding-left: 0;
}

/* width for list elements in checkbox */
.urla-section input[type="checkbox"] {
  width: auto;
}

/* style for list elements in radio/select/checkbox */
.urla-section fieldset ul {
  margin: 0;
}

.urla-section fieldset li {
  /* display: inline; */
  list-style-type: none;
  padding: 0;
}

/* hide arrows for date in chrome browser */
input[type="date"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

/* Enter/Leave & List Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: height 0.5s ease, opacity 0.3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  height: 0px;
}

/* v-for transitions for list of otherBorrowers */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease, height 0.5s ease, opacity 0.3s ease;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  /* transform: translateY(30px); */
}
.inline-list {
  display: inline;
}

/* Styles for back and next buttons */
.clearfix::after {
  display: block;
  content: "";
  clear: both;
}
.clearfix {
  font-size: 16px;
}

/* style for box in transition-group list */
.urla-section .box {
  margin-bottom: 1rem;
}

/* styles for table */
.input-table {
  margin-bottom: 1rem;
}

.row .input-table {
  margin: auto 2rem;
}

/* style for rows in table */
.input-table tr {
  background: none !important;
}

.input-table tr:last-child {
  border-bottom: none;
}

.table-heading th {
  font-size: 16px;
}

.medium-column {
  width: 270px;
}

.small-column {
  width: 230px;
}

.x-small-column {
  width: 70px;
  padding: 0;
}

.remove-button,
.inner-remove-button {
  /* float: right; */
  background: none;
  border: none !important;
  padding: 0;
  cursor: pointer;
  /*display: none;*/
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  height: 22px;
  color: #ff5049 !important;
}

.remove-button:hover,
.inner-remove-button:hover {
  color: #f9020e !important;
}

.remove-button:focus,
.inner-remove-button:hover {
  outline: none;
}

.add-button {
  font-size: 14px;
  color: #2ebfac !important;
}

.add-button:active,
.remove-button:active {
  transform: translateY(2px);
}

.left-indent {
  padding-left: 2rem;
}

/* styles for vue-date-pick*/
.date-pick .vdpInnerWrap table {
  margin: 0;
  border-collapse: unset;
}

.date-pick table tbody tr {
  background-color: inherit;
}

.vdpPeriodControl > button {
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
}

button.vdpClearInput {
  border: none !important;
}

.vdpClearInput::before {
  color: grey;
  background-color: #f7f7f7;
  margin: 0 0 0 -0.5rem;
  border: 1px solid grey;
}

.vdpHeader button,
.vdpPeriodControl button {
  border: none !important;
}

.date-pick .vdpCell.today {
  color: #2ebfac;
}

.date-pick .vdpArrowPrev:after {
  border-right-color: #2ebfac;
}

.date-pick .vdpArrowNext:after {
  border-left-color: #2ebfac;
}

.date-pick .vdpCell.selectable:hover .vdpCellContent,
.vdpCell.selected .vdpCellContent {
  background: #2ebfac;
}

.urla-section .date-pick.medium-input input {
  width: 200px !important;
  box-sizing: border-box;
}

.urla-section .date-pick.small-input input {
  width: 125px !important;
  box-sizing: border-box;
  padding-right: 25px;
}
</style>
