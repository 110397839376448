import Home from "./components/pages/Home.vue";
import NewOrContinue from "./components/pages/UrlaPage/NewOrContinue.vue";
import Urla from "./components/pages/UrlaPage/Urla.vue";
import UrlaSubmitted from "./components/pages/UrlaPage/UrlaSubmitted.vue";
import PendingApplications from "./components/pages/UrlaPage/PendingApplications.vue";
import FAQ from "./components/pages/FAQ.vue";
import Contact from "./components/pages/Contact.vue";
import About from "./components/pages/About.vue";
import Upload from "./components/pages/Upload.vue";
import ViewReviews from "./components/pages/ReviewsPage/ViewReviews.vue";
import CreateReview from "./components/pages/ReviewsPage/CreateReviews.vue";
import SuccessStories from "./components/pages/OtherPages/successStories.vue";
import PopularLoanPrograms from "./components/pages/OtherPages/PopularLoanPrograms.vue";
import StepsInMortgageProcess from "./components/pages/OtherPages/StepsInMortgageProcess.vue";
import DosAndDonts from "./components/pages/OtherPages/DosAndDonts.vue";
import Disclosure from "./components/pages/OtherPages/Disclosure.vue";
import Resources from "./components/pages/OtherPages/Resources.vue";
import DocsForProcessing from "./components/pages/OtherPages/DocsForProcessing.vue";
import MortgageDefinitions from "./components/pages/OtherPages/MortgageDefinitions.vue";
import LicensingInfo from "./components/pages/OtherPages/LicensingInfo.vue";
import Quote from "./components/pages/GetQuotePage/Quote.vue";
import QuoteSuccess from "./components/pages/GetQuotePage/QuoteSuccess.vue";
import Error from "./components/pages/Error.vue";
import SignIn from "./components/pages/Authentication/SignIn.vue";
import SignUp from "./components/pages/Authentication/SignUp.vue";

import authenticationMixin from "./mixins/authenticationMixin";

// vuex store
import { store } from "./store/store";

const noAuthReqPages = [
  "error",
  "faq",
  "contact",
  "about",
  "sign-in",
  "home"
];


// navigation guard for routes
async function requireAuth(to, from, next) {
  // these pages do not require authentication
  if (noAuthReqPages.includes(to.name)) return next();
  else if (await authenticationMixin.methods.handleTokenValidationAndRenewal()) {
    return next();
  } else {
    //store the previous page route if redirected to auth pages
    if(from.name != 'sign-up' && from.name != 'sign-in') {
      //don't store if previous route are auth pages
      await store.dispatch("SAVE_LAST_ROUTE", to.name);
    }
    return next({ name: "sign-in" });
  }
}

// function isAdmin(to, from, next) {
//   // Check if the user roles has a role 'ROLE_ADMIN'
//   let isUserAnAdmin = store.getters.getUserRoles.includes('ROLE_ADMIN')
//   isUserAnAdmin ? next() : next('/error')
// }


// Define routes
export default [
  {
    path: "/signin",
    name: "sign-in",
    component: SignIn,
  },
  {
    path: "/signup",
    name: "sign-up",
    component: SignUp,
  },
  {
    path: "/",
    name: "home",
    component: Home,
    beforeEnter: requireAuth,
  },
  {
    path: "/newOrContinueApplication",
    name: "new-or-continue",
    component: NewOrContinue,
    beforeEnter: requireAuth,
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ,
  },
  {
    path: "/SuccessStories",
    name: "success-stories",
    component: SuccessStories,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/licensing-info",
    name: "LicensingInfo",
    component: LicensingInfo,
  },
  {
    path: "/upload",
    name: "upload",
    component: Upload,
    beforeEnter: requireAuth,
  },
  {
    path: "/reviews",
    name: "view-reviews",
    component: ViewReviews,
  },
  {
    path: "/CreateReview",
    name: "create-review",
    component: CreateReview,
    beforeEnter: requireAuth,
  },
  {
    path: "/PolularLoanPrograms",
    name: "popular-loan-programs",
    component: PopularLoanPrograms,
    beforeEnter: requireAuth,
  },
  {
    path: "/StepsInMortgageProcess",
    name: "steps-in-mortgage-process",
    component: StepsInMortgageProcess,
    beforeEnter: requireAuth,
  },
  {
    path: "/DosAndDonts",
    name: "dos-and-donts",
    component: DosAndDonts,
  },
  {
    path: "/disclosure",
    name: "disclosure",
    component: Disclosure,
  },
  {
    path: "/resources",
    name: "resources",
    component: Resources,
    beforeEnter: requireAuth,
  },
  {
    path: "/DocsForProcessing",
    name: "docs-for-processing",
    component: DocsForProcessing,
    beforeEnter: requireAuth,
  },
  {
    path: "/MortgageDefinitions",
    name: "mortgage-definitions",
    component: MortgageDefinitions,
    beforeEnter: requireAuth,
  },
  {
    path: "/AmortizationCalculator",
    name: "amortization-calculator",
    component: () => import("./components/pages/AmortizationCalculator.vue"),
  },
  {
    path: "/quote",
    name: "quote",
    component: Quote,
    beforeEnter: requireAuth,
  },
  {
    path: "/quoteRequested",
    name: "quote-success",
    props: true,
    component: QuoteSuccess,
    beforeEnter: requireAuth,
  },
  {
    path: "/urla",
    name: "urla",
    props: true,
    component: Urla,
    beforeEnter: requireAuth,
  },
  {
    path: "/urlaSubmitted",
    name: "urla-submitted",
    props: true,
    component: UrlaSubmitted,
    beforeEnter: requireAuth,
  },
  {
    path: "/pendingApplications",
    name: "pending-applications",
    props: true,
    component: PendingApplications,
    beforeEnter: requireAuth,
  },
  {
    path: "/error",
    name: "error",
    component: Error,
  },
  {
    path: "*",
    redirect: {
      name: "home",
    },
  },
];
