import inputMasksMixin from "@/mixins/inputMasksMixin.js";

export default {
  mixins: [inputMasksMixin],
  data() {
    return {
      // regular expressions for validation
      regExp: {
        // eslint-disable-next-line
        firstName: /^[a-zA-Z\'\,\.\- ]+$/,
        // eslint-disable-next-line
        lastName: /^[a-zA-Z\'\,\.\- ]+$/,
        // eslint-disable-next-line
        middleName: /^[a-zA-Z\'\,\.\- ]+$/,
        // eslint-disable-next-line
        suffix: /^[a-zA-Z\'\,\.\- ]+$/,
        fullName: /^[\p{L} .'-]+$/,
        // eslint-disable-next-line
        email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        texSmartEmail: /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(texsmartlending)\.com$/,
        lastFourDigitsOfSsn: /^[\d]{4}$/,
        ssn: /^\d{3}-?\d{2}-?\d{4}$/,
        date: /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/,
        phoneNumberExtension: /^([0-9]){1,7}$/,
        integer: /^\d+$/,
        // eslint-disable-next-line
        street: /^[ \w\d,\.;\-\'&\/#@*\(\)]+$/,
        // eslint-disable-next-line
        unit: /^[\w-#&*\., \\\/]+$/,
        city: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
        state: /^[a-zA-Z]+[ ]?[a-zA-Z]*$/,
        zip: /^[0-9]{5}$/,
        county: /^.+$/,
        // eslint-disable-next-line
        country: /^[ a-zA-Z\.,-]+$/,
        organizationName: /^.+$/,
        jobTitle: /^.+$/,
        accountNumber: /^\w{1,17}$/,
        password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,32}$/,
      },
    };
  },
  methods: {
    // validates first name
    checkFirstName: function(event) {
      var inputValue = event.target.value;
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.firstName)) {
        // create error message
        let errorMessage = "please enter a valid First Name";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }

      // return errors found in this field
      return fieldErrors;
    },

    // validates last name
    checkLastName: function(event) {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.lastName)) {
        // create error message
        let errorMessage = "Please enter a valid Last Name";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    // validates middle name
    checkMiddleName: function(event) {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.middleName)) {
        // create error message
        let errorMessage = "please enter a valid Middle Name";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    // validates suffix
    checkSuffix: function(event) {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.suffix)) {
        // create error message
        let errorMessage = "please enter a valid Suffix";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    // validates full name
    checkFullName: function(event) {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.fullName)) {
        // create error message
        let errorMessage = "please enter a valid name";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    checkEmail: function(event) {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.email)) {
        // create error message
        let errorMessage = "please enter a valid email address";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    checkTexSmartEmail: function(event) {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.texSmartEmail)) {
        // create error message
        let errorMessage =
          "please enter a valid americansmartlending.com email address";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    checkPhoneNumber: function(event) {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];
      // unmask phone number
      var unmaskedPhoneNumber = this.unmaskPhoneNumber(inputValue);

      if (
        unmaskedPhoneNumber < 1000000000 ||
        unmaskedPhoneNumber > 9999999999
      ) {
        // create error message
        let errorMessage = "please enter a valid phone number";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    checkPhoneNumberExtension: function(event) {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.phoneNumberExtension)) {
        // create error message
        let errorMessage = "please enter a valid extension number";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    checkPrice: function(event) {
      var fieldErrors = [];
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field

      if (inputValue === null) {
        // 1. check if purchasePrice is null
        // create error message
        let errorMessage = "amount cannot be empty";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else if (this.unmaskCurrency(inputValue) <= 0) {
        // 2. check if purchase price is 0
        // create error message
        let errorMessage = "amount cannot be less than 0";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }

      return fieldErrors;
    },

    checkPriceAllowZero: function(event) {
      var fieldErrors = [];
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field

      if (inputValue === null) {
        // 1. check if purchasePrice is null
        // create error message
        let errorMessage = "amount cannot be empty";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }

      return fieldErrors;
    },

    isDateGreaterThan: function(left, right) {
      // split date
      const leftArr = left.split("-");
      const rightArr = right.split("-");

      // compare and return true or false
      return (
        new Date(leftArr[0], leftArr[1] - 1, leftArr[2]) >=
        new Date(rightArr[0], rightArr[1] - 1, rightArr[2])
      );
    },

    compareDate: function(left, right) {
      const leftArr = left.split("-");
      const rightArr = right.split("-");

      const leftDate = new Date(leftArr[0], leftArr[1] - 1, leftArr[2]);
      const rightDate = new Date(rightArr[0], rightArr[1] - 1, rightArr[2]);

      // check greater than
      if (leftDate > rightDate) return 1;

      // check less than
      if (leftDate < rightDate) return -1;

      // check equal
      if (
        leftDate.getFullYear() === rightDate.getFullYear() &&
        leftDate.getMonth() === rightDate.getMonth() &&
        leftDate.getDate() == rightDate.getDate()
      )
        return 0;
    },

    // validates date
    checkDate: function(event) {
      var inputValue = event.target.value;
      var fieldErrors = [];

      // 1. extract year, month, day from input
      // 2. check if extracted values are numbers
      if (!inputValue.match(this.regExp.date)) {
        // create error message
        let errorMessage = "please enter a valid date";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }

      // return errors found in this field
      return fieldErrors;
    },

    checkDateRange: function(event, min, max) {
      var inputValue = event.target.value;
      var fieldErrors = [];

      // 1. extract year, month, day from input
      // 2. check if extracted values are numbers
      if (!inputValue.match(this.regExp.date)) {
        // create error message
        let errorMessage = "please enter a valid date";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else if (
        this.compareDate(min, inputValue) === 1 ||
        this.compareDate(max, inputValue) === -1
      ) {
        // create error message
        // let errorMessage = "please enter a date between " + min + " and " + max;
        let errorMessage = "please enter a valid date";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }

      // return errors found in this field
      return fieldErrors;
    },

    checkDateRangeFromValue: function(inputValue, min, max) {
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.date)) {
        // create error message
        let errorMessage = "please enter a valid date";
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else if (
        this.compareDate(min, inputValue) === 1 ||
        this.compareDate(max, inputValue) === -1
      ) {
        // create error message
        // let errorMessage = "please enter a date between " + min + " and " + max;
        let errorMessage = "please enter a valid date";
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      }

      // return errors found in this field
      return fieldErrors;
    },

    // validates lastFourDigitsOfSsn
    checkLastFourDigitsOfSsn: function(event) {
      var inputValue = event.target.value;
      var fieldErrors = [];

      // 1. check if input string is a number
      // 2. check if input is string is
      if (!inputValue.match(this.regExp.lastFourDigitsOfSsn)) {
        // create error message
        let errorMessage = "please enter valid digits";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }

      // return errors found in this field
      return fieldErrors;
    },

    // validates social security number
    checkSocialSecurityNumber: function(event) {
      var inputValue = event.target.value;
      var fieldErrors = [];

      // 1. check if input string is a number
      // 2. check if input is string is
      if (!inputValue.match(this.regExp.ssn)) {
        // create error message
        let errorMessage = "please enter valid Social Security Number";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }

      // return errors found in this field
      return fieldErrors;
    },

    // validates a number
    checkNumber: function(event) {
      var inputValue = event.target.value;
      var fieldErrors = [];

      // 1. check if input string is a number
      if (!inputValue.match(this.regExp.integer)) {
        // create error message
        let errorMessage = "please enter a valid Number";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }

      // return errors found in this field
      return fieldErrors;
    },

    checkStreet: function() {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.street)) {
        // create error message
        let errorMessage = "please enter a valid address";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    checkUnit: function() {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.unit)) {
        // create error message
        let errorMessage = "please enter a valid unit number";
        // set custom message
        /*event.target.setCustomValidity(errorMessage);
                // display the message
                event.target.reportValidity();
                // push message to fieldErrors array*/
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    checkCity: function() {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.city)) {
        // create error message
        let errorMessage = "please enter a valid city";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    checkState: function() {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.state)) {
        // create error message
        let errorMessage = "please enter a valid state";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    checkZip: function() {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.zip)) {
        // create error message
        let errorMessage = "please enter a valid zip code";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },
    setZipErorAfterApiResponse: function(event) {
      // array to store errors in this field
      var fieldErrors = [];
     // create error message
     let errorMessage = "No data found. Please enter a valid zip code";
     // set custom message
     event.target.setCustomValidity(errorMessage);
     // display the message
     event.target.reportValidity();
     fieldErrors.push(errorMessage);
      // return errors found in this field
      return fieldErrors;
    },

    checkCounty: function() {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.county)) {
        // create error message
        let errorMessage = "please enter a valid county name";
        // set custom message
        /*event.target.setCustomValidity(errorMessage);
                // display the message
                event.target.reportValidity();*/
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    checkCountry: function() {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.country)) {
        // create error message
        let errorMessage = "please enter a valid country";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    checkOrganizationName: function() {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.organizationName)) {
        // create error message
        let errorMessage = "please enter a valid Organization Name";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    checkJobTitle: function() {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.jobTitle)) {
        // create error message
        let errorMessage = "please enter a valid Job Title";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    checkAccountNumber: function() {
      // extract input value
      var inputValue = event.target.value;
      // array to store errors in this field
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.accountNumber)) {
        // create error message
        let errorMessage = "please enter a valid Account Number";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },

    // validates password
    checkPassword: function(event) {
      var inputValue = event.target.value;
      var fieldErrors = [];

      if (!inputValue.match(this.regExp.password)) {
        // create error message
        let errorMessage = "please enter a valid Password";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }

      // return errors found in this field
      return fieldErrors;
    },

    checkInterestRate: function() {
      var inputValue = event.target.value;
      var fieldErrors = [];

      if (
        inputValue === null ||
        inputValue === "" ||
        inputValue === "0" ||
        isNaN(inputValue) ||
        inputValue < 1 ||
        inputValue > 10
      ) {
        // create error message
        let errorMessage = "please enter a valid interest rate";
        // set custom message
        event.target.setCustomValidity(errorMessage);
        // display the message
        event.target.reportValidity();
        // push message to fieldErrors array
        fieldErrors.push(errorMessage);
      } else {
        // set custom message
        event.target.setCustomValidity("");
      }
      // return errors found in this field
      return fieldErrors;
    },
  },
};
