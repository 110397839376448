<template>
  <div ref="sectionThree" class="urla-section">
    <h2>Real Estate</h2>
    <!-- Section description -->
    <p class="urla-section-description">
      This section asks you to list all properties you currently own and what
      you owe on them.
    </p>

    <!-- Required fields instruction -->
    <p class="instruction">
      Required fields are followed by
      <strong> <abbr class="required" title="required">*</abbr> </strong>.
    </p>

    <!-- Form for section 3 of URL Application -->
    <form
      id="section-3-form"
      v-on:submit.prevent="submitSectionThree"
      method="post"
    >
      <div style="min-height: 175px">
        <!-- Do you own any other Real Estate? -->
        <div>
          <p style="display: inline">
            <label for="own-real-estate">
              <span class="font-weight-normal"
                >Do you own any other Real Estate?</span
              >
              <strong>
                <abbr class="required" title="required">*</abbr>
              </strong>
            </label>
          </p>
          <fieldset class="urla-radio radio-inline" style="display: inline">
            <ul>
              <li>
                <input
                  name="own-real-estate"
                  type="radio"
                  id="own-real-estate-no"
                  v-bind:value="false"
                  v-model="section3RealEstate.ownRealEstate"
                  v-on:change="skipSection"
                  required
                />
                <label
                  class="font-weight-normal small-label"
                  for="own-real-estate-no"
                  >No</label
                >
              </li>
              <li>
                <input
                  name="own-real-estate"
                  type="radio"
                  id="own-real-estate-yes"
                  v-bind:value="true"
                  v-model="section3RealEstate.ownRealEstate"
                  v-on:change="skipSection"
                />
                <label
                  class="font-weight-normal small-label"
                  for="own-real-estate-yes"
                  >Yes</label
                >
              </li>
            </ul>
          </fieldset>
        </div>

        <transition-group name="list" tag="div">
          <div
            class="list-item-transition box item-action-wrapper"
            v-for="(property, index) in section3RealEstate.propertiesOwned"
            v-bind:key="'property-' + index"
          >
            <div class="row">
              <!-- Property Value -->
              <p class="col-4 col-12-xsmall">
                <label
                  v-bind:for="'property-value-' + index"
                  class="small-label"
                >
                  <span>Property Value:</span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
                <input
                  class="small-input"
                  v-bind:id="'property-value-' + index"
                  type="text"
                  v-model.lazy="property.propertyValue"
                  v-money="
                    property.propertyValue !== null ? currencyFormat : null
                  "
                  v-on:blur="validatePropertyValue($event, index)"
                  v-on:invalid="
                    validations.propertiesOwned[index].propertyValue = 0
                  "
                  required
                />
                <!-- Icon will be inserted below after validating the above input -->
                <validation-icon
                  v-bind:isValid="
                    validations.propertiesOwned[index].propertyValue
                  "
                ></validation-icon>
              </p>

              <!-- Remove property button -->
              <div class="col-8 col-12-xsmall">
                <button
                  title="Remove Property"
                  v-bind:key="index + 1"
                  @click.prevent="removeProperty(index)"
                  class="remove-button"
                >
                  <i class="fa fa-lg fa-times"></i> Delete Property
                </button>
              </div>

              <!-- Status -->
              <div class="col-12 col-12-xsmall">
                <p style="display: inline">
                  <label
                    class="urla-radio-label small-label"
                    v-bind:for="'status-' + index"
                  >
                    <span>Status:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                </p>
                <fieldset
                  class="urla-radio radio-inline"
                  style="display: inline"
                >
                  <ul style="padding-left: 0">
                    <li>
                      <input
                        v-bind:name="'status-' + index"
                        type="radio"
                        v-bind:id="'retained-' + index"
                        value="Retained"
                        v-model="property.status"
                      />
                      <label
                        class="font-weight-normal small-label"
                        v-bind:for="'retained-' + index"
                        >Retained</label
                      >
                    </li>
                    <li>
                      <input
                        v-bind:name="'status-' + index"
                        type="radio"
                        v-bind:id="'sold-' + index"
                        value="Sold"
                        v-model="property.status"
                      />
                      <label
                        class="font-weight-normal small-label"
                        v-bind:for="'sold-' + index"
                        >Sold</label
                      >
                    </li>
                    <li>
                      <input
                        v-bind:name="'status-' + index"
                        type="radio"
                        v-bind:id="'pending-sale-' + index"
                        value="Pending Sale"
                        v-model="property.status"
                        required
                      />
                      <label
                        class="font-weight-normal medium-label"
                        v-bind:for="'pending-sale-' + index"
                        >Pending Sale</label
                      >
                    </li>
                  </ul>
                </fieldset>
              </div>
            </div>

            <!-- Property Address -->
            <div class="row">
              <div class="col-12 col-12-xsmall">
                <h4>Property Address:</h4>
              </div>

              <div class="col-6 col-12-xsmall">
                <!-- Street -->
                <p>
                  <label class="small-label" v-bind:for="'street-' + index">
                    <span>Street:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                  <input
                    class="medium-input"
                    v-bind:id="'street-' + index"
                    type="text"
                    placeholder="Street"
                    v-model="property.propertyAddress.street"
                    v-on:input="validateStreet($event, index)"
                    v-on:invalid="
                      validations.propertiesOwned[
                        index
                      ].propertyAddress.street = 0
                    "
                    required
                  />
                  <!-- Icon will be inserted below after validating the above input -->
                  <validation-icon
                    v-bind:isValid="
                      validations.propertiesOwned[index].propertyAddress.street
                    "
                  ></validation-icon>
                </p>

                <!-- Unit -->
                <p>
                  <label class="small-label" v-bind:for="'unit-' + index"
                    >Unit:</label
                  >
                  <input
                    class="medium-input"
                    v-bind:id="'unit-' + index"
                    type="text"
                    placeholder="unit"
                    v-model="property.propertyAddress.unit"
                    v-on:input="validateUnit($event, index)"
                  />
                  <!-- Icon will be inserted below after validating the above input -->
                  <validation-icon
                    v-bind:isValid="
                      validations.propertiesOwned[index].propertyAddress.unit
                    "
                  ></validation-icon>
                </p>

                <!-- Zip -->
                <p>
                  <label class="small-label" v-bind:for="'zip-' + index">
                    <span>Zip:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                  <input
                    class="medium-input"
                    v-bind:id="'zip-' + index"
                    type="text"
                    minlength="5"
                    maxlength="5"
                    placeholder="00000"
                    v-model="property.propertyAddress.zip"
                    v-on:input="validateZip($event, index)"
                    v-on:invalid="
                      validations.propertiesOwned[index].propertyAddress.zip = 0
                    "
                    required
                  />
                  <!-- Icon will be inserted below after validating the above input -->
                  <validation-icon
                    v-bind:isValid="
                      validations.propertiesOwned[index].propertyAddress.zip
                    "
                  ></validation-icon>
                </p>
              </div>

              <div class="col-6 col-12-xsmall">
                <!-- City -->
                <p>
                  <label class="small-label" v-bind:for="'city-' + index">
                    <span>City:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                  <input
                    class="medium-input"
                    v-bind:id="'city-' + index"
                    type="text"
                    placeholder="City"
                    v-model="property.propertyAddress.city"
                    v-on:input="validateCity($event, index)"
                    v-on:invalid="
                      validations.propertiesOwned[
                        index
                      ].propertyAddress.city = 0
                    "
                    required
                  />
                  <!-- Icon will be inserted below after validating the above input -->
                  <validation-icon
                    v-bind:isValid="
                      validations.propertiesOwned[index].propertyAddress.city
                    "
                  ></validation-icon>
                </p>

                <!-- State -->
                <p>
                  <label class="small-label" v-bind:for="'state-' + index">
                    <span>State:</span>
                    <strong>
                      <abbr class="required" title="required">*</abbr>
                    </strong>
                  </label>
                  <input
                    class="medium-input"
                    v-bind:id="'state-' + index"
                    type="text"
                    placeholder="State"
                    v-model="property.propertyAddress.state"
                    v-on:input="validateState($event, index)"
                    v-on:invalid="
                      validations.propertiesOwned[
                        index
                      ].propertyAddress.state = 0
                    "
                    required
                  />
                  <!-- Icon will be inserted below after validating the above input -->
                  <validation-icon
                    v-bind:isValid="
                      validations.propertiesOwned[index].propertyAddress.state
                    "
                  ></validation-icon>
                </p>

                <!-- County -->
                <p>
                  <label class="small-label" v-bind:for="'county-' + index"
                    >County:</label
                  >
                  <input
                    class="medium-input"
                    v-bind:id="'county' + index"
                    type="text"
                    placeholder="County"
                    v-model="property.propertyAddress.county"
                    v-on:input="validateCounty($event, index)"
                  />
                  <!-- Icon will be inserted below after validating the above input -->
                  <validation-icon
                    v-bind:isValid="
                      validations.propertiesOwned[index].propertyAddress.county
                    "
                  ></validation-icon>
                </p>
              </div>
            </div>

            <!-- Questions on Retained property -->
            <transition name="fade">
              <div v-if="property.status === 'Retained'">
                <div class="row">
                  <!-- Occupancy -->
                  <div class="col-4 col-12-xsmall">
                    <p>
                      <label
                        class="urla-radio-label"
                        v-bind:for="'occupancy-' + index"
                      >
                        <span>Occupancy after closing this loan:</span>
                        <strong>
                          <abbr class="required" title="required">*</abbr>
                        </strong>
                      </label>
                    </p>
                    <fieldset class="urla-radio">
                      <ul>
                        <li>
                          <input
                            v-bind:name="'occupancy-' + index"
                            type="radio"
                            v-bind:id="'primary-residence-' + index"
                            value="Primary Residence"
                            v-model="property.occupancy"
                            required
                          />
                          <label
                            class="font-weight-normal"
                            v-bind:for="'primary-residence-' + index"
                            >Primary Residence</label
                          >
                        </li>
                        <li>
                          <input
                            v-bind:name="'occupancy-' + index"
                            type="radio"
                            v-bind:id="'second-home-' + index"
                            value="Second Home"
                            v-model="property.occupancy"
                          />
                          <label
                            class="font-weight-normal"
                            v-bind:for="'second-home-' + index"
                            >Second Home</label
                          >
                        </li>
                        <li>
                          <input
                            v-bind:name="'occupancy-' + index"
                            type="radio"
                            v-bind:id="'investment-property-' + index"
                            value="Investment Property"
                            v-model="property.occupancy"
                          />
                          <label
                            class="font-weight-normal"
                            v-bind:for="'investment-property-' + index"
                            >Investment Property</label
                          >
                        </li>
                        <li>
                          <input
                            v-bind:name="'occupancy-' + index"
                            type="radio"
                            v-bind:id="'fha-secondary-residence-' + index"
                            value="FHA Secondary Residence"
                            v-model="property.occupancy"
                          />
                          <label
                            class="font-weight-normal"
                            v-bind:for="'fha-secondary-residence-' + index"
                            >FHA Secondary Residence</label
                          >
                        </li>
                        <li>
                          <input
                            v-bind:name="'occupancy-' + index"
                            type="radio"
                            v-bind:id="'commercial-space-' + index"
                            value="Commercial Space"
                            v-model="property.occupancy"
                          />
                          <label
                            class="font-weight-normal"
                            v-bind:for="'commerical-space-' + index"
                            >Commercial Space</label
                          >
                        </li>
                      </ul>
                    </fieldset>
                  </div>

                  <div class="col-8 col-12-xsmall">
                    <transition name="fade">
                      <div
                        v-if="property.occupancy === 'Investment Property'"
                        class="row"
                      >
                        <div class="col-6 col-12-xsmall">
                          <!-- Purchased On -->
                          <p>
                            <label
                              v-bind:for="'purchased-on-' + index"
                              class="medium-label"
                            >
                              <span>Purchased On:</span>
                              <strong>
                                <abbr class="required" title="required">*</abbr>
                              </strong>
                            </label>
                            <date-pick
                              v-bind:id="'purchased-on-' + index"
                              class="date-pick small-input"
                              v-mask="'##/##/####'"
                              v-bind:inputAttributes="{
                                placeholder: 'MM/DD/YYYY',
                                required: 'required',
                              }"
                              v-bind:isDateDisabled="isFutureDate"
                              v-bind:displayFormat="'MM/DD/YYYY'"
                              v-model="property.purchasedOn"
                              v-on:input="validatePurchasedOn($event, index)"
                              v-on:invalid="
                                validations.propertiesOwned[
                                  index
                                ].purchasedOn = 0
                              "
                            ></date-pick>
                            <!-- Icon will be inserted below after validating the above input -->
                            <validation-icon
                              v-bind:isValid="
                                validations.propertiesOwned[index].purchasedOn
                              "
                            ></validation-icon>
                          </p>

                          <!-- h4>Rental Income on the property You Want to Purchase</h4 -->
                          <!-- Monthly Rental Income -->
                          <p>
                            <label
                              v-bind:for="'monthly-rental-income-' + index"
                              class="medium-label"
                            >
                              <span>Monthly Rental Income:</span>
                              <strong>
                                <abbr class="required" title="required">*</abbr>
                              </strong>
                            </label>
                            <input
                              class="small-input"
                              v-bind:id="'monthly-rental-income-' + index"
                              type="text"
                              v-model.lazy="property.monthlyRentalIncome"
                              v-money="
                                property.monthlyRentalIncome !== null
                                  ? currencyFormat
                                  : null
                              "
                              v-on:blur="
                                validateMonthlyRentalIncome($event, index)
                              "
                              v-on:invalid="
                                validations.propertiesOwned[
                                  index
                                ].monthlyRentalIncome = 0
                              "
                              required
                            />
                            <!-- Icon will be inserted below after validating the above input -->
                            <validation-icon
                              v-bind:isValid="
                                validations.propertiesOwned[index]
                                  .monthlyRentalIncome
                              "
                            ></validation-icon>
                          </p>

                          <!-- Investment property since -->
                          <p>
                            <label
                              v-bind:for="'investment-property-since-' + index"
                              class="medium-label"
                            >
                              <span>Investment Property since:</span>
                              <strong>
                                <abbr class="required" title="required">*</abbr>
                              </strong>
                            </label>
                            <date-pick
                              v-bind:id="'investment-property-since-' + index"
                              class="date-pick small-input"
                              v-mask="'##/##/####'"
                              v-bind:inputAttributes="{
                                placeholder: 'MM/DD/YYYY',
                                required: 'required',
                              }"
                              v-bind:isDateDisabled="isFutureDate"
                              v-bind:displayFormat="'MM/DD/YYYY'"
                              v-model="property.investmentPropertySince"
                              v-on:input="
                                validateInvestmentPropertySince($event, index)
                              "
                              v-on:invalid="
                                validations.propertiesOwned[
                                  index
                                ].investmentPropertySince = 0
                              "
                            ></date-pick>
                            <!-- Icon will be inserted below after validating the above input -->
                            <validation-icon
                              v-bind:isValid="
                                validations.propertiesOwned[index]
                                  .investmentPropertySince
                              "
                            ></validation-icon>
                          </p>
                        </div>

                        <!-- Insurance, Taxes and HoA Fee -->
                        <div class="col-6 col-12-xsmall">
                          <!-- Monthly Insurance -->
                          <p>
                            <label
                              class="medium-label"
                              v-bind:for="'monthly-insurance-' + index"
                            >
                              <span>Insurance (per month):</span>
                            </label>
                            <input
                              class="small-input"
                              v-bind:id="'monthly-insurance-' + index"
                              type="text"
                              v-model.lazy="property.monthlyInsurance"
                              v-money="
                                property.monthlyInsurance !== null
                                  ? currencyFormat
                                  : null
                              "
                              v-on:blur="
                                validateMonthlyInsurance($event, index)
                              "
                            />
                            <!-- Icon will be inserted below after validating the above input -->
                            <validation-icon
                              v-bind:isValid="
                                validations.propertiesOwned[index]
                                  .monthlyInsurance
                              "
                            ></validation-icon>
                          </p>

                          <!-- Monthly Taxes -->
                          <p>
                            <label
                              class="medium-label"
                              v-bind:for="'monthly-taxes-' + index"
                            >
                              <span>Taxes (per month):</span>
                            </label>
                            <input
                              class="small-input"
                              v-bind:id="'monthly-taxes-' + index"
                              type="text"
                              v-model.lazy="property.monthlyTaxes"
                              v-money="
                                property.monthlyTaxes !== null
                                  ? currencyFormat
                                  : null
                              "
                              v-on:blur="validateMonthlyTaxes($event, index)"
                            />
                            <!-- Icon will be inserted below after validating the above input -->
                            <validation-icon
                              v-bind:isValid="
                                validations.propertiesOwned[index].monthlyTaxes
                              "
                            ></validation-icon>
                          </p>

                          <!-- Monthly HOA Fee -->
                          <p>
                            <label
                              class="medium-label"
                              v-bind:for="'monthly-hoa-' + index"
                            >
                              <span>HoA Fee (per month):</span>
                            </label>
                            <input
                              class="small-input"
                              v-bind:id="'monthly-hoa-' + index"
                              type="text"
                              v-model.lazy="property.monthlyHoaFee"
                              v-money="
                                property.monthlyHoaFee !== null
                                  ? currencyFormat
                                  : null
                              "
                              v-on:blur="validateMonthlyHoaFee($event, index)"
                            />
                            <!-- Icon will be inserted below after validating the above input -->
                            <validation-icon
                              v-bind:isValid="
                                validations.propertiesOwned[index].monthlyHoaFee
                              "
                            ></validation-icon>
                          </p>
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </transition>

            <!-- do you have loans on this property? -->
            <div>
              <p style="line-height: 25px; padding-bottom: 0">
                <label
                  style="width: auto"
                  v-bind:for="'other-loans-on-property-' + index"
                >
                  <span class="font-weight-normal">
                    Do you have any
                    <span class="font-weight-bold">Mortgage Loans</span> on this
                    property?
                  </span>
                  <strong>
                    <abbr class="required" title="required">*</abbr>
                  </strong>
                </label>
              </p>
              <fieldset class="urla-radio radio-inline">
                <ul>
                  <li>
                    <input
                      v-bind:name="'other-loans-on-property-' + index"
                      type="radio"
                      v-bind:id="'other-loans-on-property-no-' + index"
                      v-bind:value="false"
                      v-model="property.hasLoansOnProperty"
                      v-on:change="skipLoansOnProperty(index)"
                      required
                    />
                    <label
                      class="font-weight-normal small-label"
                      v-bind:for="'other-loans-on-property-no-' + index"
                      >No</label
                    >
                  </li>
                  <li>
                    <input
                      v-bind:name="'other-loans-on-property-' + index"
                      type="radio"
                      v-bind:id="'other-loans-on-property-yes-' + index"
                      v-bind:value="true"
                      v-model="property.hasLoansOnProperty"
                      v-on:change="skipLoansOnProperty(index)"
                    />
                    <label
                      class="font-weight-normal small-label"
                      v-bind:for="'other-loans-on-property-yes-' + index"
                      >Yes</label
                    >
                  </li>
                </ul>
              </fieldset>
            </div>
            <transition name="fade">
              <div v-if="property.mortgageLoansOnThisProperty.length !== 0">
                <hr class="urla-break" />

                <!-- Mortgage Loans on this property -->
                <div class="row">
                  <div class="col-4 col-12-xsmall">
                    <h4>Mortgage Loans on this property:</h4>
                  </div>
                </div>

                <transition-group name="list" tag="div">
                  <div
                    class="list-item-transition"
                    v-for="(loan, j) in property.mortgageLoansOnThisProperty"
                    v-bind:key="'loan-' + index + '-' + j"
                  >
                    <div class="row inner-item-action-wrapper">
                      <!-- Loan Number -->
                      <h5 class="col-4 col-12-xsmall">
                        Mortgage Loan {{ j + 1 }}
                      </h5>

                      <!-- Remove loan on property button -->
                      <div
                        class="col-8 col-12-xsmall"
                        style="display: inline-block"
                      >
                        <button
                          title="Remove Loan on Property"
                          @click.prevent="removeLoanOnProperty(index, j)"
                          class="inner-remove-button"
                          style="float: none"
                        >
                          <i class="fa fa-lg fa-times"></i> Delete loan
                        </button>
                      </div>

                      <!-- Creditor Name -->
                      <p class="col-4 col-12-xsmall">
                        <label
                          class="medium-label"
                          v-bind:for="'creditor-name-' + index + '-' + j"
                          >Creditor Name:</label
                        >
                        <input
                          class="small-input"
                          v-bind:id="'creditor-name-' + index + '-' + j"
                          type="text"
                          placeholder="Creditor Name"
                          v-model="loan.creditorName"
                          v-on:input="validateCreditorName($event, index, j)"
                        />
                        <!-- Icon will be inserted below after validating the above input -->
                        <validation-icon
                          v-bind:isValid="
                            validations.propertiesOwned[index]
                              .mortgageLoansOnThisProperty[j].creditorName
                          "
                        ></validation-icon>
                      </p>

                      <!-- Account Number -->
                      <p class="col-4 col-12-xsmall urla-middle-section">
                        <label
                          class="medium-label"
                          v-bind:for="'account-number-' + index + '-' + j"
                          >Account Number:</label
                        >
                        <input
                          class="small-label"
                          v-bind:id="'account-number-' + index + '-' + j"
                          type="text"
                          placeholder="123456789"
                          v-model="loan.accountNumber"
                          v-on:input="validateAccountNumber($event, index, j)"
                        />
                        <!-- Icon will be inserted below after validating the above input -->
                        <validation-icon
                          v-bind:isValid="
                            validations.propertiesOwned[index]
                              .mortgageLoansOnThisProperty[j].accountNumber
                          "
                        ></validation-icon>
                      </p>

                      <!-- Monthly Mortgage Payment -->
                      <p class="col-4 col-12-xsmall">
                        <label
                          class="medium-label"
                          v-bind:for="
                            'monthly-mortgage-payment' + index + '-' + j
                          "
                          >Mortgage Payment:</label
                        >
                        <input
                          class="small-input"
                          v-bind:id="
                            'monthly-mortgage-payment-' + index + '-' + j
                          "
                          type="text"
                          v-model.lazy="loan.monthlyMortgagePayment"
                          v-money="
                            loan.monthlyMortgagePayment !== null
                              ? currencyFormat
                              : null
                          "
                          v-on:blur="
                            validateMonthlyMortgagePayment($event, index, j)
                          "
                        />
                        <!-- Icon will be inserted below after validating the above input -->
                        <validation-icon
                          v-bind:isValid="
                            validations.propertiesOwned[index]
                              .mortgageLoansOnThisProperty[j]
                              .monthlyMortgagePayment
                          "
                        ></validation-icon>
                      </p>

                      <!-- Unpaid Balance -->
                      <p class="col-4 col-12-xsmall">
                        <label
                          class="medium-label"
                          v-bind:for="'unpaid-balance-' + index + '-' + j"
                          >Unpaid Balance:</label
                        >
                        <input
                          class="small-input"
                          v-bind:id="'unpaid-balance-' + index + '-' + j"
                          type="text"
                          placeholder="Unpaid Balance"
                          v-model.lazy="loan.unpaidBalance"
                          v-money="
                            loan.unpaidBalance !== null ? currencyFormat : null
                          "
                          v-on:blur="validateUnpaidBalance($event, index, j)"
                        />
                        <!-- Icon will be inserted below after validating the above input -->
                        <validation-icon
                          v-bind:isValid="
                            validations.propertiesOwned[index]
                              .mortgageLoansOnThisProperty[j].unpaidBalance
                          "
                        ></validation-icon>
                      </p>

                      <!-- Loan Type -->
                      <div class="col-4 col-12-xsmall urla-middle-section">
                        <label
                          class="medium-label"
                          v-bind:for="'loan-type-' + index + '-' + j"
                          >Type:</label
                        >
                        <select
                          class="small-input"
                          v-bind:id="'loan-type-' + index + '-' + j"
                          v-model="loan.loanType"
                        >
                          <option value="Conventional" selected="selected">
                            Conventional
                          </option>
                          <option value="FHA">FHA</option>
                          <option value="USDA-RD">USDA-RD</option>
                          <option value="VA">VA</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>

                      <!-- Credit Limit -->
                      <p class="col-4 col-12-xsmall">
                        <label
                          class="medium-label"
                          v-bind:for="'credit-limit-' + index + '-' + j"
                          >Credit Limit (if applicable):</label
                        >
                        <input
                          class="small-input"
                          v-bind:id="'credit-limit-' + index + '-' + j"
                          type="text"
                          placeholder="Creditor Limit"
                          v-model.lazy="loan.creditLimit"
                          v-money="
                            loan.creditLimit !== null ? currencyFormat : null
                          "
                          v-on:input="validateCreditLimit($event, index, j)"
                        />
                        <!-- Icon will be inserted below after validating the above input -->
                        <validation-icon
                          v-bind:isValid="
                            validations.propertiesOwned[index]
                              .mortgageLoansOnThisProperty[j].creditLimit
                          "
                        ></validation-icon>
                      </p>

                      <!-- To be paid off before closing -->
                      <div>
                        <p style="display: inline">
                          <label
                            class="urla-radio-label"
                            style="width: 300px"
                            v-bind:for="'to-be-paid-off-' + index + '-' + j"
                          >
                            <span>To be paid off at or before closing?</span>
                            <strong>
                              <abbr class="required" title="required">*</abbr>
                            </strong>
                          </label>
                        </p>
                        <fieldset
                          style="display: inline"
                          class="urla-radio radio-inline"
                        >
                          <ul style="padding-left: 0">
                            <li>
                              <input
                                v-bind:name="
                                  'to-be-paid-off-' + index + '-' + j
                                "
                                type="radio"
                                v-bind:id="'no-' + index + '-' + j"
                                v-bind:value="false"
                                v-model="loan.toBePaidOff"
                              />
                              <label
                                class="font-weight-normal small-label"
                                v-bind:for="'no-' + index + '-' + j"
                                >No</label
                              >
                            </li>
                            <li>
                              <input
                                v-bind:name="
                                  'to-be-paid-off-' + index + '-' + j
                                "
                                type="radio"
                                v-bind:id="'yes-' + index + '-' + j"
                                v-bind:value="true"
                                v-model="loan.toBePaidOff"
                                required
                              />
                              <label
                                class="font-weight-normal small-label"
                                v-bind:for="'yes-' + index + '-' + j"
                                >Yes</label
                              >
                            </li>
                          </ul>
                        </fieldset>
                      </div>
                    </div>
                    <hr class="urla-break" />
                  </div>
                </transition-group>

                <!-- Add Loan Button -->
                <button
                  @click.prevent="addLoanOnProperty(index)"
                  class="button add-button"
                  style="font-size: 13px"
                >
                  <i class="fa fa-plus"></i> Loan
                </button>
              </div>
            </transition>
          </div>
        </transition-group>

        <transition name="fade">
          <div v-if="section3RealEstate.propertiesOwned.length !== 0">
            <!-- Add Property Button -->
            <button
              @click.prevent="addProperty()"
              class="button add-button"
              style="margin: 1rem"
            >
              <i class="fa fa-lg fa-plus"></i> Property
            </button>
          </div>
        </transition>
      </div>

      <!-- clearfix for back and save -->
      <div class="clearfix">
        <!-- back button -->
        <button
          type="button"
          class="primary wizard__back pull-left"
          @click.prevent="goBack()"
        >
          <span>
            <i class="fa fa-arrow-left fa-lg"></i>
            back
          </span>
        </button>
        <!-- save & continue button -->
        <button type="submit" class="primary wizard__next pull-right">
          <span>
            <i class="fa fa-floppy-o" aria-hidden="true"></i>
            save
            <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { bus } from "@/main";
import DatePick from "vue-date-pick";
import validationsMixin from "@/mixins/validationsMixin.js";
import inputMasksMixin from "@/mixins/inputMasksMixin.js";
import jsonUtilMixin from "@/mixins/jsonUtilMixin.js";
import zipCodeMixin from "@/mixins/zipCodeMixin.js";

export default {
  name: "Section3",
  // components used
  components: { "date-pick": DatePick },
  // date received from parent component
  props: ["sourceSection3"],
  // mixins used
  mixins: [validationsMixin, inputMasksMixin, jsonUtilMixin, zipCodeMixin],

  data() {
    return {
      currentDate: null,
      currencyFormat: {
        // input masking for currency
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 0,
      },
      section3RealEstate: {
        loanApplicationProgress: 400,
        currentPage: 2,
        sectionName: "section3RealEstate",
        ownRealEstate: null,
        propertiesOwned: [
          
        ],
      },
      validations: null,
      errors: null,
    };
  },

  methods: {
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },

    getFormattedDate: function(date) {
      // extract day, month and year from date
      const dd = String(date.getDate()).padStart(2, "0");
      const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = date.getFullYear();
      // concat string to yyyy-mm-dd format
      const formattedDate = yyyy + "-" + mm + "-" + dd;
      // return formatted date
      return formattedDate;
    },

    getToday: function() {
      // create new Date
      const date = new Date();

      // return today
      return this.getFormattedDate(date);
    },

    addProperty: function() {
      // empty property object
      const property = {
        propertyAddress: {
          street: null,
          unit: null,
          city: null,
          state: null,
          zip: null,
          county: null,
        },
        propertyValue: null,
        status: null,
        occupancy: null,
        purchasedOn: null,
        investmentPropertySince: null,
        monthlyInsurance: null,
        monthlyTaxes: null,
        monthlyHoaFee: null,
        monthlyRentalIncome: null,
        netMonthlyRentalIncome: null,
        hasLoansOnProperty: null,
        mortgageLoansOnThisProperty: [],
      };

      // empty property object for error
      const propertyError = {
        propertyAddress: {
          street: [],
          unit: [],
          city: [],
          state: [],
          zip: [],
          county: [],
        },
        propertyValue: [],
        status: [],
        monthlyInsurance: [],
        monthlyTaxes: [],
        monthlyHoaFee: [],
        monthlyRentalIncome: [],
        netMonthlyRentalIncome: [],
        mortgageLoansOnThisProperty: [],
      };

      // add empty object to assets list
      this.section3RealEstate.propertiesOwned.push(
        JSON.parse(JSON.stringify(property))
      );

      // add empty object to validations list
      this.validations.propertiesOwned.push(
        JSON.parse(JSON.stringify(property))
      );

      // add empty object to errors list
      this.errors.propertiesOwned.push(
        JSON.parse(JSON.stringify(propertyError))
      );
    },

    removeProperty: function(index) {
      // remove row from properties owned array
      this.section3RealEstate.propertiesOwned.splice(index, 1);

      // remove row from validations array
      this.validations.propertiesOwned.splice(index, 1);

      // remove row from errors array
      this.errors.propertiesOwned.splice(index, 1);

      // set flag for own real estate
      if (this.section3RealEstate.propertiesOwned.length === 0)
        this.section3RealEstate.ownRealEstate = false;
    },

    removeAllProperties: function() {
      // remove row from properties owned array
      this.section3RealEstate.propertiesOwned = [];

      // remove row from validations array
      this.validations.propertiesOwned = [];

      // remove row from errors array
      this.errors.propertiesOwned = [];
    },

    addLoanOnProperty: function(propertyIndex) {
      // empty property object
      const loan = {
        creditorName: null,
        accountNumber: null,
        monthlyMortgagePayment: null,
        unpaidBalance: null,
        toBePaidOff: null,
        loanType: "FHA",
        creditLimit: null,
      };

      // empty property object for error
      const loanError = {
        creditorName: [],
        accountNumber: [],
        monthlyMortgagePayment: [],
        unpaidBalance: [],
        toBePaidOff: [],
        loanType: [],
        creditLimit: [],
      };

      // add empty object to assets list
      this.section3RealEstate.propertiesOwned[
        propertyIndex
      ].mortgageLoansOnThisProperty.push(JSON.parse(JSON.stringify(loan)));

      // add empty object to validations list
      this.validations.propertiesOwned[
        propertyIndex
      ].mortgageLoansOnThisProperty.push(JSON.parse(JSON.stringify(loan)));

      // add empty object to errors list
      this.errors.propertiesOwned[
        propertyIndex
      ].mortgageLoansOnThisProperty.push(JSON.parse(JSON.stringify(loanError)));

      // set flag for hasLoansOnProperty
      if (
        this.section3RealEstate.propertiesOwned[propertyIndex]
          .mortgageLoansOnThisProperty.length !== 0
      )
        this.section3RealEstate.propertiesOwned[
          propertyIndex
        ].hasLoansOnProperty = true;
    },

    removeLoanOnProperty: function(propertyIndex, loanIndex) {
      // remove row from mortgageLoansOnThisProperty owned array
      this.section3RealEstate.propertiesOwned[
        propertyIndex
      ].mortgageLoansOnThisProperty.splice(loanIndex, 1);

      // remove row from validations array
      this.validations.propertiesOwned[
        propertyIndex
      ].mortgageLoansOnThisProperty.splice(loanIndex, 1);

      // remove row from errors array
      this.errors.propertiesOwned[
        propertyIndex
      ].mortgageLoansOnThisProperty.splice(loanIndex, 1);

      // set flag hasLoansOnProperty
      if (
        this.section3RealEstate.propertiesOwned[propertyIndex]
          .mortgageLoansOnThisProperty.length === 0
      )
        this.section3RealEstate.propertiesOwned[
          propertyIndex
        ].hasLoansOnProperty = false;
    },

    removeAllLoansOnProperty: function(propertyIndex) {
      // remove row from mortgage loans on this property array
      this.section3RealEstate.propertiesOwned[
        propertyIndex
      ].mortgageLoansOnThisProperty = [];

      // remove row from validations array
      this.validations.propertiesOwned[
        propertyIndex
      ].mortgageLoansOnThisProperty = [];

      // remove row from errors array
      this.errors.propertiesOwned[
        propertyIndex
      ].mortgageLoansOnThisProperty = [];

      // set flag for hasLoansOnProperty
      this.section3RealEstate.propertiesOwned[
        propertyIndex
      ].hasLoansOnProperty = false;
    },

    skipLoansOnProperty: function(propertyIndex) {
      // add empty loan object if hasLoansOnProperty is true
      if (
        this.section3RealEstate.propertiesOwned[propertyIndex]
          .hasLoansOnProperty
      )
        this.addLoanOnProperty(propertyIndex);
      // clear all loans if hasLoansOnProperty is false
      else this.removeAllLoansOnProperty(propertyIndex);
    },

    skipSection: function() {
      // clear all properties if input is checked
      if (this.section3RealEstate.ownRealEstate === false)
        this.removeAllProperties();
      // add empty property object if input is unchecked
      else this.addProperty();
    },
    autoFillCityAndStateErrorDisplay: function(field, event, validations) {

      // return from function if zipcode is null
      if (field.zipCode === null) return;

      // call POST /customers method of URLA API
      this.getCityAndState(field.zip)
        .then((data) => {
          // return if data is empty
          if (Object.keys(data).length === 0 && data.constructor === Object) {
            field.city = '';
            field.state = '';
            field.county = '';
            this.setZipErorAfterApiResponse(event);
            validations.zip = 0;
            validations.city = 0;
            validations.state = 0;
            return;
          }
          // auto fill city
          field.city = data.City;
          // auto fill State
          field.state = data.State;
          // auto fill county if present
          if ("county" in field) field.county = data.County;
          validations.zip = 1;
          validations.city = 1;
          validations.state = 1;
        })
        .catch(() => {});
    },

    validateStreet: function(event, index) {
      var errors = this.errors.propertiesOwned[index].propertyAddress;
      var validations = this.validations.propertiesOwned[index].propertyAddress;

      // validate street address from validationsMixin
      errors.street = this.checkStreet(event);

      // set validation-icon
      validations.street = errors.street.length === 0 ? 1 : 0;
    },

    validateUnit: function(event, index) {
      var errors = this.errors.propertiesOwned[index].propertyAddress;
      var validations = this.validations.propertiesOwned[index].propertyAddress;

      // validate unit address from validationsMixin
      errors.unit = this.checkUnit(event);

      // set validation-icon
      validations.unit = errors.unit.length === 0 ? 1 : 0;
    },

    validateCity: function(event, index) {
      var errors = this.errors.propertiesOwned[index].propertyAddress;
      var validations = this.validations.propertiesOwned[index].propertyAddress;

      // validate city name from validationsMixin
      errors.city = this.checkCity(event);

      // set validation-icon
      validations.city = errors.city.length === 0 ? 1 : 0;
    },

    validateState: function(event, index) {
      var errors = this.errors.propertiesOwned[index].propertyAddress;
      var validations = this.validations.propertiesOwned[index].propertyAddress;

      // validate state name from validationsMixin
      errors.state = this.checkState(event);

      // set validation-icon
      validations.state = errors.state.length === 0 ? 1 : 0;
    },

    validateZip: function(event, index) {
      var errors = this.errors.propertiesOwned[index].propertyAddress;
      var validations = this.validations.propertiesOwned[index].propertyAddress;
      var field = this.section3RealEstate.propertiesOwned[index].propertyAddress;
      // validate zip from validationsMixin
      errors.zip = this.checkZip(event);
      var zipNoError = errors.zip.length === 0 ? 1 : 0;
      // get city name and State from Zip Code API
      if (zipNoError === 1){
        this.autoFillCityAndStateErrorDisplay(
          field,
           event, validations);
      }else {
      // set validation-icon
      validations.zip = errors.zip.length === 0 ? 1 : 0;
      }
    },

    validateCounty: function(event, index) {
      var errors = this.errors.propertiesOwned[index].propertyAddress;
      var validations = this.validations.propertiesOwned[index].propertyAddress;

      // validate County from validationsMixin
      errors.county = this.checkCounty(event);

      // set validation-icon
      validations.county = errors.county.length === 0 ? 1 : 0;
    },

    validatePropertyValue: function(event, index) {
      // validate price from validationsMixin
      this.errors.propertiesOwned[index].propertyValue = this.checkPrice(event);

      // set validation-icon
      this.validations.propertiesOwned[index].propertyValue =
        this.errors.propertiesOwned[index].propertyValue.length === 0 ? 1 : 0;
    },

    validateMonthlyInsurance: function(event, index) {
      var errors = this.errors.propertiesOwned[index];
      var validations = this.validations.propertiesOwned[index];

      // validate price from validationsMixin
      errors.monthlyInsurance = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.monthlyInsurance =
        errors.monthlyInsurance.length === 0 ? 1 : 0;
    },

    validateMonthlyTaxes: function(event, index) {
      var errors = this.errors.propertiesOwned[index];
      var validations = this.validations.propertiesOwned[index];

      // validate price from validationsMixin
      errors.monthlyTaxes = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.monthlyTaxes = errors.monthlyTaxes.length === 0 ? 1 : 0;
    },

    validateMonthlyHoaFee: function(event, index) {
      var errors = this.errors.propertiesOwned[index];
      var validations = this.validations.propertiesOwned[index];

      // validate price from validationsMixin
      errors.monthlyHoaFee = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.monthlyHoaFee = errors.monthlyHoaFee.length === 0 ? 1 : 0;
    },

    validatePurchasedOn: function(inputValue, index) {
      // min purchased on
      var min = new Date();
      min.setFullYear(min.getFullYear() - 100);

      // validate Date from validationsMixin and check range for date
      this.errors.propertiesOwned[
        index
      ].purchasedOn = this.checkDateRangeFromValue(
        inputValue,
        this.getFormattedDate(min),
        this.currentDate
      );

      // set validation-icon
      this.validations.propertiesOwned[index].purchasedOn =
        this.errors.propertiesOwned[index].purchasedOn.length === 0 ? 1 : 0;

      // set investment property since if purchasedOn is valid
      if (
        this.validations.propertiesOwned[index].purchasedOn === 1 &&
        this.section3RealEstate.propertiesOwned[index]
          .investmentPropertySince === null
      )
        this.section3RealEstate.propertiesOwned[
          index
        ].investmentPropertySince = this.section3RealEstate.propertiesOwned[
          index
        ].purchasedOn;
    },

    validateInvestmentPropertySince: function(inputValue, index) {
      // set min value for range
      var min = new Date();
      min.setFullYear(min.getFullYear() - 100);

      // validate Date from validationsMixin and check range for date
      this.errors.propertiesOwned[
        index
      ].investmentPropertySince = this.checkDateRangeFromValue(
        inputValue,
        this.getFormattedDate(min),
        this.currentDate
      );

      // set validation-icon
      this.validations.propertiesOwned[index].investmentPropertySince =
        this.errors.propertiesOwned[index].investmentPropertySince.length === 0
          ? 1
          : 0;
    },

    validateMonthlyRentalIncome: function(event, index) {
      // validate account number from validationsMixin
      this.errors.propertiesOwned[index].monthlyRentalIncome = this.checkPrice(
        event
      );

      // set validation-icon
      this.validations.propertiesOwned[index].monthlyRentalIncome =
        this.errors.propertiesOwned[index].monthlyRentalIncome.length === 0
          ? 1
          : 0;
    },

    validateCreditorName: function(event, index, j) {
      var errors = this.errors.propertiesOwned[index]
        .mortgageLoansOnThisProperty[j];
      var validations = this.validations.propertiesOwned[index]
        .mortgageLoansOnThisProperty[j];

      // validate organization name from validationsMixin
      errors.creditorName = this.checkOrganizationName(event);

      // set validation-icon
      validations.creditorName = errors.creditorName.length === 0 ? 1 : 0;
    },

    validateAccountNumber: function(event, index, j) {
      var errors = this.errors.propertiesOwned[index]
        .mortgageLoansOnThisProperty[j];
      var validations = this.validations.propertiesOwned[index]
        .mortgageLoansOnThisProperty[j];

      // validate account number from validationsMixin
      errors.accountNumber = this.checkAccountNumber(event);

      // set validation-icon
      validations.accountNumber = errors.accountNumber.length === 0 ? 1 : 0;
    },

    validateMonthlyMortgagePayment: function(event, index, j) {
      var errors = this.errors.propertiesOwned[index]
        .mortgageLoansOnThisProperty[j];
      var validations = this.validations.propertiesOwned[index]
        .mortgageLoansOnThisProperty[j];

      // validate price from validationsMixin
      errors.monthlyMortgagePayment = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.monthlyMortgagePayment =
        errors.monthlyMortgagePayment.length === 0 ? 1 : 0;
    },

    validateUnpaidBalance: function(event, index, j) {
      var errors = this.errors.propertiesOwned[index]
        .mortgageLoansOnThisProperty[j];
      var validations = this.validations.propertiesOwned[index]
        .mortgageLoansOnThisProperty[j];

      // validate price from validationsMixin
      errors.unpaidBalance = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.unpaidBalance = errors.unpaidBalance.length === 0 ? 1 : 0;
    },

    validateCreditLimit: function(event, index, j) {
      var errors = this.errors.propertiesOwned[index]
        .mortgageLoansOnThisProperty[j];
      var validations = this.validations.propertiesOwned[index]
        .mortgageLoansOnThisProperty[j];

      // validate organization name from validationsMixin
      errors.creditLimit = this.checkPriceAllowZero(event);

      // set validation-icon
      validations.creditLimit = errors.creditLimit.length === 0 ? 1 : 0;
    },

    goBack: function() {
      // emit event to go back with current page number
      bus.$emit("goBack", this.section3RealEstate.currentPage);
    },

    submitSectionThree: function() {
      // validate form
      bus.$emit("postUrla", this.section3RealEstate);
    },
  },

  mounted: function() {
    // set default values

    // current Date
    this.currentDate = this.getToday();

    // scroll to top of page
    this.$scrollTo(this.$refs.sectionThree, {
      duration: 500,
      easing: "ease",
      offset: -200,
    });
  },

  created: function() {
    // copy data received from urla into current section
    this.section3RealEstate = this.parseSection(
      this.section3RealEstate,
      this.sourceSection3
    );

    // initialize validations object to null
    this.validations = this.initializeToNull(this.section3RealEstate);

    // initialize errors object to empty array
    this.errors = this.initializeToEmptyArray(this.section3RealEstate);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
