<template>
  <!-- Main -->
  <section class="main">
    <section class="urla-summary">
      <h2>Application submitted!</h2>
      <!-- Urla submitted message -->
      <h3 class="upload-docs-msg">
        Please upload the below documents
        <a class="link"
          v-on:click="$router.push({ name: 'upload' });">
          here
          <i class="icon fa-external-link"></i>
        </a>
      </h3>
      <h4>Property related documentation required to be uploaded</h4>
      <ol>
        <li>Most recent mortgage statement</li>
        <li>
          Survey of the property given during the original purchase of the house
        </li>
        <li>
          HOA statement or payment history showing the HOA contact details and
          the monthly payment liability
        </li>
        <li>
          current insurance binder and insurance agent email address and phone
          number
        </li>
        <li>
          NOTE, deed of trust and closing disclosure from the most recent
          purchase or refinance transaction
        </li>
        <li v-if="'PURCHASE' === loanPurpose.toUpperCase()">
          Purchase Order
        </li>
      </ol>
      <h4>Income and asset requirements</h4>
      <ol>
        <li>Three recent paystubs</li>
        <li>Two recent W2s</li>
        <li>Two recent bank statements</li>
        <li>Most recent tax return</li>
        <li>Bank statement showing the payment or refund from the IRS</li>
        <li>If you own more than 25% of any company we need the following</li>
        <ul>
          <li>K1 showing income from the company</li>
          <li>Most recent business tax returns</li>
        </ul>
      </ol>
      <h4>Identification and work eligibility</h4>
      <ul>
        <li>
          if you are a U.S.citizen please provide clear front and back pictures
          of your Drivers license
        </li>
        <li>
          If you are green card holder please provide clear front and back
          pictures of your green card
        </li>
        <li>if you are on work visa, please provide</li>
        <ul class="circle">
          <li>recent valid passport picture</li>
          <li>Valid I-797 both pages clearly scanned as a PDF</li>
        </ul>
      </ul>
      <p>
        We appreciate you for considering American Smart Lending for your
        mortgage needs. We will get back to you as soon as possible.
      </p>
      <p>
        Please do not hesitate to reach us at
        <a href="tel:469-525-6725">+1(469)525-6725</a> or
        <a href="mailto:kc@texsmartlending.com">kc@texsmartlending.com</a>
        in case you need to reach us before the end of the day.
      </p>
    </section>
  </section>
</template>

<script>
export default {
  // Component Name
  name: "UrlaSubmitted",
  // props can be set from parent component
  props: ["message", "loanPurpose"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.upload-docs-msg {
  color: black;
}

.urla-summary p ol {
  font-size: 18px;
  margin: 0 0 1rem 0;
  color: black !important;
}

.urla-summary h2 h3 h4 {
  color: black;
  margin: 0 0 0.2rem 0;
}

/* styles for field names and values */
.field,
.field-value {
  display: inline-block;
  width: 200px;
  box-sizing: border-box;
}

ul.circle {
  list-style-type: circle;
}

.field {
  font-weight: bold;
}

.link {
  cursor: pointer;
}
</style>
