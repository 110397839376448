import Vue from "vue";
import Vuex from "vuex";
import authenticationMixin from "@/mixins/authenticationMixin.js"

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    isAuthenticated: false,
    lastRoute: null,

    // AuthenticationInfo
    loggedInUser:null,
    authData: null,
    user: null,
    isSignedIn : false,
  },

  getters: {
    authUser: (state) => {
      return state.user;
    },

    signedIn: (state) => {
      state.loggedInUser = window.localStorage.getItem('username');
      state.emailId = window.localStorage.getItem('emailId');
      if (state.isSignedIn || null != state.loggedInUser) {
        return true;
      }
      return false
    },

    getLastRoute: (state) => state.lastRoute,

    loggedInUser: (state) => state.loggedInUser,

    getAuthData: (state) => state.authData,

    getAccessToken: (state) => state.authData ? state.authData.access_token : null,

    getRefreshToken: (state) => state.authData && state.authData.refresh_token,

    getUserId: (state) => state.authData && state.authData.user_id,

    getUserUuid: (state) => state.authData ? state.authData.user_uuid : null,

    getUserRoles: (state) => {
      let roles = []
      if (state.authData && state.authData.roles) {
        roles = state.authData.roles
      }
      return roles;
    },

    getAccessTokenExpirationTime: (state) => state.authData && state.authData.accessTokenExpirationTime,

    getRefreshTokenExpirationTime: (state) => state.authData && state.authData.refreshTokenExpirationTime,

    emailId: (state) => state.emailId,
  },

  mutations: {
    setAuthentication(state, status) {
      state.isAuthenticated = status;
    },

    // save last route
    SAVE_LAST_ROUTE: async (state, routeName) => {
      state.lastRoute = routeName;
    },
    
    // Save autheData in application store
    SAVE_AUTH_DATA_APP: async (state, tokenData) => {
      state.authData = JSON.parse(JSON.stringify(tokenData));
      state.loggedInUser = window.localStorage.getItem('username');
      state.isSignedIn = true;
    },

    // Save authData in Browser Local Storage
    SAVE_AUTH_DATA_BROWSER: (state, tokenData) => {
      window.localStorage.setItem('authData', JSON.stringify(tokenData));
      let decodedAccessToken = JSON.parse(Buffer.from(tokenData.access_token.split('.')[1], 'base64').toString());
      let username = decodedAccessToken.sub.split('@')[0]
      window.localStorage.setItem('username', username)
    },

    UPDATE_USER: (state, user) => {
      state.user = user;

      if(state.user == null){
        window.localStorage.removeItem('username');
        state.loggedInUser = null
      }
      else if(state.user.attributes != null){
        window.localStorage.setItem('username', state.user.attributes.email.split('@')[0]);
        window.localStorage.setItem('emailId', state.user.attributes.email);
        state.loggedInUser = window.localStorage.getItem('username');
        state.emailId = window.localStorage.getItem('emailId');
      }
      else{
        window.localStorage.setItem('username', state.user.challengeParam.userAttributes.email.split('@')[0]);
        window.localStorage.setItem('emailId', state.user.challengeParam.userAttributes.email);
        state.loggedInUser = window.localStorage.getItem('username');
        state.emailId = window.localStorage.getItem('emailId');
      }
    },

    CLEAR_AUTH_SESSION_BROWSER: () => {
      window.localStorage.removeItem('authData');
      window.localStorage.removeItem('loggedInUser');
      window.localStorage.removeItem('username');
      window.localStorage.removeItem('userEmail');
      window.localStorage.removeItem('userUuid');
    },

    CLEAR_AUTH_SESSION_STATE: (state) => {
      state.authData = null
      state.loggedInUser = null
      state.isSignedIn = false;
    },

    RENEW_TOKEN: async (state, refreshToken) => {
      let newTokenData = await authenticationMixin.methods.getNewAuthToken(refreshToken);
      // Calculate new expiration time of access token
      let updatedTokenData = authenticationMixin.methods.calculateAccessTokenExpirationTime(newTokenData?.data);
      // Calculate new expiration time of refresh token
      updatedTokenData = authenticationMixin.methods.calculateRefreshTokenExpirationTime(updatedTokenData);
      // Update the new token in local storage
      let authDataInBrowser = JSON.parse(window.localStorage.getItem('authData'));
      authDataInBrowser.access_token = updatedTokenData.access_token;
      authDataInBrowser.refresh_token = updatedTokenData.refresh_token;
      authDataInBrowser.accessTokenExpirationTime = updatedTokenData.accessTokenExpirationTime;
      authDataInBrowser.refreshTokenExpirationTime = updatedTokenData.refreshTokenExpirationTime;
      window.localStorage.setItem('authData', JSON.stringify(authDataInBrowser));

      // Update the new token in state variables 
      state.authData = authDataInBrowser
      state.loggedInUser = window.localStorage.getItem('username')
    }

  },

  actions: {

    SAVE_AUTH_DATA: (context, tokenData) => {
      context.commit('SAVE_AUTH_DATA_BROWSER', tokenData);
      context.commit('SAVE_AUTH_DATA_APP', tokenData);
    },

    SAVE_LAST_ROUTE: (context, routeName) => {
      context.commit('SAVE_LAST_ROUTE', routeName);
    },

    SAVE_AUTH_DATA_APP: (context, tokenData) => {
      context.commit('SAVE_AUTH_DATA_APP', tokenData);
    },

    SAVE_AUTH_DATA_BROWSER: (context, tokenData) => {
      context.commit('SAVE_AUTH_DATA_BROWSER', tokenData);
    },

    RENEW_TOKEN: async (context, refreshToken) => {
      await context.commit('RENEW_TOKEN', refreshToken);
    },

    SIGN_OUT: (context) => {
      context.commit("CLEAR_AUTH_SESSION_BROWSER")
      context.commit("CLEAR_AUTH_SESSION_STATE")
    },
  },
});
