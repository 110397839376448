//************************************************************************ */
//  This file has methods for handling File Folder structure
//----------- group parent and child from the paths -----------------------//

//## get existing files for the node
//----------- generate path and node id object ----------------------------
export const generateFolderRegister = function (nodeTree,folderRegister) {
    nodeTree.forEach(nodeObj => {
        if(nodeObj.type == 'Folder') {
            folderRegister[nodeObj.path] = {};
            folderRegister[nodeObj.path].id = nodeObj.id.toString();
            folderRegister[nodeObj.path].isActive = false;
           // folderRegister[nodeObj.path] = nodeObj.id.toString();
        }
        if(nodeObj.nodes.length > 0) {
            generateFolderRegister(nodeObj.nodes, folderRegister);
        }
    });
}

//----------- insert new files into the tree --------------------------------------------------------
export const addFilesToTree = function (nodeTree, filesList, folderRegister) {
    filesList.forEach(fileObj => {
        var nodeId = folderRegister[fileObj.path.id];
        var address = folderRegister[fileObj.path.id].split('-');
        addFileAtAddress(fileObj,nodeId,address,nodeTree);
    });
}

const addFileAtAddress = function(fileObj,nodeId,address,nodeTree) {
    var index = address.shift();
    index = parseInt(index);
    if(address.length > 0) {
        addFileAtAddress(fileObj,nodeId,address,nodeTree[index].nodes);
    } else {
        //add files only next to the child folders
        var folderIndex = 0;
        nodeTree[index].nodes.forEach((nodeObj) => {
            if(nodeObj.type == 'Folder') {
                folderIndex++;
            }
        });
        fileObj.name = fileObj.filename;
        fileObj.nodes = [];
        fileObj.isFileSelected = false;
        fileObj.isSelectionEnabled = false;
        fileObj.type = 'File';
        fileObj.id = nodeId + '-' + folderIndex;
        var parents = fileObj.path.split('/');
        fileObj.parent = parents[parents.length - 1];
        nodeTree[index].nodes.splice(folderIndex,0,fileObj);
        //update ids of the succeeding files
        for(let i=folderIndex + 1;i < nodeTree[index].nodes.length;i++) {
            nodeTree[index].nodes[i].id = nodeId + '-' + i;
        }
    }
}

const getExistingFilesInNode = function(pathValue,pathAndFiles,foldername,folderId,siblingFolderCount) {

    if(!pathAndFiles) {
        return [];
    }
    var files = [];
    var wrappedFiles = [];
    var filecounter = siblingFolderCount;
    pathAndFiles.forEach(pathObj => {
       if(pathObj.path == pathValue) {
           files=[...pathObj.files];
       }
    });
    files.forEach(file => {
        if(file.filename != "no-file") {
            var fileObj = {};
            fileObj.name = file.filename;
            fileObj.fileId = file.fileId;
            fileObj.isFile = file.isFile;
            fileObj.lastUpdatedOn = file.lastUpdatedOn;
            fileObj.url = file.url;
            fileObj.fileFormat = file.fileFormat;
            fileObj.path = file.path;
            fileObj.parent = foldername;
            fileObj.type = "File";
            fileObj.isSelectionEnabled = false;
            fileObj.isFileSelected = false;
            fileObj.id = folderId + '-' + filecounter;
            fileObj.nodes = [];
            wrappedFiles.push(fileObj);
            filecounter++; 
        } 
    });
    
    return wrappedFiles;
}
//## Generate list of folder nodes ###
//generateTree(paths,parent_id,parent_name,path_addr);
//returns tree list and cummulative file count in the folders
const generateTree = function(paths,parentId,parent_name,parent_path,pathAndFiles) {

var nodeWrapper = {};
nodeWrapper.tree = [];
nodeWrapper.fileCount = 0;
//check if empty array
if(paths.length <= 0) return nodeWrapper;
//remove empty arrays
var temp_paths = [];
paths.forEach(arr => {
    if(arr.length > 0) {
        temp_paths.push(arr);
    }
});
if(temp_paths.length <= 0) return nodeWrapper;

paths = temp_paths;

//first gather child nodes
var first = paths[0][0];
var node = {};
node.name = first;
node.nodes = [];
var nodenum = 0;
var commonlist = [];
var parent_nodes = [];
var totalFileCount = 0;
var childnodeWrapper = {};
var fileNodes = {};
for(let r=0;r<=paths.length;r++) {
    if(r==paths.length) {
        commonlist.forEach(arr => arr.shift());
        node.name = first;
        node.isActive = false;
        node.type = "Folder";
        node.displayTag = true;
        node.parent = parent_name;
        if(parentId !=null) {
            node.id = parentId + '-' + nodenum;
            node.path = parent_path + '/' + node.name;
        } else {
            node.id = nodenum;
            node.path = node.name;
        }
        //add files to the folder
        childnodeWrapper = generateTree(commonlist,node.id,node.name,node.path,pathAndFiles);
        fileNodes = getExistingFilesInNode(node.path,pathAndFiles,node.name,node.id,childnodeWrapper.tree.length);
        node.fileCount = fileNodes.length + childnodeWrapper.fileCount;
        totalFileCount = totalFileCount + node.fileCount;
        node.nodes.push(...childnodeWrapper.tree);
        node.nodes.push(...fileNodes);
        parent_nodes.push(node);
        nodeWrapper.tree = [];
        nodeWrapper.tree = [...parent_nodes];
        nodeWrapper.fileCount = totalFileCount;
        return nodeWrapper;
    } else {
        if(paths[r][0] != first) {
            //gather previous node data
            node.name = first;
            node.isActive = false;
            node.type = "Folder";
            node.displayTag = true;
            node.parent = parent_name;
            if(parentId !=null) {
               node.id = parentId + '-' + nodenum;
               node.path = parent_path + '/' + node.name;
            } else {
                node.id = nodenum;
                node.path = node.name;
            }
            //apply recursion here
            commonlist.forEach(arr => arr.shift());
            childnodeWrapper = generateTree(commonlist,node.id,node.name,node.path,pathAndFiles);
            fileNodes = getExistingFilesInNode(node.path,pathAndFiles,node.name,node.id,childnodeWrapper.tree.length);
            node.fileCount = fileNodes.length + childnodeWrapper.fileCount;
            totalFileCount = totalFileCount + node.fileCount;
            node.nodes.push(...childnodeWrapper.tree);
            node.nodes.push(...fileNodes);
            parent_nodes.push(node);
            //clear old common list
            commonlist=[];
            node = {};
            //create current node
            node.nodes = [];
            commonlist.push(paths[r]);
            nodenum++;
            first = paths[r][0];
        
        } else {
            commonlist.push(paths[r]);
        }
    }
}
}

export const generateTreeView = function(filesList) {

//extract paths from files
var pathList = [];
var filepaths = [];
filesList.forEach(fileObj => {
    pathList.push(fileObj.path);
});

//remove redundant paths
var pathValues = pathList.filter(function(item, pos) {
            return pathList.indexOf(item) == pos;
        });
filepaths = [...pathValues];

//remove root folder from path values
const root_index = pathValues.indexOf(':root');
if (root_index > -1) {
  pathValues.splice(root_index, 1);
}

//create list of path objects with files
var pathAndFiles = [];
filepaths.forEach(pathValue => {
    var files = filesList.filter(function(pathObj) { return pathObj.path === pathValue && pathObj.isFile }).map(function(fileObj) { return fileObj });
    var pathAndFileObj = {};
    pathAndFileObj.path = pathValue;
    
    //sort files based on date
    files.sort((a, b) => {
        var c = new Date(a.lastUpdatedOn);
        var d = new Date(b.lastUpdatedOn);
        return d-c;
    });
    pathAndFileObj.files = files;
    pathAndFiles.push(pathAndFileObj);
});

//sort paths in alphanumeric order
pathValues.sort((a,b) => a.localeCompare(b, 'en', { numeric: true }));

//break paths
var paths = [];
pathValues.forEach(path=>{
    var arr = path.split('/');
    paths.push(arr);
});

//## list of nodes

var treeObj = generateTree(paths,null,null,null,pathAndFiles);

//add files from root into the tree
var files = [];
var wrappedFiles = [];
var filecounter = treeObj.tree.length;
pathAndFiles.forEach(pathObj => {
   if(pathObj.path == ":root") {
       files=[...pathObj.files];
   }
});
files.forEach(file => {
    if(file.filename != "no-file") {
        var fileObj = {};
        fileObj.name = file.filename;
        fileObj.fileId = file.fileId;
        fileObj.isFile = file.isFile;
        fileObj.lastUpdatedOn = file.lastUpdatedOn;
        fileObj.url = file.url;
        fileObj.fileFormat = file.fileFormat;
        fileObj.path = file.path;
        fileObj.parent = null;
        fileObj.type = "File";
        fileObj.isSelectionEnabled = false;
        fileObj.isFileSelected = false;
        fileObj.id = filecounter;
        fileObj.nodes = [];
        wrappedFiles.push(fileObj);
        filecounter++; 
    } 
});

treeObj.tree.push(...wrappedFiles);
return treeObj.tree;

}

	//------ create new folder -----------------------
    export const createNewFolder = function(toAddressId,foldername,nodeTree) {
        var toAddress = ":root";
        if(toAddressId != ":root") {
            toAddress = toAddressId.split('-');
        }
        createFolder(toAddress,foldername,nodeTree);
    }
    
    const createFolder = function (toAddress,foldername,nodeTree) {
        
        if(toAddress == ":root") {
            let folderNodes = nodeTree.filter(nodeObj => { return nodeObj.type === "Folder"});
        let folderIndex = folderNodes.length;
        //push files to the last
        let fileNodes = nodeTree.filter(nodeObj => { return nodeObj.type === "File"});
        let fileIndex = folderIndex + 1;
            let folderObj = {};
            folderObj.name=foldername;
            folderObj.parent=null;
            folderObj.path=foldername;
            folderObj.isActive=false;
            folderObj.type="Folder";
            folderObj.nodes=[];
            folderObj.id=folderIndex;
            nodeTree.splice(folderIndex, 0, folderObj);
            //update file Node Ids
            for(let i=0; i<fileNodes.length; i++) {
              nodeTree[fileIndex].id=fileIndex;
              fileIndex++;
          }
            return;
        }
        var index = toAddress.shift();
        if(toAddress.length > 0) {
            createFolder(toAddress,foldername,nodeTree[index].nodes);
        } else {
            let folderNodes = nodeTree[index].nodes.filter(nodeObj => { return nodeObj.type === "Folder"});
            let folderIndex = folderNodes.length;
              //push files to the last
            let fileNodes = nodeTree[index].nodes.filter(nodeObj => { return nodeObj.type === "File"});
            let fileIndex = folderIndex + 1;
            let folderObj = {};
            folderObj.name=foldername;
            folderObj.parent=nodeTree[index].name;
            folderObj.path=nodeTree[index].path + "/" + foldername;
            folderObj.isActive=false;
            folderObj.type="Folder";
            folderObj.nodes=[];
            folderObj.id=nodeTree[index].id + "-" + folderIndex;
            nodeTree[index].nodes.splice(folderIndex, 0, folderObj);
            //update file Node Ids
            for(let i=0; i<fileNodes.length; i++) {
              nodeTree[index].nodes[fileIndex].id=fileIndex;
              fileIndex++;
          }
        }
    }
        //update files in Tree
	export  const updateUploadedFileInTree = function(toAddressId, selectedFileObj, nodeTree) {
        
        if (toAddressId == ":root") {
            //first select file objects

            let filenodes = nodeTree.filter(function(nodeObj) { return nodeObj.type === "File"});
            
                filenodes.push(selectedFileObj);
                //sort files in alphanumeric
                //filenodes.sort((a,b) => a.name.localeCompare(b.name, 'en', { numeric: true }));
                //sort files according to time
                filenodes.sort((a, b) => {
                    var c = new Date(a.lastUpdatedOn);
                    var d = new Date(b.lastUpdatedOn);
                    return d-c;
                });

                //reassign ids to them
                let fileIndex = filenodes.findIndex(fileObj => { 
                    //** replace with fileId here later
                    return fileObj.fileId == selectedFileObj.fileId });
                let folderCount = nodeTree.filter(nodeObj => {
                    return nodeObj.type == "Folder";
                }).length;


                //pushing file into the tree
                nodeTree.splice(fileIndex + folderCount, 0, selectedFileObj);
                //reassign ids to files starting from new file
                for(let i=(fileIndex + folderCount);i<nodeTree.length;i++) {
                    nodeTree[i].id = i;
                    nodeTree[i].parent = null;
                    nodeTree[i].nodes = [];
                    nodeTree[i].type = "File";
                }

                
        } else {
            var toAddress = toAddressId.split('-');
            updateFileInTree(toAddress,selectedFileObj,nodeTree);
        }
    }

    export const updatefileCountInFolder = function(toAddressId, nodeTree, fileCount) {
        if(toAddressId != ":root") {
            var toAddress = toAddressId.split('-');
            updatefileCount(toAddress,nodeTree, fileCount);
        }
    }

    const updatefileCount = function(toAddress,nodeTree,fileCount) {
        var index = toAddress.shift();
        nodeTree[index].fileCount = nodeTree[index].fileCount + fileCount;
        if(toAddress.length > 0) {
            updatefileCount(toAddress,nodeTree[index].nodes, fileCount);
        }
    }
    
    const updateFileInTree = function(toAddress, selectedFileObj, nodeTree) {
        var index = toAddress.shift();
        if(toAddress.length > 0) {
            updateFileInTree(toAddress, selectedFileObj, nodeTree[index].nodes);
        } else {
            if (nodeTree[index].nodes.length <=0) {
                selectedFileObj.id = nodeTree[index].id + '-' + 0;
                selectedFileObj.nodes = [];
                selectedFileObj.parent = nodeTree[index].name;
                nodeTree[index].nodes.push(selectedFileObj); 
            } else {
                //first select files
                let filenodes = nodeTree[index].nodes.filter(function(nodeObj) { return nodeObj.type === "File"});
                filenodes.push(selectedFileObj);

                //sort files in alphanumeric
                //filenodes.sort((a,b) => a.name.localeCompare(b.name, 'en', { numeric: true }));
                //sort files according to time
                filenodes.sort((a, b) => {
                    var c = new Date(a.lastUpdatedOn);
                    var d = new Date(b.lastUpdatedOn);
                    return d-c;
                });

                //reassign ids to them
                let fileIndex = filenodes.findIndex(fileObj => { 
                    //** replace with fileId here later
                    return fileObj.fileId == selectedFileObj.fileId });
                let folderCount = nodeTree[index].nodes.filter(nodeObj => {
                    return nodeObj.type == "Folder";
                }).length;
                //pushing file into the tree
                nodeTree[index].nodes.splice(fileIndex + folderCount, 0, selectedFileObj);
                //reassign ids to files starting from new file
                for(let i=(fileIndex + folderCount);i<nodeTree[index].nodes.length;i++) {
                    nodeTree[index].nodes[i].id = nodeTree[index].id + '-' + i;
                    nodeTree[index].nodes[i].parent = nodeTree[index].name;
                }
            }
        }
    }

        //------ move files in tree ---------------------------------------
        // moveFilesInTree(sourcefolderId,targetFolderId,fileIdsList,movedFiles,this.nodes);

        export const moveFilesInTree = function(fromAddressId,toAddressId,deletedFileIds,movedFileObjs,nodeTree) {
            var toAddressForCopyingFiles=[];
            var toAddress = [];
            var addressCount=0;
            var toAddressPrefix = "";
           if(toAddressId == ":root") {
               nodeTree.every(node => {
                  if(node.type=='File') {
                      return false;
                  }
                  addressCount++;
                  return true;
               });
               toAddressForCopyingFiles=":root";
           } else {
               toAddress=toAddressId.split('-');
               toAddressForCopyingFiles = [...toAddress];
               addressCount = getFirstFileIndex(toAddress,nodeTree);
               toAddressPrefix = toAddressId + '-';
           }
           var existingFilesStartIndex = addressCount;
           var tempmovedFiles = [...movedFileObjs];
           //sort files according to recent dates
           tempmovedFiles.sort(function(a,b){
            return new Date(b.lastUpdatedOn) - new Date(a.lastUpdatedOn);
            });

            var movedFiles = [];
            tempmovedFiles.forEach((fileObj) => {
                var newFileObj = {};
                newFileObj.link={};
                newFileObj.name=fileObj.name;
                newFileObj.isFile=true;
                var parent = fileObj.path.split("/").pop();
                newFileObj.parent=parent != ":root" ? parent : null;
                newFileObj.type='File';
                newFileObj.isSelectionEnabled = false;
                newFileObj.isFileSelected = false;
                newFileObj.id=toAddressPrefix + addressCount;
                newFileObj.nodes=[];
                newFileObj.url = fileObj.link.href;
                newFileObj.link.href=fileObj.link.href;
                newFileObj.link.rel="self";
                newFileObj.lastUpdatedOn = fileObj.lastUpdatedOn;
                newFileObj.path = fileObj.path;
                newFileObj.fileId = fileObj.fileId;
                newFileObj.fileFormat = fileObj.fileFormat;
                movedFiles.push(newFileObj);
                addressCount++;
            });
           //copy files to destination
           copyFilesInTree(existingFilesStartIndex,toAddressPrefix,toAddressForCopyingFiles,movedFiles,nodeTree);
           //delete files from source

           //delete files from source
           /* remember: only files under same folder can be deleted*/
           deleteFilesFromTree(fromAddressId,deletedFileIds,nodeTree);
       }
        
        export const getNodeCount = function(address,nodeTree) {
            var index = address.shift();
            if (address.length > 0) {
                return getNodeCount(address, nodeTree[index].nodes);
            } else {
                return nodeTree[index].nodes.length;
            }
        }

        const getFirstFileIndex = function(address,nodeTree) {
            var index = address.shift();
            if (address.length > 0) {
                return getFirstFileIndex(address,nodeTree[index].nodes);
            } else {
                var count=0;
                nodeTree[index].nodes.every(node => {
                    if(node.type=='File') {
                        return false;
                    }
                    count++;
                    return true;
                });
                return count;
            }
        }
        
        export const fetchFilesFromTree = function(fileAddress,nodeTree) {
            var index = fileAddress.shift();
            if (fileAddress.length > 0) {
                return fetchFilesFromTree(fileAddress,nodeTree[index].nodes);
            } else {
                return nodeTree[index];
            }
        }

        const getHashFileIds = function(nodeAddress, nodeTree) {
            var index = nodeAddress.shift();
            index = parseInt(index);
            if(nodeAddress.length > 0) {
                return getHashFileIds(nodeAddress,nodeTree[index].nodes);
            } else {
                return nodeTree[index].fileId;
            }
        }
        
        //---- delete files in tree -----------------------------------------
        export const deleteFilesFromTree = function(fromAddressId,deletedFileIds,nodeTree) {
            //get hash fileIds of the deletedFiles
            var hashFileIds = [];
            deletedFileIds.forEach(nodeId => {
                nodeId=Number.isNaN(nodeId) ? nodeId : nodeId.toString();
                var nodeAddress=nodeId.split('-');
                hashFileIds.push(getHashFileIds(nodeAddress,nodeTree));
            });

            if(fromAddressId == ":root") {
                hashFileIds.forEach(fileId => {
                    //deleting the node
                    let pos = nodeTree.findIndex(node => node.fileId == fileId);
                    nodeTree.splice(pos,1);
                 });
                 //update nodeIds under the folder
                let index = 0;
                nodeTree.forEach(node => {
                    if(node.type == 'File') {
                        node.id=index;
                    }
                    index++;
                });
            } else {
                var fromAddress = fromAddressId.split('-');
                var fromAddressForUpdate = [...fromAddress];
                //delete the files using hash fileId
                hashFileIds.forEach(fileId => {
                   var nav_address = [...fromAddress];
                   deleteFileFromTree(nav_address, fileId, nodeTree); 
                });
                //update nodeIds under the folder
                updateNodeIdsInTree(fromAddressForUpdate,fromAddressId,nodeTree);
            }
        }

        const deleteFileFromTree = function(fromAddress, fileId, nodeTree) {
            var index = fromAddress.shift();
            if(fromAddress.length > 0) {
                deleteFileFromTree(fromAddress,fileId, nodeTree[index].nodes);
            } else {
                //deleting the node
                let pos = nodeTree[index].nodes.findIndex(node => node.fileId == fileId);
                nodeTree[index].nodes.splice(pos,1);
            }
        }

        const updateNodeIdsInTree = function(fromAddress, fromAddressPrefix, nodeTree) {
            var index = fromAddress.shift();
            if (fromAddress.length > 0) {
                updateNodeIdsInTree(fromAddress, fromAddressPrefix, nodeTree[index].nodes);
            } else {
                let pos=0;
                nodeTree[index].nodes.forEach(node => {
                   if(node.type == 'File') {
                       node.id = fromAddressPrefix + '-' + pos;
                   }
                   pos++;
                });
            }
        }
        
        //----- copy files in tree ------------------------------------------
        export const copyFilesInTree = function(existingFilesStartIndex,toAddressPrefix,toAddress,selectedFiles,nodeTree){
            if(toAddress == ":root") {
                //update nodelist and Ids of existing files;
                let pos = existingFilesStartIndex;
                for(let k=0;k<selectedFiles.length;k++) {
                    nodeTree.splice(pos,0,selectedFiles[k]);
                    pos++;
                }
                //nodeTree.unshift(...selectedFiles)
                for(let i=existingFilesStartIndex;i<nodeTree.length;i++) {
                  //let j=i + selectedFiles.length;
                  nodeTree[i].id=toAddressPrefix + i;  
                }
                return;
            }
            var index = toAddress.shift();
            if (toAddress.length > 0) {
                copyFilesInTree(existingFilesStartIndex,toAddressPrefix,toAddress,selectedFiles,nodeTree[index].nodes);
            } else {
                //update nodelist and Ids of existing files;
                let pos = existingFilesStartIndex;
                for(let k=0;k<selectedFiles.length;k++) {
                    nodeTree[index].nodes.splice(pos,0,selectedFiles[k]);
                    pos++;
                }
                //nodeTree[index].nodes.unshift(...selectedFiles);
                for(let i=existingFilesStartIndex;i<nodeTree[index].nodes.length;i++) {
                  //let j=i + selectedFiles.length;
                  nodeTree[index].nodes[i].id=toAddressPrefix + i;  
                }
                return;
            }
        }

        //------- toggle nodeproperty in tree ------------------------
        export const toggleNodeProperties = function(folderAddress,nodeProperty,nodeTree) {
            var index = folderAddress.shift();
            if(folderAddress.length > 0) {
                toggleNodeProperties(folderAddress,nodeProperty,nodeTree[index].nodes);
            } else {
                let nodeObj = {};
                nodeObj[nodeProperty] = !nodeTree[index][nodeProperty];
                Object.assign(nodeTree[index],nodeObj);
            }
        }

        //------- turn off display Tag --------------------------------
        export const turnOffTag = function(folderAddress,nodeTree) {
            var index = folderAddress.shift();
            if(folderAddress.length > 0) {
                turnOffTag(folderAddress,nodeTree[index].nodes);
            } else {
                nodeTree[index].displayTag = false;
            }
        }

        //------ update node properties in tree------------------------------
        export const updateNodeProperties = function(folderAddress,nodeProperty,propertyValue,nodeTree) {
            var index = folderAddress.shift();
            if(folderAddress.length > 0) {
                updateNodeProperties(folderAddress,nodeProperty,propertyValue,nodeTree[index].nodes);
            } else {
                var nodeObj = {};
                nodeObj[nodeProperty] = propertyValue;
                Object.assign(nodeTree[index],nodeObj);
            }
        }

        //------ get only modified files ---------------------------------------
        export const getRecentlyModifiedFiles = function(lastSavedFiles,newlyUpdatedFiles) {
            var orderedUpdatedFiles = [];
            lastSavedFiles.forEach(fileObj => {
              var orderedFileObj = newlyUpdatedFiles.find(updatedFile => updatedFile.fileId === fileObj.fileId);
              if (orderedFileObj !== undefined) {
                orderedUpdatedFiles.push(orderedFileObj);
              }
            });
            var index = 0;
            var modifiedFiles = [];
            orderedUpdatedFiles.forEach(fileObj => {
              if ((fileObj.path !== lastSavedFiles[index].path) ||
              (fileObj.lastUpdatedOn !== lastSavedFiles[index].lastUpdatedOn) ||
              (fileObj.name !== lastSavedFiles[index].name)) {
                modifiedFiles.push(fileObj);
              }
              index++;
            });
            return modifiedFiles;
          }

        //------ find the difference in files ----------------------------------
        // A comparer used to determine if two entries are equal.

export const getOnlyRecentlyUploadedFiles = function(existingFiles,recentList) {
    const isSameFiles = (existingFiles, recentList) => existingFiles.fileId === recentList.fileId;
    // Get items that only occur in the left array,
    // using the compareFunction to determine equality.
    const onlyInLeft = (left, right, compareFunction) => 
      left.filter(leftValue =>
        !right.some(rightValue => 
          compareFunction(leftValue, rightValue)));
    
    const onlyInA = onlyInLeft(existingFiles, recentList, isSameFiles);
    const onlyInB = onlyInLeft(recentList, existingFiles, isSameFiles);
    
    const result = [...onlyInA, ...onlyInB];
    
    return result;
}

///----***** for testing
export const getFolder = function(id,nodeTree) {
    var address=id.split('-');
    return getNodeId(address,nodeTree);
}

const getNodeId = function(address,nodeTree) {
    var index = address.shift();
    if(address.length>0) {
        getNodeId(address,nodeTree[index].nodes);
    } else {
        return nodeTree[index];
    }
}

//---- Return  of the folderId-----------------
export const getNodeProperty = function(nodeId,nodeTree,property) {
    if(nodeId == ":root") {
        return null;
    } else {
        var address = nodeId.split('-');
        
        return getPropertyFromTree(address,nodeTree,null,property);
    }
}

const getPropertyFromTree = function(address,nodeTree,parent,property) {
    var index = address.shift();
    if(address.length>0) {
        return getPropertyFromTree(address,nodeTree[index].nodes,nodeTree[index],property);
    } else {
        if (nodeTree[index].type == "File") {
            if(property == 'path') {
                if(parent == null) return "null";
                else return parent.path;
            } else {
                return nodeTree[index][property];
            }
        } else {
            return nodeTree[index][property];
        }
    }
}

//reset open folders
export const resetOpenFolders = function(nodeTree) {
    nodeTree.forEach(node=> {
        if(node.type == 'Folder') {
            node.isActive = false;
            resetOpenFolders(node.nodes);
        }
    });
}
//set all file nodes attributes

export const setTwoAttributeOfAllFiles = function(nodeTree,childAdressId,
    firstpropertyName,firstpropertyValue,secondpropertyName,secondpropertyValue) {
    let address = [];
    if(childAdressId.includes('-')) {
        address = childAdressId.split('-');
        address.splice(-1,1);
        return setFileAttribute(address,nodeTree,firstpropertyName,firstpropertyValue,secondpropertyName,secondpropertyValue);
    } else {
        let fileCount=0;
        nodeTree.forEach(nodeObj => {
            if(nodeObj.type == 'File') {
                nodeObj[firstpropertyName] = firstpropertyValue;
                nodeObj[secondpropertyName] = secondpropertyValue;
                fileCount++;
            }
        });
        return fileCount;
    }
}

const setFileAttribute = function(address,nodeTree,firstpropertyName,firstpropertyValue,secondpropertyName,secondpropertyValue) {
    let index = address.shift();
    if (address.length > 0) {
        return setFileAttribute(address,nodeTree[index].nodes,firstpropertyName,firstpropertyValue,secondpropertyName,secondpropertyValue);
    } else {
        let fileCount=0;
        nodeTree[index].nodes.forEach(nodeObj => {
            if(nodeObj.type == 'File') {
                nodeObj[firstpropertyName] = firstpropertyValue;
                nodeObj[secondpropertyName] = secondpropertyValue;
                fileCount++;
            }
        });
        return fileCount;
    }
}

//get list of selected fileIds
export const getSelectedFileIds = function(nodeTree,currFileId) {
    let address = [];
    if(currFileId.includes('-')) {
        address = currFileId.split('-');
        address.splice(-1,1);
        return getSelectedFileIdsFromTree(address,nodeTree);
    } else {
        let filteredNodes = [];
        nodeTree.forEach(nodeObj => {
            if(nodeObj.type == 'File' && nodeObj.isFileSelected) {
                filteredNodes.push(nodeObj.id);
            }
        });
        return filteredNodes;
    }
}

const getSelectedFileIdsFromTree = function(address,nodeTree) {
    let index = address.shift();
    if (address.length > 0) {
        return getSelectedFileIdsFromTree(address,nodeTree[index].nodes);
    } else {
        let filteredNodes = [];
        nodeTree[index].nodes.forEach(nodeObj => {
            if(nodeObj.type == 'File' && nodeObj.isFileSelected) {
                filteredNodes.push(nodeObj.id);
            }
        });
        return filteredNodes;
    }
}

//get fileId
export const getFileId = function(nodeId,nodeTree) {
    let address = [];
    if(!Number.isInteger(nodeId)) {
        address = nodeId.split('-');
        return getFileIdFromTree(address,nodeTree);
    } else {
        return nodeTree[nodeId].fileId;
    }
}

const getFileIdFromTree = function(address,nodeTree) {
    var index = address.shift();
    if(address.length > 0) {
        return getFileIdFromTree(address,nodeTree[index].nodes)
    } else {
        return nodeTree[index].fileId;
    }
}

//get path
export const getNodePath = function(nodeId,nodeTree) {
    let address = [];
    if(!Number.isInteger(nodeId)) {
        address = nodeId.split('-');
        return getNodePathFromTree(address,nodeTree);
    } else {
        return nodeTree[nodeId].path;
    }
}

const getNodePathFromTree = function(address,nodeTree) {
    var index = address.shift();
    if(address.length > 0) {
        return getNodePathFromTree(address,nodeTree[index].nodes)
    } else {
        return nodeTree[index].path;
    }
}

export const getAllFileIdsFromFolder = function(fileId,nodeTree) {
    fileId = Number.isNaN(fileId) ? fileId : fileId.toString();
    if(fileId.includes('-')) {
        let fileAddress=fileId.split('-');
        let sourcefolderAddr=[fileAddress[0]];
        for(let i=1;i<(fileAddress.length - 1);i++) {
            sourcefolderAddr.push(fileAddress[i]);
        }
        return getFileIdsList(sourcefolderAddr, nodeTree);
    } else {
        //it is root
        let fileIds = [];
        nodeTree.forEach(node => {
            if(node.type == 'File') {
                fileIds.push(node.id);
            }
        });
        return fileIds;
    }
}

const getFileIdsList = function(folderAddr, nodeTree) {
    var index = folderAddr.shift();
    if(folderAddr.length > 0) {
        return getFileIdsList(folderAddr,nodeTree[index].nodes);
    } else {
        let fileIds = [];
        nodeTree[index].nodes.forEach(node => {
            if(node.type == 'File') {
                fileIds.push(node.id);
            }
        });
        return fileIds;
    }
}

export const updateFolderStatusInTree = function(nodeTree, folderRegister) {
    for (let path in folderRegister) {
        if (Object.prototype.hasOwnProperty.call(folderRegister,path)) {
            var folderObj = folderRegister[path];
            var address = folderObj.id.split('-');
            updateNodeProperties(address,'isActive',folderObj.isActive,nodeTree);
        }
    }
}