<template>
  <!-- Main -->
  <section class="main">
    <section class="urla-pending-applications">
      <!-- Moon Loader -->
      <moon-loader class="moon-loader" v-bind:loading="isLoading"
        :loaderMessage="loaderMessage"></moon-loader>

      <!-- Pending Applications message -->
      <h2 v-if="resultsLoanArray">
        Hi
        {{
        resultsLoanArray[0].primaryBorrowerBriefInfo.firstName != null
        ? resultsLoanArray[0].primaryBorrowerBriefInfo.firstName
        : null
        }},
      </h2>
      <p>
        We have found the below pending loan applications:
      </p>
      <div>
        <ul>
          <li v-for="result in resultsLoanArray" :key="result.loanId">
            <div class="loan-property">
              <p>
                {{
                result.primaryBorrowerBriefInfo.firstName != null
                ? result.primaryBorrowerBriefInfo.firstName
                : "N/A"
                }}
                {{
                result.primaryBorrowerBriefInfo.lastName != null
                ? result.primaryBorrowerBriefInfo.lastName
                : "N/A"
                }}
              </p>
              <p>{{ isoToLocalDateTime(result.createdDateTime) }}</p>
            </div>
            <div class="loan-property">
              <p>
                {{ result.loanPurpose != null ? result.loanPurpose : "N/A" }}
              </p>
              <p>
                {{ result.loanProgram != null ? result.loanProgram : "N/A" }}
              </p>
            </div>
            <div v-if="result.propertyAddress" class="loan-property">
              <p>
                {{
                result.propertyAddress.unit != null
                ? result.propertyAddress.unit
                : "N/A"
                }}
                {{
                result.propertyAddress.street != null
                ? result.propertyAddress.street
                : "N/A"
                }},
              </p>
              <p>
                {{
                result.propertyAddress.city != null
                ? result.propertyAddress.city
                : "N/A"
                }}
                {{
                result.propertyAddress.state != null
                ? result.propertyAddress.state
                : "N/A"
                }}
                {{
                result.propertyAddress.zip != null
                ? result.propertyAddress.zip
                : "N/A"
                }}
              </p>
            </div>
            <div class="loan-property">
              <p>{{ result.loanAmount != null ? result.loanAmount : "N/A" }}</p>
              <p>
                <span v-for="index in 8" :key="index">
                  <span class="progress-bar" v-if="index != 6" v-bind:class="{
                      progressActive:
                        index <= result.loanApplicationProgress / 100,
                    }"></span>
                </span>
              </p>
            </div>
            <div class="loan-property">
              <div v-bind:class="
                  result.loanStatus == 'PARTIAL' ||
                  result.loanStatus == 'FLOATED' ||
                  result.loanStatus == 'STARTED'
                    ? null
                    : 'notActive'
                ">
                <p class="edit-application edit-hover" @click="
                    getCompleteUrlaAndRedirectToUrlaPage(
                      result.customerId,
                      result.loanId
                    )
                  ">
                  <i class="fa fa-edit"></i>
                  <span class="edit-hover-text"> Edit</span>
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div class="button-container">
          <button class="primary" v-on:click="createNewUrlaAndRedirectToUrlaPage">
            Create New
          </button>
        </div>
      </div>
    </section>
    <p class="contact-info">
      Please do not hesitate to reach us at
      <a href="tel:469-525-6725">+1(469)525-6725</a> or
      <a href="mailto:kc@texsmartlending.com">kc@texsmartlending.com</a>
      in case you need to reach us before the end of the day.
    </p>
    <pop-up v-bind:status="statusResponse" v-bind:statusMessage="statusMessage"></pop-up>
  </section>
</template>

<script>
import jsonUtilMixin from "@/mixins/jsonUtilMixin.js";
import urlaMixin from "@/mixins/urlaMixin.js";
import PopUp from "@/components/elements/popUp.vue";
import authenticationMixin from "@/mixins/authenticationMixin.js"
import MoonLoader from "@/components/elements/MoonLoader.vue";

export default {
  // Component Name
  name: "PendingApplications",
  mixins: [jsonUtilMixin, urlaMixin],
  components: {
    "pop-up": PopUp,
    "moon-loader": MoonLoader,
  },
  data() {
    return {
      customerId: null,
      isLoading: false,
      resultsLoanArray: null,
      statusResponse: false,
      statusMessage: null,
      loaderMessage: "Loading"
    };
  },
  methods: {
    getCompleteUrlaAndRedirectToUrlaPage: async function(customerId, loanId) {
      this.isLoading = true;
      let getCompleteUrlaResponse = await this.callUrlasAPIAndGetUrlaById(
        customerId,
        loanId
      ).catch(() => {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      });
      if (
        getCompleteUrlaResponse && (getCompleteUrlaResponse.status !== 200 ||
        getCompleteUrlaResponse.data == null)
      ) {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      }
      this.isLoading = false;
      this.redirectToUrla(customerId, loanId, getCompleteUrlaResponse.data);
    },

    callDeleteMethodFromUrlaMixin: async function(customerId, LoanId) {
      if (authenticationMixin.methods.isLoggedIn() == true) {
        let confirmation = confirm(
          "Are you sure you want to delete this loan application?"
        );
        if (confirmation == true) {
          let response = await this.deleteUrlaLoanApplication(
            customerId,
            LoanId
          ).catch(() => {
            this.redirectToErrorPage();
            return;
          });
          if (response.status === 200 || response.status === 204) {
            this.getAllUrlasPendingApplications(this.customerId);
          }
        }
      } else {
        alert("Session Out");
        this.$router.push({
          name: "new-or-continue",
        });
      }
    },

    getAllUrlasPendingApplications: async function(customerId) {
      this.isLoading = true;
      let getUrlasForCustomerResponse = await this.callUrlasAPIAndGetAllCustomerUrlas(
        customerId
      ).catch(() => {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      });
      this.resultsLoanArray = getUrlasForCustomerResponse.data.results;
      this.isLoading = false;
    },

    redirectToErrorPage: function() {
      const ERROR_MESSAGE = "Unexpected Error";
      this.$router.push({
        name: "error",
        params: { message: ERROR_MESSAGE },
      });
    },

    createNewUrlaAndRedirectToUrlaPage: async function() {
      let createUrlaResponse = await this.callUrlasAPIAndCreateUrla(
        this.$route.query.customerId
      ).catch(() => {
        this.redirectToErrorPage();
        return;
      });
      this.popUpActive(createUrlaResponse);
      if (
        !(
          createUrlaResponse && createUrlaResponse.status && (createUrlaResponse.status === 201 || createUrlaResponse.status === 200)
        )
      ) {
        this.redirectToErrorPage();
        return;
      }
      this.getCompleteUrlaAndRedirectToUrlaPage(
        createUrlaResponse.data.customerId,
        createUrlaResponse.data.loanId
      );
    },

    popUpActive: function(response) {
      this.statusResponse = !this.statusResponse;
      this.statusMessage = "Creating New Loan Application...";
      setTimeout(() => (this.statusResponse = false), 2000);
      if (response.status === 404) {
        this.statusMessage = "File Not Found ?";
        return;
      } else if (response.status === 500) {
        this.statusMessage = "Internal Server Error, please try again later";
        return;
      }
    },
  },
  created: function() {
    this.customerId = this.$route.query.customerId;
    this.getAllUrlasPendingApplications(this.customerId);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding-left: 0px;
  margin: 15px auto 0px auto;
}
ul li {
  border-radius: 3px;
  border: 1px solid #bcbaba;
  margin-bottom: 10px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}
ul li .loan-property {
  margin-bottom: 3px;
  font-size: 14px;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

ul li .loan-property p:nth-child(2) {
  font-size: 12px;
}

ul li .loan-property:last-child {
  flex-direction: row;
  justify-content: space-between;
  width: 70px;
}

ul li .loan-property:last-child div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

ul li p span {
  font-weight: bold;
}

.urla-pending-applications {
  max-width: 1000px;
  margin: 0 auto;
  min-height: calc(100vh - 250px);
}

.urla-pending-applications p {
  font-size: 15px;
  line-height: 20px;
  margin: 0px 0px 3px 0px;
}

/* styles for field names and values */
.field,
.field-value {
  display: inline-block;
  width: 200px;
  box-sizing: border-box;
}

.field {
  font-weight: bold;
}
.notActive {
  pointer-events: none;
}
.button-container {
  margin: 0 auto;
  text-align: right;
  margin-bottom: 20px;
}
.edit-application,
.delete-application {
  padding: 3px 6px;
  border-radius: 19px;
  font-size: 15px;
  box-shadow: 0px 1px 5px #a9a9a9;
  background-color: #2ebfac;
}
.edit-application:hover,
.delete-application:hover {
  cursor: pointer;
}

.delete-application {
  padding: 2px 8px;
  font-size: 17px;
  font-weight: bold;
}

.edit-hover {
  position: relative;
  display: inline-block;
  color: #fefefe !important;
}

.edit-hover .edit-hover-text {
  visibility: hidden;
  background-color: #434343;
  color: #fff;
  border-radius: 2px;
  font-size: 11px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  line-height: 10px;
  top: 30px;
  left: 6px;
  font-weight: 100;
}

.edit-hover:hover .edit-hover-text {
  visibility: visible;
}

.contact-info {
  margin: 0px auto;
  text-align: center;
  width: 80%;
  padding: 0 3em 0 3em;
  max-width: 1000px;
}
.progressActive {
  background-color: #2ebfac !important;
}
.progress-bar {
  display: inline-block;
  width: 8px;
  height: 3px;
  margin-right: 2px;
  background-color: #c3c3c3;
  border-radius: 20px;
}
</style>
