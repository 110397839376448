<template>
  <div class="new-or-continue">
          <!-- Moon Loader -->
          <moon-loader
            v-bind:loading="isLoading"
            :color="loaderColor"
            :size="loaderSize"
            v-bind:loaderMessage="loaderMessage"
          ></moon-loader>
  </div>
</template>

<script>
import "vue-date-pick/dist/vueDatePick.css";
import MoonLoader from "@/components/elements/MoonLoader.vue";
import validationsMixin from "@/mixins/validationsMixin.js";
import inputMasksMixin from "@/mixins/inputMasksMixin.js";
import urlaMixin from "@/mixins/urlaMixin.js";
import { store } from "./../../../store/store";

export default {
  // Component Name
  name: "NewOrContinue",
  // mixins used in this component
  mixins: [validationsMixin, urlaMixin, inputMasksMixin],
  // components used
  components: {
    "moon-loader": MoonLoader
  },
  // data used in component
  data() {
    return {
      loaderSize: "100px",
      loaderColor: "#2ebfac",
      loaderMessage: "Loading",
      isLoading: false,
    };
  },

  computed: {
  },

  methods: {
    init: async function() {
      this.customerId = store.getters.getUserUuid;
      if(this.customerId == null) {
        //wait for useruuid to load
        for(let attempt=1;attempt<3;attempt++) {
          if(this.customerId == null) {
            await new Promise(resolve => {setTimeout(resolve, 2000)});
            this.customerId = store.getters.getUserUuid;
          } else {
            this.getApplications();
            break;
          }
        }
      } else {
        this.getApplications();
      }
    },

    async getApplications() {
      this.isLoading = true;
      let getUrlasForCustomerResponse = await this.callUrlasAPIAndGetAllCustomerUrlas(
        this.customerId
      ).catch(() => {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      }); 

      if (getUrlasForCustomerResponse && getUrlasForCustomerResponse.status == 404) {
        this.isLoading = false;
        this.createNewUrla(this.customerId);
      } else if (getUrlasForCustomerResponse && getUrlasForCustomerResponse.status == 200 &&
        this.findPendingUrlas(getUrlasForCustomerResponse.data.results).length > 0) {
        this.isLoading = false;
        this.redirectToPendingApplication(
          getUrlasForCustomerResponse.data.results[0].customerId
        );
        return;
      } else {
          this.isLoading = true;
          return;
      }
    },

    async startNewApplication() {
      if (!this.validateNewApplicationForm()) {
        return;
      }
      // set moon loader
      this.isLoading = true;
      let createCustomerResponse = await this.callCustomersAPIAndCreateCustomer(
        this.newApplication
      ).catch(() => {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      });
      if (
        createCustomerResponse.status !== 201 ||
        createCustomerResponse.data === undefined
      ) {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      }
      let getUrlasForCustomerResponse = await this.callUrlasAPIAndGetAllCustomerUrlas(
        createCustomerResponse.data.customerId
      ).catch(() => {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      });
      if (
        !(
          getUrlasForCustomerResponse.status === 404 ||
          getUrlasForCustomerResponse.status === 200
        )
      ) {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      }
      if (
        getUrlasForCustomerResponse.status === 200 &&
        this.findPendingUrlas(getUrlasForCustomerResponse.data.results).length >
          0
      ) {
        this.isLoading = false;
        this.redirectToPendingApplication(
          getUrlasForCustomerResponse.data.results[0].customerId
        );
        return;
      }
      this.createNewUrla(createCustomerResponse.data.customerId);
    },

    createNewUrla: async function(responseCustomerId) {
      let createUrlaResponse = await this.callUrlasAPIAndCreateUrla(
        responseCustomerId
      ).catch(() => {
        this.isLoading = false;
        this.redirectToErrorPage();
        return;
      });
      this.isLoading = false;
      if (
        !(
          createUrlaResponse?.status === 201 || createUrlaResponse?.status === 200
        )
      ) {
        this.redirectToErrorPage();
        return;
      }
      this.redirectToUrla(
        createUrlaResponse.data.customerId,
        createUrlaResponse.data.loanId,
        createUrlaResponse.data
      );
    },

    findPendingUrlas: function(urlas) {
      if (null == urlas) {
        return [];
      }
      return urlas.filter(
        (loan) => loan.loanStatus === "PARTIAL" || loan.loanStatus === "STARTED" || loan.loanStatus === "FLOATED"
      );
    },

    redirectToPendingApplication: function(customerId) {
      this.$router.push({
        name: "pending-applications",
        query: { customerId: customerId },
      });
    },
  },

  mounted: function() {
    // call the init function on mounting the component
    this.init();
  },
};
</script>

<style>
/* font styles for form elements */
.new-or-continue form p,
.new-or-continue form legend,
.new-or-continue form label {
  font: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  margin: 0 0 2% 0;
  color: #464646;
}

.new-or-continue form {
  width: 510px;
  margin: 5% auto;
  min-width: 300px;
  padding: 2rem;
}

/* border styles for forms*/
.new-or-continue form {
  background: white;
  -moz-border-radius: 15px; /*for mozilla support*/
  -webkit-border-radius: 15px; /*for chrome support*/
  border-radius: 15px;
}

/* shadow styles for form */
.new-or-continue form {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}

/* remove borders and shadows for mobile view */
@media all and (max-width: 500px) {
  .new-or-continue form {
    border-radius: 0;
    -moz-border-radius: 0; /*for mozilla support*/
    -webkit-border-radius: 0; /*for chrome support*/
    box-shadow: none;
    margin: auto;
    width: auto;
    padding: auto;
  }

  .new-or-continue .left-section,
  .new-or-continue .right-section {
    padding: 0;
  }
}

/* style for Required Asterisk */
.new-or-continue .required,
.new-or-continue .instruction,
.new-or-continue .error {
  text-decoration: none;
  /*color: #bf4d2e;*/
  color: red;
  margin: 0;
  padding: 0;
}

/* style for input and labels */
.new-or-continue input,
.new-or-continue label,
.new-or-continue .empty-label {
  display: inline-block;
  width: 200px;
  box-sizing: border-box;
}

/* hide arrows for date in chrome browser */
.new-or-continue input[type="date"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

/* styles for disabled input[type="tel"] */
.new-or-continue input[type="tel"]:disabled {
  color: #999 !important;
  -webkit-text-fill-color: #999 !important;
}

/* style for Same as above */
.new-or-continue .same-as-above {
  display: block;
}

/* font-size for all children of same-as-above*/
.new-or-continue .same-as-above * {
  font-size: 13px;
}

/* styles for description of SSN */
.ssn-description {
  font-size: 13px;
  position: absolute;
}

/* width for list elements in checkbox */
.new-or-continue input[type="checkbox"] {
  width: auto;
}

/* shake transition for error*/
.shake-enter-active,
.shake-leave-active {
  /*transition: opacity 0.5s;*/
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.shake-enter, .shake-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* styles for button */
.new-or-continue .primary {
  font-size: 14px;
  font-weight: bold;
  margin-top: 5%;
  margin-left: 5%;
}
</style>
