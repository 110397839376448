<template>
  <div>
    <section class="main row">
      <div class="left-section col-5 col-12-medium">
        <div>
          <!-- Moon Loader -->
          <moon-loader
            class="moon-loader"
            v-bind:loading="isLoading"
            :color="loaderColor"
            :size="loaderSize"
            :loaderMessage="loaderMessage"
          ></moon-loader>
          <div class="file-explorer">
            <folderview-panel
            :isInitialLoadingDone="isInitialLoadingDone"
            :isFilesUploaded="isFilesUploaded"
            :isLoading="isLoading"
            :uploadedFilesList="uploadedFilesList"
            :existingFiles="existingFiles"
             @file-selected="displayFilePreview"
            />
          </div>
        </div>
      </div>

      <div class="right-section col-7 col-12-medium">
        <div class="tabs-bar" v-if="!isHandHeldDevice">
          <div class="tab-selection">
            <div class="tabs" id="uploaded-documents"
            v-on:click="tabChangeClick"
            :class="isActiveTab ? 'show__tab' : 'hide__tab'"> 
              Preview File
            </div>
            <div class="tabs" id="upload-new"
            v-on:click="tabChangeClick"
            :class="!isActiveTab ? 'show__tab' : 'hide__tab'">
              Upload Files
            </div>
          </div>
          <hr class="tabs-seperation">
        </div>

        <!-- File Previewe Tab -->
        <div id="file-previewer-tab" v-if="isActiveTab">
          <p v-if="!isFilesLoaded" class="preview-message">
            There are no files to preview
          </p>
          <p class="preview-message"
          v-else-if="imageLink == null && src == null && isFilesLoaded || tabSelected == 'Folder'">
            Click on a file from left to preview
          </p>

          <!-- loader until pdf file loads -->
          <moon-loader
            v-if="tabSelected == 'File'"
            class="moon-loader-pdf"
            v-bind:loading="isLoadingPdf"
            :color="loaderColor"
            :size="loaderSize"
            :loaderMessage="loaderMessagePdf"
          ></moon-loader>
          <!-- loader until pdf file loads ends -->

          <p class="preview-message"
           v-if="statusPdf404 == true && statusPdfNot200 == false && tabSelected == 'File'">
            File Not Found
          </p>
          <p class="preview-message"
           v-if="statusPdf404 == false && statusPdfNot200 == true && tabSelected == 'File'">
            Unable to load the file
          </p>
          <div v-if="tabSelected == 'File'">
            <div class="pdf-viewer" v-if="imageLink == null && currentFileFormat=='application/pdf'">
              <iframe
                :src="src"
                embedded="true"
                class="google-pdf-viewer"
              ></iframe>
            </div>
            <div v-else-if="isFileImage" class="img-viewer">
              <img-viewer
              :isLoading="isLoading"
              :imageUrl="src"
              :fileName="fileNamePdf"
              ></img-viewer>
            </div>
            <div v-else
            >
            </div>
          </div>
        </div>

        <!-- File Uploader Tab -->

        <div v-if="!isActiveTab" :class="{ activeSection: !isActiveTab }" class="new-file-upload">

        <fieldset v-bind:disabled="!isFileUploadFormEnabled">
          <form
            id="file-upload"
            method="post"
            v-on:submit.prevent="createLinkAndUploadFiles"
            v-cloak
            @drop.prevent="addDroppedFiles"
            @dragover.prevent
          >
            <div class="drop-zone-wrapper">
              <div class="upload">
                <div :class="{ shake: showWarning }" class="select-folder-message">
                {{  folderMessage }}
                </div>
                <label for="drop-zone">
                  Drag files here or
                  <span @click="displayWarning" class="browse__button">Browse</span>
                </label>

                <div>
                  <input
                    hidden
                    id="drop-zone"
                    multiple
                    type="file"
                    class="input-file"
                    ref="fileInput"
                    :disabled="currentPath==null"
                    @change.prevent="addSelectedFiles"
                  />
                </div>
              </div>
              <div class="max-size-instruction">
                <p>Max. file size: 10MB</p>
              </div>
              <!-- Error message invalid file-->
              <div class="wrap-error">
                <transition name="shake">
                  <p class="error" v-show="isUnsupportedFileAdded">
                    Please remove unsupported file(s)
                  </p>
                </transition>
              </div>

              <!-- summary -->
              <div class="summary-wrapper">
                <div
                  class="selected-file-wrapper"
                  v-for="(fileWrapper, index) in filesToUpload"
                  :key="index"
                >
                  <i class="fa fa-file"></i>
                  <div
                    class="file"
                    v-bind:class="
                      fileWrapper.isValid ? 'valid-file' : 'invalid-file'
                    "
                  >
                    <div class="selected-file-info">
                      <p class="file-name-str">{{ fileWrapper.name }}</p>
                      <p class="file-name-size">
                        {{ bytesToSize(fileWrapper.size) }}
                      </p>
                      <i
                        v-if="!fileWrapper.isUploaded"
                        @click.prevent="removeFile(index)"
                        class="fa fa-times"
                      ></i>
                    </div>
                      <p class="file-name-path">{{ showUploadMessage(fileWrapper) }}</p>
                    <div class="progress file-upload-progress">
                      <div
                        class="progress-bar progress-bar-animated"
                        v-bind:style="{ width: fileWrapper.uploadStatus }"
                        v-bind:class="[
                          fileWrapper.isUploadFailing
                            ? 'bg-failure'
                            : 'bg-success',
                          fileWrapper.isUploaded ? '' : 'progress-bar-striped',
                        ]"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-upload100-form-btn">
                <button
                  type="submit"
                  class="upload100-form-btn"
                  v-bind:disabled="
                    isUnsupportedFileAdded || isDisableUploadButton
                  "
                >
                  Upload
                  <i class="fa fa-cloud-upload fa-lg" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </form>
        </fieldset>

        </div>

      </div>

    </section>
  </div>
</template>

<script>
import MoonLoader from "@/components/elements/MoonLoader.vue";
import inputMasksMixin from "@/mixins/inputMasksMixin.js";
import validationsMixin from "@/mixins/validationsMixin.js";
import fileUploadMixin from "@/mixins/fileUploadMixin.js";
import { store } from "./../../store/store";
import FolderviewPanel from "./../elements/fileExplorer/FolderviewPanel.vue";
import ImageViewer from "./../elements/ImageViewer.vue";

export default {
  name: "Upload",
  mixins: [validationsMixin, fileUploadMixin, inputMasksMixin],
  components: {
    "moon-loader": MoonLoader,
    "folderview-panel": FolderviewPanel,
    "img-viewer": ImageViewer,
  },
  data() {
    return {
      loaderSize: "100px",
      loaderColor: "#2ebfac",
      isLoading: false,
      loaderMessage: "Searching",
      isUnsupportedFileAdded: false,
      isFailedToLoadCustomerFiles: false,
      isCustomerFound: true,
      isInitialLoadingDone: false,
      isFileUploadFormEnabled: false,
      isDisableDeleteFileButton: false,
      isDisableUploadButton: false,
      isFilesUploaded: false,
      isFilesLoaded:false,
      filesToUpload: [],
      uploadedFilesList: [],
      existingFiles: [],
      loggedInUser: null,
      customerId: null,
      currentPath: null,
      showWarning: false,
      isActiveTab: false,
      src: null,
      imageLink: null,
      isLoadingPdf: false,
      loaderMessagePdf: "Loading File..",
      fileNamePdf: null,
      statusPdf404: false,
      statusPdfNot200: false,
      isHandHeldDevice: false,
      tabSelected: "Folder",
      isFileImage: false,
      currentFileFormat: null,
    };
  },
  computed: {
    folderMessage() {
      if(this.currentPath == null ) {
        return "*Select a Folder from the " + this.foldersPosition + " before adding files";
      } else {
        return "Adding files to : " + this.currentPath;
      }
    },
    foldersPosition() {
      if(window.innerWidth < 980) {
        return "top";
      } else return "left";
    },
    stackedFilesList() {
      return [...this.filesToUpload].reverse();
    }
  },
  methods: {

    tabChangeClick: function(e) {
      if (e.target.id == "uploaded-documents") {
        this.isActiveTab = true;
      } else {
        this.isActiveTab = false;
      }
    },

    displayFilePreview: async function(fileInfo) {
      this.isFileImage=false;
      this.isLoadingPdf = true;
      this.src = "";
      this.fileNamePdf = fileInfo.filename;
      this.imageLink = null;
      let response = await this.downloadCustomerFile(fileInfo.url);
      this.isLoadingPdf = false;
      if (fileInfo.fileFormat === "application/pdf" || fileInfo.fileFormat === "image/png" || fileInfo.fileFormat === "image/jpeg") {
        this.responseErrorAlert(response);
        var blob = new Blob([response.data], {
          type: fileInfo.fileFormat,
        });
        this.src = window.URL.createObjectURL(blob);
        this.currentFileFormat=fileInfo.fileFormat;
        this.statusPdf404 = false;
        this.statusPdfNot200 = false;
        if(fileInfo.fileFormat === "image/png" || fileInfo.fileFormat === "image/jpeg") {
          this.isFileImage=true;
        }
      } else {
        this.responseErrorAlert(response);
        this.currentFileFormat=fileInfo.fileFormat;
        this.imageLink = fileInfo.url;
      }
    },

    responseErrorAlert: function(response) {
      if (response.status == 404) {
        alert("404, File Not Found");
        return;
      }
      if (response.status == 500) {
        alert("Internal Server Error, please try again later");
        return;
      }
      if (response.status !== 200) {
        alert("Unexpected error, Please try again later");
        return;
      }
      if (!response.data) {
        alert("Preview file is empty, Please try again later");
        return;
      }
    },

    transitionStyles(fileWrapper) {
      if(!fileWrapper.isUploaded) {
          return {
          width: fileWrapper.uploadStatus,
          transition: 'width 5s ease'
        }
      } else {
          return {
          width: fileWrapper.uploadStatus,
          transition: 'width 0s ease'
          }
      }
    },
    /* find customer methods */
    init: async function() {
      // set default values
      this.isCustomerFound = true;
      this.isFileUploadFormEnabled = false;
      this.loggedInUser = store.getters.loggedInUser;
      this.customerId = store.getters.getUserUuid;
      this.loaderMessage = "Loading";
      this.isLoading = true;
      await this.getExistingCustomerFiles();
      this.isInitialLoadingDone = true;
      if(this.loggedInUser && this.customerId)  {
        this.isFileUploadFormEnabled = true;
      }
    },

    isoToLocalDateTime: function(isoDate) {
      var dateTemp = new Date(isoDate);
      const MONTHS = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return (
        MONTHS[dateTemp.getMonth()] +
        " " +
        dateTemp.getDate() +
        ", " +
        dateTemp.getFullYear() +
        " " +
        dateTemp.toLocaleTimeString()
      );
    },


    bytesToSize(bytes) {
      const SIZES = ["B", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0B";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      if (i == 0) return bytes + " " + SIZES[i];
      return (bytes / Math.pow(1024, i)).toFixed(1) + " " + SIZES[i];
    },

    validateFile(file) {
      const MAX_SIZE_BYTES = 10 * 1024 * 1024;
      const ALLOWEDTYPES = [
        "text/plain",
        "application/pdf",
        "application/msword",
        "application/x-zip-compressed",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/png",
        "image/jpeg",
      ];
      if (file.size > MAX_SIZE_BYTES) {
        return false;
      }
      if (!ALLOWEDTYPES.includes(file.type)) {
        return false;
      }
      return true;
    },

    showUploadMessage: function(fileWrapper) {
      if(fileWrapper.isUploadFailing) {
        return "Upload Failed";
      }
      return ((fileWrapper.isUploaded ? "uploaded to: " : "will be uploaded to: ") + fileWrapper.path);
    },

    async getExistingCustomerFiles() {
      let response = await this.getCustomerFiles(this.customerId).catch(
        (err) => {
          this.$router.push({ name: "error", params: { message: err } });
        }
      );
      if (response && response.status !== 200) {
        this.isFailedToLoadCustomerFiles = true;
      }
      if (
        response.data &&
        response.data.files &&
        response.data.files.length != 0
      ) {
        this.existingFiles = response.data.files;
        this.isFilesLoaded = true;
      }
    },

    validateAndAddFilesToList(filesToAdd) {
      if (!filesToAdd) return;
      [...filesToAdd].forEach((f) => {
        var fileWrapper = {
          name: f.name,
          file: f,
          path: this.currentPath,
          isValid: false,
          uploadLink: null,
          isUploadFailing: false,
          isUploaded: false,
          size: f.size,
          uploadStatus: "0%",
        };
        if (this.validateFile(f)) {
          fileWrapper.isValid = true;
        } else {
          this.isUnsupportedFileAdded = true;
        }
        this.filesToUpload.push(fileWrapper);
      });
    },

    displayWarning() {
      if(this.currentPath == null) {
        this.showWarning=true;
        setTimeout(() => {
          this.showWarning= false;
        }, 1500);
      } else {
        this.showWarning=false;
      }
    },

    /* file-upload methods */
    addSelectedFiles(e) {
      this.validateAndAddFilesToList(e.target.files);
      this.$refs.fileInput.value = null;
    },

    addDroppedFiles(e) {
      if(this.currentPath == null) {
        this.displayWarning();
      } else {
        this.validateAndAddFilesToList(e.dataTransfer.files);
      }
    },

    removeFile(index) {
      this.filesToUpload[index].uploadStatus="0%";
      this.filesToUpload.splice(index,1);
      var hasUnsupportedFilesAfterRemoving = false;
      this.filesToUpload.forEach((fileWrapper) => {
        if (!this.validateFile(fileWrapper.file)) {
          this.isUnsupportedFileAdded = true;
          hasUnsupportedFilesAfterRemoving = true;
        }
      });
      this.isUnsupportedFileAdded = hasUnsupportedFilesAfterRemoving;
    },

    async createUploadLinkForFile(fileWrapper) {
      fileWrapper.uploadStatus = "25%";
      if (fileWrapper.isUploaded) {
        return;
      }
      if (
        null != fileWrapper.uploadLink &&
        undefined !== fileWrapper.uploadLink &&
        "" != fileWrapper.uploadLink
      ) {
        // skip if link is already created (retrying)
        return;
      }
      var fileInfo = {
        name: null,
        description: null,
      };
      fileInfo.name = fileWrapper.name;
      fileInfo.path = fileWrapper.path;
      let response = await this.createFileUploadLink(this.customerId, fileInfo).catch(() => {
        fileWrapper.isUploadFailing = true;
      });
      if (response.status !== 201) {
        fileWrapper.isUploadFailing = true;
      } else {
        fileWrapper.uploadLink = response.data.file.link.href;
        fileWrapper.uploadStatus = "50%";
      }
    },

    async uploadEachFile(fileWrapper) {
      if (null === fileWrapper.uploadLink || "" === fileWrapper.uploadLink) {
        fileWrapper.isUploadFailing = true;
        return;
      }
      fileWrapper.uploadStatus = "75%";
      let response = await this.uploadFile(
        fileWrapper.uploadLink,
        fileWrapper.file
      ).catch(() => {
        fileWrapper.isUploadFailing = true;
      });
      if (response.status !== 200) {
        fileWrapper.isUploadFailing = true;
      } else {
        fileWrapper.uploadStatus = "100%";
        fileWrapper.isUploaded = true;
      }
    },

    async createLinkAndUploadFile(fileWrapper) {
      if (fileWrapper.isUploaded) {
        return;
      }
      fileWrapper.isUploadFailing = false;
      await this.createUploadLinkForFile(fileWrapper);
      await this.uploadEachFile(fileWrapper);
      if (fileWrapper.isUploaded) {
        this.uploadedFilesList.push(fileWrapper);
        if(this.filesToUpload.filter(fileWrapper => { return fileWrapper.isUploaded }).length == this.uploadedFilesList.length) {
          this.isFilesUploaded=true;
        }
      }
    },

    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },

    async createLinkAndUploadFiles() {
      this.isDisableDeleteFileButton = true;
      // TODO: validate files
      // this.isDisableUploadButton = true;
      if (this.isUnsupportedFileAdded) {
        return;
      }
      this.asyncForEach(this.filesToUpload, this.createLinkAndUploadFile);
    },
  },
  mounted() {
    //get window dimensions
    this.isHandHeldDevice=(window.innerWidth / window.innerHeight < 1 ? true: false);
    this.init();
  }
};
</script>

<style scoped>
.main {
  min-height: 80vh;
}

/* @media screen and (min-height: 1280px) {
  .main {
    min-height: 1300px;
  }
} */

/* find customer form styles */

/* main */
/*//////////////////////////////////////////////////////////////////
    [ RESTYLE TAG ]*/
.left-section {
  border-right: dashed #2ebfac 1px;
}

.left-section > div {
  position: sticky;
  top:40px;
  left:10px;
}

.wrap-error {
  min-height: 25px;
  text-align: center;
  padding-bottom: 15px;
}

.error {
  text-decoration: none;
  /*color: #bf4d2e;*/
  color: red;
  margin: 0;
  padding: 0;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  /*font-family: Poppins-Regular, sans-serif;*/
}

/*---------------------------------------------*/
a,
.link {
  /*font-family: Poppins-Regular;*/
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.link {
  cursor: pointer;
}

a:focus,
.link:focus {
  outline: none !important;
  box-shadow: none;
  -webkit-box-shadow: none;
}

a:hover,
.link:hover {
  text-decoration: none;
  color: #1ccab2 !important;
  /*color: #333333;*/
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  /*font-family: Poppins-Regular;*/
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
  box-shadow: none;
  -webkit-box-shadow: none;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #adadad;
}

input:-moz-placeholder {
  color: #adadad;
}

input::-moz-placeholder {
  color: #adadad;
}

input:-ms-input-placeholder {
  color: #adadad;
}

textarea::-webkit-input-placeholder {
  color: #adadad;
}

textarea:-moz-placeholder {
  color: #adadad;
}

textarea::-moz-placeholder {
  color: #adadad;
}

textarea:-ms-input-placeholder {
  color: #adadad;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
    [ Utility ]*/
.txt1 {
  /*font-family: Poppins-Regular;*/
  font-size: 15px;
  color: #999999;
  line-height: 1.5;
}

.txt2 {
  /*font-family: Poppins-Regular;*/
  font-size: 15px;
  color: #2ebfac;
  line-height: 1.5;
}

/*//////////////////////////////////////////////////////////////////
    [ login ]*/

.container-login100 {
  margin: 0 auto;
  width: 80%;
  min-height: 60vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f8f8f8;
}

@media screen and (min-width: 1280px) {
  .container-login100 {
    width: 60%;
  }
}

/* .wrap-login100 {
      width: 390px;
      background: #f8f8f8;
    } */

/*------------------------------------------------------------------
    [ Form ]*/

.login100-form {
  width: 100%;
}

.login100-form-title {
  display: block;
  font-weight: bold;
  /*font-family: Poppins-Bold;*/
  font-size: 28px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  padding-top: 20px;
}

@media screen and (min-width: 1280px) {
  .login100-form-title {
    font-size: 36px;
    padding-top: 35px;
  }
}

.customer-info-title {
  display: block;
  font-weight: bold;
  /*font-family: Poppins-Bold;*/
  font-size: 36px;
  color: #333333;
  line-height: 1.2;
  text-align: start;
  padding-top: 35px;
}

.login100-form-avatar {
  display: block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.login100-form-avatar img {
  width: 100%;
}

/*------------------------------------------------------------------
    [ Input ]*/

.wrap-input100 {
  margin-top: 15px;
  margin-bottom: 30px;
  width: 100%;
  position: relative;
  border-bottom: 2px solid #d9d9d9;
}

@media screen and (min-width: 1280px) {
  .wrap-input100 {
    margin-top: 35px;
    margin-bottom: 35px;
  }
}

.input100 {
  /*font-family: Poppins-SemiBold;*/
  font-size: 14px;
  color: #555555;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 40px;
  background: transparent;
  padding: 0 5px;
}

@media screen and (min-width: 1280px) {
  .input100{
    font-size: 18px;
    height: 52px;
  }
}

/*---------------------------------------------*/
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #2ebfac;
}

.focus-input100::after {
  /*font-family: Poppins-Medium;*/
  font-size: 15px;
  color: #999999;
  line-height: 1.2;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 20px;
  left: 0px;
  padding-left: 5px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

@media screen and (min-width: 1280px){
  .focus-input100::after {
    top: 15px;
    font-size: 18px;
  }
}

.input100:focus + .focus-input100::after {
  top: -10px;
  font-size: 13px;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::after {
  top: -10px;
  font-size: 13px;
}

@media screen and (min-width: 1280px){
  .has-val.input100 + .focus-input100::after {
    top: -20px;
    font-size: 15px;
  }
  .input100:focus + .focus-input100::after {
    top: -20px;
    font-size: 15px;
  }
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

/*------------------------------------------------------------------
    [ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  /*font-family: Poppins-Medium;*/
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #fff !important;
  line-height: 1.2;
  /*text-transform: uppercase;*/

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  /* width: 30%; */
  height: 40px;
  background-color: #2ebfac;
  border-radius: 25px;

  border: none !important;

  box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -moz-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -webkit-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -o-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -ms-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn .fa-sign-in {
  font-size: 18px;
  padding-left: 2px;
}

.login100-form-btn:hover {
  background-color: #333333;
  box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
}

/*------------------------------------------------------------------
    [ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  /*background-color: #fff;*/
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0px;
  pointer-events: none;

  /*font-family: Poppins-Regular;*/
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.alert-validate::after {
  content: "\f06a";
  font-family: FontAwesome;
  font-size: 16px;
  color: #c80000;

  display: block;
  position: absolute;
  /*background-color: #fff;*/
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

/*//////////////////////////////////////////////////////////////////
    [ Login more ]*/
.login-more li {
  position: relative;
  padding-left: 16px;
}

/* util */

.p-t-25 {
  padding-top: 25px;
}

.p-t-85 {
  padding-top: 85px;
}

.p-t-190 {
  padding-top: 190px;
}

.p-b-70 {
  padding-bottom: 70px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-l-15 {
  padding-left: 15px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-35 {
  margin-bottom: 35px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.vdpComponent.vdpWithInput > input {
  border: none;
  background: none;
}

/* File Upload form styles */
.right-section {
  /* background-color: whitesmoke; */
  /* background-image: url("https://www.transparenttextures.com/patterns/lyonnette.png"); */
  /*border-bottom: 0px solid black;*/
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

@media screen and (max-width:980px) {
  .right-section {
    border-top: 1px dashed #2ebfac  ;
  }
}

/* === Wrapper Styles === */
#file-upload {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.right-section fieldset {
  height: 100%;
  width:100%;
}

.drop-zone-wrapper {
  margin: 30px;
  padding: 10px;
  min-height: 60vh;
  position: relative;
  /* box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); */
  /* border-radius: 10px; */
  /* background-color: white; */
  /* width: 415px; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .drop-zone-wrapper {
    width:90vw;
    margin: 5%;
    padding-left: 0%;
  }
  .drop-zone-wrapper .upload {
    width: 100%;
  }
}

@media screen and (max-width: 980px) {
  .drop-zone-wrapper {
    min-height: 0;
  }
}

.max-size-instruction {
  font-size: 16px;
  text-align: center;
  padding: 1%;
}

.max-size-instruction p {
  color: 555555;
}

/* === Drop Files Box === */
.upload {
  margin: auto;
  width: 85%;
  min-height: 175px;
  border: 8px dashed #7de2d4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

@media screen and (max-width:500px){
  .upload {
    width: 65%;
  }
}

.upload label {
  color: dimgray;
  margin: 0;
  font-size: 1.3rem;
  text-align: center;
  margin-left: 2.5%;
  margin-left: 2.5%;
}

@media screen and (min-width:1280px){
  .upload label {
    font-size: 2rem;
  }
}

.upload p {
  margin-top: 12px;
  line-height: 0;
  font-size: 22px;
  color: #0c3214;
  letter-spacing: 1.5px;
}

.browse__button {
  background-color: #7de2d4;
  border-radius: 10px;
  padding: 0px 8px 0px 10px;
  caret-color: transparent;
  /* width: 100%; */
}

.browse__button:hover {
  cursor: pointer;
  background-color: #2ebfac;
  color: white;
  /* opacity: 0.9; */
}

.container-upload100-form-btn {
  /* position: absolute; */
  bottom: 0;
  margin-top: auto;
  margin-left: auto;
  margin-right: 5%;
  margin-bottom: 15px;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.upload100-form-btn {
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #fff !important;
  line-height: 1.2;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  background-color: #2ebfac;
  border-radius: 25px;

  border: none !important;

  box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -moz-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -webkit-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -o-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);
  -ms-box-shadow: 0 10px 30px 0px rgba(127, 226, 212, 0.75);

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.upload100-form-btn .fa-search {
  font-size: 18px;
  padding-left: 2px;
}

.upload100-form-btn:hover {
  background-color: #333333;
  box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -webkit-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -o-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
  -ms-box-shadow: 0 10px 30px 0px rgba(51, 51, 51, 0.5);
}

/* === Selected Files === */
.summary-wrapper {
  /*min-height: 35vh;*/
  min-height: 10vh;
  margin: auto;
  width: 85%;
  caret-color: transparent;
  min-width: 400px;
  padding: 0 5%;
  /* flex-direction: row; */
}

@media screen and (max-width: 480px) {
  .summary-wrapper {
    min-width: 90vw;
  }
}

@media screen and (max-width: 980px) {
  .summary-wrapper {
    width: 65%;
    min-height: 0;
  }
}
.selected-file-wrapper {
  /* background-color: #caf4ee;
        border-radius: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 0;
        margin-right: 0; */

  /* display: inline-block; */
  background-color: #fefefe;
  border-left: 5px solid #2ebfb0;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}

.selected-file-wrapper .invalid-file .file-name-str,
.selected-file-wrapper .invalid-file .file-name-size {
  color: red;
}

.file {
  /* display: inline-block;
        display: flex;
        flex-direction: column;
        align-items: flex-start; */
  display: inline-block;
  padding: 15px 15px 15px 0;
  margin: auto;
  vertical-align: sub;
  justify-content: center;
  width: 85%;
}

.selected-file-info {
  /* flex-direction: row; */
  /* align-content: stretch; */
  /* align-items: baseline; */
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  line-height: 0;
  color: #0c3214;
  height: 25px;
  letter-spacing: 1.5px;
  line-height: 1rem;
}

  .file-name-str {
    font-size: 18px;
    color: black;
    font-weight: bold;
    margin: 0;
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5rem;
  }

@media screen and (min-width: 980px) {
  .file-name-str {
  /* height: 20px; */
  /* padding-top: 10px; */
  /* margin-bottom: 1rem; */
  font-size: 18px;
  font-weight: bold;
  margin: 0;  
  white-space: nowrap;
  width: 300px;
} 
}

.file-name-size {
  padding: 0 5px;
  margin: 0 5px;
  min-width: 0;
  width: 100px;
  display: block;
  /* font-style: italic;
        white-space: nowrap;
        white-space: unset;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; */
}

.file-name-path {
  display: inline-block;
}

.selected-file-wrapper .fa-times {
  color: black;
  font-size: 18px;
}

.selected-file-wrapper .fa-times:hover {
  cursor: pointer;
  color: red;
}

.selected-file-wrapper .fa-file {
  /* width: 62px; */
  color: black;
  padding: 15px;
  font-size: 40px;
  width: 15%;
  float: left;
  text-align: center;
  vertical-align: super;
}

@media screen and (max-width: 644px) {
  .selected-file-wrapper .fa-file {
    font-size: 1.5rem;
  }
}


.file-upload-progress {
  /* width: 100%; */
}

/* From https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css */

progress {
  vertical-align: baseline;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  /* background-color: #f2fefd; */
  background-color: #f8f8f8;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.bg-success {
  background-color: #2ebfac !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-failure {
  background-color: #e34848 !important;
}

a.bg-failure:focus,
a.bg-failure:hover,
button.bg-failure:focus,
button.bg-failure:hover {
  background-color: #910000 !important;
}

/* customer info styles */
.existing-customer-summary {
  margin: 10px;
  width: 80%;
  margin: auto;
}

.customer-info-title {
  padding: 15px 0 15px 0;
}

.customer-info-container {
  margin-bottom: 35px;
}

.exisiting-files-summary h3 {
  color: black;
  font-size: 24px;
  margin: 15px 0 15px 0;
}

.existing-file-wrapper {
  background-color: #fefefe;
  border-left: 5px solid #2ebfb0;

  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}

.existing-file-wrapper .fa-file {
  color: black;
  font-size: 3rem;
  display: inline-block;
  vertical-align: super;
  margin: 10px;
}

.existing-file-wrapper .existing-file {
  display: inline-block;
}

.existing-file-info {
  padding: 10px 10px 10px 0;
}

.existing-file-name {
  color: black;
  font-weight: bold;
}

.existing-file-date {
  /* font-style: italic; */
}

.left-section {
  position: relative;
}

.left-section .moon-loader {
  position: absolute;
}
.select-folder-message {
  font-size: medium;
  color: #babab4;
  font-weight: bold;
  padding: 2%;
  margin-bottom: 5px;
  text-wrap: wrap;
  display: block;
  height: auto;
  text-align: center;
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.file-explorer {
  overflow: scroll;
  height: 90vh;
  margin-left: 15%;
}

@media screen and (max-width: 440px) {
  .file-explorer {
    margin-left: 10px;
  }
}

@media screen and (max-width: 660px) {
  .file-explorer {
    margin-left: 20px;
  }
}

@media screen and (max-width: 980px) {
  .file-explorer {
    height: 70vh;
  }
}

@media screen and (max-aspect-ratio: 0.7) {
  .file-explorer {
    height: 60%;
  }
}

@media screen and (max-aspect-ratio: 0.6) {
  .file-explorer {
    height: 50vh;
  }
}




/*@media screen and (min-height: 1280px) {
  .file-explorer {
    height: 60vh;
  }
}*/

/*@media (min-aspect-ratio: 3/4) {
  .file-explorer {
    height: 65vh;
  }
}*/

.tabs-bar {
	background-color: transparent;
	padding: 0%;
  width: 100%;
}

.tab-selection {
	display: flex;
	flex-direction: row;
	background-color: transparent;
  border: none;
	margin: 0%;
	padding-bottom: 0%;
  width: 100%;
  justify-content: center;
  gap: 2px;
}

.tab-selection .active {
  font-weight: bold;
  font-size: 16px;
  padding: 5px 12px;
  color: #2ebfac;
  border-bottom: 2px solid #2ebfac;
}

.tabs {
  font-weight: bold;
  font-size: 16px;
  padding: 5px 12px;
  color: #000;
  border-bottom: 2px solid transparent;
  width: 50%;
  text-align: center;
}
.tabs:hover {
  cursor: pointer;
  background-color: #e2e2e2;
}
.show__tab {
	border: 1px dashed #2ebfac;
	padding: 10px;
  color: #2ebfac;
  caret-color: transparent;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border-bottom: 1px solid #f8f8f8;
	background-color: #f8f8f8;
  margin-bottom: -1px;
}
.hide__tab {
	border: 1px dotted rgba(144,144,144,0.25);
  color:#909090;
  caret-color: transparent;
	padding: 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border-bottom-color: transparent;
}
.tabs-seperation {
margin-top: -2px;
padding: 0%;
margin-bottom: 10px;
border-top: 1px dashed #2ebfac;
background-color: #f8f8f8 !important;
z-index: -1;
}
#file-previewer-tab {
  margin-left: 10px;
  min-height: calc(100vh - 120px);
  width: 100%;
}
.new-file-upload {
  width: 100%;
}
.pdf-viewer {
  height: calc(100vh - 80px);
  overflow-y: scroll;
  scrollbar-width: thin;
}
.google-pdf-viewer {
  width: 100%;
  height: 100%;
}

.image-viewer {
  height: calc(100vh - 180px);
  overflow-y: scroll;
  scrollbar-width: thin;
}

.image-viewer img {
  width: 100%;
  height: auto;
  /* object-fit: contain; */
}

.moon-loader-pdf {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(50%, 50%);
}

.preview-message {
  color:dimgray;
  font-size: 2.5rem;
  font-weight: 600;
  opacity: 0.4;
  text-align: center;
  margin: 20% 0;
}

.img-viewer {
  height: calc(100vh - 80px);
}
</style>