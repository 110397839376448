import axios from "axios";

export default {
  data() {
    return {
      existingCustomerFilesBasePath:
        process.env.VUE_APP_ROOT_URL+"/customers/",
      config: null,
      fileDownloadConfig: null,
    };
  },

  methods: {
    getCustomerFiles: async function(customerId) {
      // If customerId is null or undefined return 
      if (!customerId) return;
      const url = this.existingCustomerFilesBasePath + customerId + "/files";
      let response = await axios.get(url, this.config).catch((err) => {
        return err.response;
      });
      return response;
    },

    createFileUploadLink: async function(customerId, fileInfo) {
      if (!customerId) return;
      const url = this.existingCustomerFilesBasePath + customerId + "/files";
      let response = await axios
        .post(url, fileInfo, this.config)
        .catch((err) => {
          return err.response;
        });
      return response;
    },

    uploadFile: async function(url, file) {
      var formData = new FormData();
      formData.append("fileToUpload", file);
      // PUT request with file as body
      let response = await axios
        .put(url, formData, this.uploadFileConfig)
        .catch((err) => {
          // handle failure to contact API
          return err.response;
        });
      return response;
    },

    downloadCustomerFile: async function(url) {
      let response = await axios
        .get(url, this.fileDownloadConfig)
        .catch((err) => {
          return err.response;
        });
      return response;
    },
  },

  mounted() {
    // configure axios on mount
    this.config = {
      timeout: 30000,
      // configure headers
      headers: {
        // default content type
        "Content-Type": "application/json",
        // can setup to prevent all caching
        "Cache-Control": "no-cache",
      },
    };

    this.uploadFileConfig = {
      // timeout: 30000,
      headers: {
        // default content type
        "Content-Type": "multipart/form-data",
        // can setup to prevent all caching
        "Cache-Control": "no-cache",
      },
    };

    this.fileDownloadConfig = {
      timeout: 30000,
      // configure headers
      headers: {
        // default content type
        "Content-Type": "application/octet-stream",
        Accept: "application/octet-stream",
        // can setup to prevent all caching
        "Cache-Control": "no-cache",
      },
      responseType: "blob",
    };
  },
};
